import React, { useEffect, useState } from "react";
import PagingTable from "components/Table/Paging";
import _ from "lodash";
import Select, { getUidOfItems } from "components/Form/Select";
import MultiSelect from "components/Form/MultiSelect";
import { adminClient, AdminClient } from "apis/admin";
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from "pages/Admin/Clinic/helper";
import SpinnerMedium from "components/Spinner/Medium";
import { randomString } from "utils/math";
import RangeDatePicker from "components/Form/Datepicker/Range";
import moment from "moment";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import { useSearchParams } from "react-router-dom";
import IconButton from "components/Form/Button/Icon";
import excelIcon from "assets/logo/excel.png";
import { SHIPPING_COMPANY_ITEMS } from "pages/Admin/Customer/Status/TaskManager";

export interface RangePickerOption {
    label: string;
    field: string;
}


const GeneralTaskList = ({ states, columnInfos, children = <div></div>, rangePickerOptions = null as RangePickerOption[], enableIntakeFilter = true, enableClinicFilter = false, enableShippingCompanyFilter = false, randomKeyExternal = "" }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterParams, setFilterParams] = useState(null);
    const [intakeInfos, setIntakeInfos] = useState(null);
    const [intakeItems, setIntakeItems] = useState(null);
    const [clinicInfos, setClinicInfos] = useState([]);
    const [clinicItems, setClinicItems] = useState(null);
    const [shippingCompanyItems, setShippingCompanyItems] = useState(null);
    const [searchText, setSearchText] = useState(searchParams.get("user_search_text") ?? null);
    const [selectedIntakeItem, setSelectedIntakeItem] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [selectedShippingCompany, setSelectedShippingCompany] = useState(null);
    const [selectedDateRangeField, setSelectedDateRangeField] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [randomKey, setRandomKey] = useState(randomString());

    useEffect(() => {
        initialize();
    }, [])

    const initialize = async () => {
        if (enableIntakeFilter) {
            await updateIntakeInfos();
        }
        const infos = await AdminClient.getClinicInfos();
        setClinicInfos(infos);
        setShippingCompanyItems([
            {name: "모든 배송사", value: null},
            null,
            ...SHIPPING_COMPANY_ITEMS
        ]);
        setIsReady(true);
    }

    useEffect(() => {
        console.log("[useEffect] intakeInfos", intakeInfos);
        if (_.isNil(intakeInfos)) {
            return;
        }
        const tempIntakeItems = [
            { name: "모든 진료지", value: null },
            null,
            ...intakeInfos.filter((elm) => elm.clinic_id !== "COMMON").map((elm) => ({ name: elm.title, value: elm.intake_id }))
        ]
        setIntakeItems(tempIntakeItems);
        const uid = getUidOfItems(tempIntakeItems);
        const selectedIntakeSaved = localStorage.getItem(uid);
        if (_.isNil(selectedIntakeSaved)) {
            setSelectedIntakeItem(tempIntakeItems[0]);
            return;
        }
    }, [intakeInfos])

    useEffect(() => {
        console.log("[useEffect] clinicInfos", clinicInfos);
        if (_.isEmpty(clinicInfos)) {
            return;
        }
        const tempClinicItems = [
            { name: "모든 의원", value: null },
            null,
            ...clinicInfos.map((elm) => ({ name: elm.name, value: elm.clinic_id }))
        ]
        setClinicItems(tempClinicItems);
        const uid = getUidOfItems(tempClinicItems);
        const selectedClinicSaved = localStorage.getItem(uid);
        if (_.isNil(selectedClinicSaved)) {
            setSelectedClinicItem(tempClinicItems[0]);
            return;
        }
    }, [clinicInfos])

    useEffect(()=>{
        if(_.isNil(shippingCompanyItems)){
            return;
        }
        const uid = getUidOfItems(shippingCompanyItems);
        const selectedShippingCompanySaved = localStorage.getItem(uid);
        if (_.isNil(selectedShippingCompanySaved)) {
            setSelectedShippingCompany(shippingCompanyItems[0]);
            return;
        }
    },[shippingCompanyItems])

    useEffect(() => {
        console.log("[useEffect] selectedIntakeItem", selectedIntakeItem);
        if (enableIntakeFilter && _.isNil(selectedIntakeItem)) {
            return;
        }
        if (enableClinicFilter && _.isNil(selectedClinicItem)) {
            return;
        }
        if (enableShippingCompanyFilter && _.isNil(selectedShippingCompany)) {
            return;
        }
        if (!_.isNil(rangePickerOptions) && _.isNil(selectedDateRange)) {
            return;
        }
        setFilterParams({
            ...(!_.isNil(searchText) && { search_text: searchText }),
            ...(!_.isNil(selectedIntakeItem?.value) && { intake_id: selectedIntakeItem?.value }),
            ...(!_.isNil(selectedClinicItem?.value) && { clinic_id: selectedClinicItem?.value }),
            ...(!_.isNil(selectedShippingCompany?.value) && { shipping_company: selectedShippingCompany?.value }),
            ...(!_.isNil(selectedDateRange) && {
                ...(!_.isNil(selectedDateRangeField) && { [`${selectedDateRangeField}_start`]: selectedDateRange[0] }),
                ...(!_.isNil(selectedDateRangeField) && { [`${selectedDateRangeField}_end`]: selectedDateRange[1] }),
            }),

        })
        setRandomKey(randomString());
    }, [selectedIntakeItem, selectedClinicItem, selectedShippingCompany, selectedDateRangeField, selectedDateRange, searchText])

    useEffect(() => {
        setRandomKey(randomKeyExternal);
    }, [randomKeyExternal])

    useEffect(() => {
        if (!_.isNil(searchText)) {
            setSearchParams(`user_search_text=${searchText}`);
        } else {
            setSearchParams();
        }
    }, [searchText])

    const updateIntakeInfos = async () => {
        try {
            const ret = await adminClient.get(`/intakes/distinct/latest`);
            const data = ret.data;
            setIntakeInfos(data);
        } catch (e) {
            console.error(e);
        }
    }

    const onIntakeFilterChanged = (item) => {
        console.log("[onIntakeFilterChanged] item", item);
        setSelectedIntakeItem(item);
    }

    const onClinicFilterChanged = (item) => {
        console.log("[onClinicFilterChanged] item", item);
        setSelectedClinicItem(item);
    }

    const onShippingCompanyChanged = (item) => {
        console.log("[onShippingCompanyChanged] item", item);
        setSelectedShippingCompany(item);
    }

    const onDateRangeChanged = (range) => {
        console.log("[onDateRangeChanged] range", range)
        if (range.some((elm) => _.isNil(elm))) {
            console.log("[useEffect] some of range has nil")
            return;
        }
        setSelectedDateRange([moment(range[0]).format("YYYY-MM-DD"), moment(range[1]).format("YYYY-MM-DD")]);
    }

    const onDateRangeOptionChanged = (option) => {
        console.log("[onDateRangeOptionChanged] option", option)
        const field = rangePickerOptions?.find((elm) => elm.label === option)?.field;
        setSelectedDateRangeField(field);
    }

    const fetchData = async (page: number, limit: number) => {
        console.log("[fetchData] page, limit", page, limit, filterParams)
        if (_.isNil(filterParams)) {
            return {
                data: [],
                page: 1,
                total: 0
            }
        }
        const params = {
            page,
            limit,
            states,
            ...filterParams
        }
        const ret = await adminClient.get("/trackings", { params });
        return ret.data;
    }

    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfos, clinicInfos);
    const rowInfo = createRowInfoWithClinic(clinicInfos);

    if (!isReady) {
        return <SpinnerMedium />
    }

    const windowWidth = window.innerWidth;

    return (
        <div className="px-2">
            <div className="overflow-auto">
                <div className="flex pb-2 space-x-2 justify-between items-end">
                    <div>
                        {<div className="flex space-x-2">
                            {enableIntakeFilter &&
                                <div>
                                    <Select
                                        upperLabel={"진료지"}
                                        items={intakeItems}
                                        selectedItem={selectedIntakeItem}
                                        onChange={onIntakeFilterChanged}
                                        saveSelectedItems={true}
                                    />
                                </div>
                            }
                            {enableClinicFilter &&
                                <div>
                                    <Select
                                        upperLabel={"의원"}
                                        items={clinicItems}
                                        selectedItem={selectedClinicItem}
                                        onChange={onClinicFilterChanged}
                                        saveSelectedItems={true}
                                    />
                                </div>
                            }
                            {enableShippingCompanyFilter &&
                                <div>
                                    <Select
                                        upperLabel={"배송사"}
                                        items={shippingCompanyItems}
                                        selectedItem={selectedShippingCompany}
                                        onChange={onShippingCompanyChanged}
                                        saveSelectedItems={true}
                                    />
                                </div>
                            }
                            {rangePickerOptions &&
                                <div className="z-50">
                                    <RangeDatePicker
                                        upperLabel={"날짜"}
                                        onRangeChanged={onDateRangeChanged}
                                        options={rangePickerOptions.map((elm) => elm.label)}
                                        hideRange={selectedDateRangeField === null}
                                        onOptionChanged={onDateRangeOptionChanged}
                                        saveSelectedDts={true}
                                        saveOption={true}
                                    />
                                </div>
                            }
                            <div className="min-w-36">
                                <ToggleSearchInput
                                    upperLabel="고객검색"
                                    upButtonLabel="적용"
                                    downButtonLabel="해제"
                                    initialValue={searchText}
                                    initialIsUp={_.isEmpty(searchText)}
                                    onToggle={(isUp, value) => { setSearchText(!isUp ? value : null) }}
                                    placeholder="고객명, 고객번호, 전화번호 검색"
                                />
                            </div>
                        </div>}
                    </div>
                    <div>
                        <div className="flex space-x-2">
                            {children}
                            <div className="">
                                <IconButton
                                    onButtonClicked={async () => {
                                        const params = {
                                            states,
                                            ...filterParams
                                        }
                                        console.log("[download excel]", params)
                                        AdminClient.downloadXlsx("/trackings/download/xlsx", params, "downloaded_status.xlsx");
                                    }}
                                >
                                    <div className="size-5">
                                        <img src={excelIcon} alt="excel" />
                                    </div>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PagingTable
                fetchData={fetchData}
                columns={columnInfosWithClinic}
                rowInfo={rowInfo}
                disableSort={true}
                windowSize={20}
                isStickyFirstCol={windowWidth > 640}
                key={randomKey}
            />
        </div>
    )
}

export default GeneralTaskList;

