import React, { useRef, useState } from "react";
import _ from "lodash";
import { PrescriptionTemplateField, PrescriptionTemplateFieldType } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/types";
import Quill from "quill";
import { Op } from "quill/core";
import QuillMadeArticle from "components/Article/QuillMade";
import Button from "components/Form/Button";
import IconButton from "components/Form/Button/Icon";
import { ChevronDoubleDownIcon } from "@heroicons/react/20/solid";
import papagoIcon from "assets/logo/papago.png";
import { INTENDED_EMPTY_TEXT } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/helper";

const FieldsViewer = ({ fields }) => {
    const [quill] = useState(new Quill(document.createElement('div')));

    if (_.isNil(fields)) {
        return null;
    }

    return (
        <div className="flex flex-col space-y-2">
            {fields.map((field: PrescriptionTemplateField) => {
                let html = "";
                let txt = "";
                if (field.type === PrescriptionTemplateFieldType.SHORT) {
                    html = field.data as string;
                    txt = field.data as string;
                } else if (field.type === PrescriptionTemplateFieldType.LONG) {
                    quill.setContents(field.data as Op[]);
                    html = quill.getSemanticHTML();
                    txt = quill.getText();
                }
                const isIntendedEmpty = txt === INTENDED_EMPTY_TEXT;
                return (
                    <div key={field.key}>
                        <div className="flex justify-between items-end mb-[1.5px]">
                            <h1 className="font-semibold text-sm">
                                {field.key}
                            </h1>
                        </div>
                        <div className="shadow-sm ring-1 ring-inset ring-gray-300 w-full rounded-md">
                            <div className="relative px-2 py-1.5 flex justify-between items-center">
                                {!isIntendedEmpty && <>
                                    <div className="w-full">
                                        <QuillMadeArticle htmlText={html}></QuillMadeArticle>
                                    </div>
                                    <div className="absolute top-[4px] right-[6px]">
                                        <IconButton
                                            paddingClassName="p-[2px]"
                                            classNames="align-sub"
                                            onButtonClicked={() => {
                                                window.open(`https://papago.naver.com/?sk=ko&tk=ja&hn=0&st=${txt}`, 'newWindow', 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no')
                                            }}
                                        >
                                            <div className="size-6">
                                                <img src={papagoIcon} alt="번역" className="w-6 h-6" />
                                            </div>
                                        </IconButton>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                )

            })}
        </div>
    )
}

export default FieldsViewer;