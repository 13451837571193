import React from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import {
    ArchiveBoxXMarkIcon,
    ChevronDownIcon,
    PencilIcon,
    Square2StackIcon,
    TrashIcon,
    EllipsisVerticalIcon
} from '@heroicons/react/20/solid'
import { AnchorProps } from '@headlessui/react/dist/internal/floating'
import { classNames } from 'components/uiUtils'
import _ from 'lodash'

export type DropdownItem = {
    name: string;
    avatar?: string;
    disabled?: boolean;
    action?: (item: DropdownItem) => void;
}

const EXAMPLE_ITEMS: DropdownItem[] = [
    {
        name: 'Go ETH Homepage',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
    },
    {
        name: 'Go USDT Homepage',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    },
    {
        name: 'Go USDC Homepage',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    },
    {
        name: 'Go XRP Homepage',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png',
    },
    {
        name: 'Go DOGE Homepage',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
    },
]

const DefaultMenuButton = () => {

    return (
        <div className="px-2 py-2 inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white sm:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 font-normal">
            <EllipsisVerticalIcon className="size-5"></EllipsisVerticalIcon>
        </div>
    )
}

const Dropdown = ({ items = EXAMPLE_ITEMS, CustomMenuButton = DefaultMenuButton, onClick = (item) => { }, anchor = "bottom end" as AnchorProps }) => {
    return (
        <Menu>
            <MenuButton className="align-sub">
                <CustomMenuButton />
            </MenuButton>
            <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <MenuItems
                    anchor={anchor}
                    className="absolute right-0 z-[200] mt-1 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none [--anchor-gap:var(--spacing-1)]"
                >
                    {items.map((item, i) => (
                        _.isNil(item) ? <hr key={i} className="border-t-1 border-gray-200" /> :
                            (<MenuItem key={i} disabled={item.disabled}>
                                <div
                                    className={classNames("group flex items-center w-full me-0 block px-4 py-2 sm:text-sm text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white",
                                        item.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer")}
                                    onClick={() => { onClick(item); item.action?.(item) }}
                                >
                                    {item.avatar &&
                                        <div className="size-5 mr-3">
                                            <img src={item.avatar} className="flex-shrink-0 rounded-full" />
                                        </div>
                                    }
                                    <div className="w-fit text-nowrap">
                                        {item.name}
                                    </div>
                                </div>
                            </MenuItem>)
                    ))}
                </MenuItems>
            </Transition>
        </Menu>
    )
}

export default Dropdown