import React, { useEffect } from "react";
import SpinnerMedium from "components/Spinner/Medium";
import { useNavigate, useSearchParams } from "react-router-dom";
import { commonClient, tryAssignDevAuthCookie } from "apis/common";
import { toast } from "react-toastify";
import { useTranslation } from "utils/translate";

const OAuthPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { tr } = useTranslation();

    useEffect(() => {
        onLineLoginInfoReceived();
    }, []);


    const onLineLoginInfoReceived = async () => {
        console.log("THIS IS LINE LOGIN PAGE");
        const code = searchParams.get("code");
        const state = searchParams.get("state");
        console.log("LINE LOGIN CODE", code, state);
        let authInfo = {};
        try {
            // alert("LINE LOGIN CODE" + code + state)
            const ret = await commonClient.post('/line/callback', { code, state });
            console.log("[Login Page] /line/callback ret", ret);
            const { token, refreshToken } = ret.data;
            localStorage.setItem("refreshToken", refreshToken);
            authInfo = { token, refreshToken };
            const agent = window.navigator.userAgent.toLowerCase();
            if (agent.includes("line/")) {
                toast.success(tr("라인 앱에서 로그인 되었습니다."));
                navigate(`/`);
                return;
            }
        } catch (e) {
            // window.alert(tr("라인 로그인 오류"));
            toast.error(tr("회원 정보를 찾을 수 없습니다."));
            window.close();
        }
        try {
            window.opener.postMessage(authInfo, "*");
            window.close();
        } catch (e) {
            // maybe new browser
            console.log("window.opener.postMessage error", e);
            toast.info(tr("새로운 브라우저에서 로그인 되었습니다."));
            navigate(`/`);
        }
    }
    return (
        <div className="flex justify-center min-h-screen h-fit w-full bg-gradient-to-bl from-pink-300 to-transparent bg-pink-100">
            <div className="w-full max-w-screen-lg px-3 pt-[4svh] pb-[4svh] md:mt-[15vh] md:mb-[15vh]">
                <SpinnerMedium></SpinnerMedium>
            </div>
        </div>
    )
}

export default OAuthPage;
