import React, { useEffect } from 'react';
import { adminClient } from 'apis/admin';
import PagingTable from 'components/Table/Paging';
import Card from 'components/Card';
import PageTitle from 'components/Title/Page';
import { columnInfos } from './config';
const ActionPAge = () => {
    useEffect(() => {

    });

    const fetchUserActionData = async (page, limit) => {
        try {
            const params = {
                page,
                limit,
            };
            const ret = await adminClient.get(`/master/admin-actions`, { params });
            const data = {
                data: ret.data.data,
                page: ret.data.page,
                total: ret.data.total,
            }
            return data;
        } catch (e) {
            console.error("[fetchUserActionData] error", e);
            return { data: [], page: 1, total: 0, }
        }
    }
    return (
        <Card>
            <div className="p-3">
                <PageTitle>활동기록</PageTitle>
                <PagingTable
                    fetchData={fetchUserActionData}
                    columns={columnInfos}
                    windowSize={20}
                />
            </div>
        </Card>
    )
}


export default ActionPAge;
