import React, { useEffect, useState } from 'react';
import logoCrm from 'assets/logo/logo-big.png';
import logoPublic from 'assets/logo/logo-no-background.png';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import _ from 'lodash';
import { UserType } from 'pages/Admin/User/types';
import { memberClient } from 'apis/member';

const Home = () => {
    const [cookies] = useCookies();
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        console.log("[Home] useEffect [] cookies", cookies)
        const { Authorization: token } = cookies;
        if (_.isNil(token)) {
            return;
        }
        const parsed: any = jwtDecode(token);
        console.log("[Home] parsed token", parsed);
        setUserType(parsed.type);
        fetchUserInfo();
    }, [])

    const fetchUserInfo = async () => {
        const ret = await memberClient.get("/public/users/profile");
        console.log("[Home] fetchUserInfo ret", ret);
    }

    const logo = [UserType.STAFF, UserType.MASTER].includes(userType) ? logoCrm : logoPublic;

    return (
        <div className="mx-auto max-w-screen-lg">
            {userType !== UserType.STAFF && <div className="pt-20"></div>}
            <img className="w-auto p-5 md:px-32" src={logo} alt="Logo" style={{ margin: "0 auto" }}></img>
        </div>
    );
}

export default Home;
