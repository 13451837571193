import { InputFormat } from "components/Form/Input";
import { Column } from "components/Table/Simple";
import _ from "lodash";

const formatAfterDay = (value) => {
    return _.isNil(value) ? "" : `${value}일 후`
}
export const columnInfos: Column[] = [
    {
        field: "aftercare_type_id",
        label: "Aftercare Type ID",
        visible: false,
    },
    {
        field: "name",
        label: "관리유형명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "management_day1",
        label: "1차 관리 일정",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatAfterDay
    },
    {
        field: "management_day2",
        label: "2차 관리 일정",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatAfterDay
    },
    {
        field: "management_day3",
        label: "3차 관리 일정",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatAfterDay
    },
    {
        field: "post_management_day1",
        label: "1차 사후관리 일정",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatAfterDay
    },
    {
        field: "post_management_day2",
        label: "2차 사후관리 일정",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatAfterDay
    },
    {
        field: "created_at",
        label: "생성일",
        visible: false,
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: false,
    },
    {
        field: "order",
        label: "순서",
        visible: false,
    }
]