export const columnInfos = [
    {
        field: "clinic_id",
        label: "의원ID",
        visible: false
    },
    {
        field: "clinic_name",
        label: "의원명",
        visible: true,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
    },
    {
        field: "count",
        label: "번역건수",
        visible: true,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    }
]