import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "components/Modal/Simple";
import DetailViewer, { DetailViewerContent } from "pages/Admin/Customer/Status/DetailViewer";
import Button from "components/Form/Button";
import PagingTable from "components/Table/Paging";
import CRUDTable from "components/Table/CRUD";
import { adminClient } from "apis/admin";
import Select from "components/Form/Select";
import VerticalDragList from "components/Drag/VerticalList";
import IconButton from "components/Form/Button/Icon";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import Input, { InputFormat } from "components/Form/Input";
import _, { set } from 'lodash';
import { randomString } from "utils/math";
import { classNames } from "components/uiUtils";
import { PAYMENT_METHOD_ITEMS, FINANCIAL_INSTITUTE_ITEMS, FINANCIAL_FEE_TYPE_ITEMS } from "./config"
import { CURRENCY_ITEMS } from "pages/Admin/Clinic/Product/config";
import { toast } from "react-toastify";
import { formatProductName } from "./helper";
import { getIdFromCookie } from "utils/cookie";

const CLINIC_ID = "LAYEON";

interface ProductCardFormData {
	product_id: string,
	price: string,
	discount: string
	// quantity: string,
}

const ProductCard = ({ cardId, productInfos, initialData, onChange = (cardId: string, formData: ProductCardFormData) => { } }) => {
	const [productItems, setProductItems] = useState([]);
	const [formData, setFormData] = useState<ProductCardFormData>(initialData as ProductCardFormData);

	useEffect(() => {
		if (_.isEmpty(productInfos) || _.isNil(productInfos)) {
			return;
		}
		const items = productInfos?.map((product) => {
			let label = formatProductName(product);
			return {
				name: label,
				value: product.product_id,
			}
		})
		setProductItems(items);
		if (_.isNil(initialData) || _.isEmpty(initialData?.product_id)) {
			onProductChanged(items[0]);
		}
	}, [productInfos])

	useEffect(() => {
		onChange(cardId, formData);
	}, [formData])

	const onProductChanged = (item) => {
		const price = productInfos?.find((product) => product.product_id === item.value)?.price;
		setFormData({ ...formData, product_id: item.value, price });
	}

	// const onQuantityChanged = (value) => {
	//     setFormData({ ...formData, quantity: value });
	// }

	const onDiscountChanged = (value) => {
		setFormData({ ...formData, discount: value });
	}

	return (
		<div className="p-2 flex flex-col space-y-1">
			<div className="">
				<h1 className="text-lg font-bold">제품명</h1>
				<Select items={productItems} onChange={onProductChanged} selectedItem={productItems?.find((item) => item.value === formData.product_id) ?? productItems[0] ?? null}></Select>
			</div>
			<div className="">
				<h1 className="text-lg font-bold">가격</h1>
				<Input value={formData.price} disabled={true}></Input>
			</div>
			<div className="">
				<h1 className="text-lg font-bold">할인</h1>
				<Input onChange={onDiscountChanged} value={formData.discount} format={InputFormat.NUMBER}></Input>
			</div>
			{/* <div className="">
                <h1 className="text-lg font-bold">수량</h1>
                <Input onChange={onQuantityChanged} value={formData.quantity}></Input>
            </div> */}
		</div>
	)
}

const PurchaseInfoEditor = () => {
	const navigate = useNavigate();
	const { submissionId } = useParams();
	const { Modal: DetailModal, openModal: openDetailModal, closeModal: closeDetailModal } = useModal();
	const [clinicUserId, setClinicUserId] = useState(null);
	const [userName, setUserName] = useState(null);
	const [productInfos, setProductInfos] = useState([]);
	const [productDatas, setProductDatas] = useState([]);
	const [selectedProductCardId, setSelectedProductCardId] = useState(null);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHOD_ITEMS[0]);
	const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_ITEMS[0]);
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalDiscount, setTotalDiscount] = useState(0);
	// const [mainPrice, setMainPrice] = useState("");
	// const [subPrice, setSubPrice] = useState("");
	// const [selectedFinancialFeeType, setSelectedFinancialFeeType] = useState(FINANCIAL_FEE_TYPE_ITEMS[0]);
	const [financialFee, setFinancialFee] = useState("");
	const [deliveryFee, setDeliveryFee] = useState("");
	const [earnedPoint, setEarnedPoint] = useState("");
	const [usedPoint, setUsedPoint] = useState("");
	const [memo, setMemo] = useState("");
	const [note, setNote] = useState("");
	const [shippingAddress, setShippingAddress] = useState("");
	const [successGetPurchase, setSuccessGetPurchase] = useState(false);

	const [clinicId, setClinicId] = useState(null);
	const [userId, setUserId] = useState(null);
	const [assigned, setAssigned] = useState(null)
	const [aftercareTypeId, setAftercareTypeId] = useState(null);

	const [aftercareItems, setAftercareItems] = useState([]);
	const [selectedAftercareItem, setSelectedAftercareItem] = useState(null);

	useEffect(() => {
		fetchAllInfos();
	}, [])

	useEffect(() => {
		if (_.isNil(userId)) {
			return;
		}
		fetchUserInfo();
	}, [userId])

	useEffect(() => {
		if (_.isNil(clinicId)) {
			return;
		}
		fetchProductInfos();
	}, [clinicId])

	useEffect(() => {
		if (_.isNil(aftercareTypeId)) {
			return;
		}
		const item = aftercareItems.find((item) => item.value === aftercareTypeId);
		if (_.isNil(item)) {
			return;
		}
		setSelectedAftercareItem(item);
	}, [aftercareTypeId])

	useEffect(() => {
		let totalP = 0;
		let totalD = 0;
		productDatas.forEach((product) => {
			totalP += Number(product.price);
			totalD += Number(product.discount);
		})
		setTotalPrice(totalP);
		setTotalDiscount(totalD);
	}, [productDatas])

	const fetchAllInfos = async () => {
		await fetchProfile();
		await fetchAftercareTypes();
		await fetchTrackingInfos();
		await fetchPurchaseInfo();
	}

	const fetchProfile = async () => {
		try {
			const ret = await adminClient.get(`/users/profile`);
			console.log("[fetchProfile] ret", ret);
			const { name } = ret.data;
			setAssigned(name);
		} catch (e) {

		}
	}

	const fetchPurchaseInfo = async () => {
		try {
			const ret = await adminClient.get(`/purchases/${submissionId}`);
			const purchaseData = ret.data;
			console.log("[fetchPurchaseInfo] purchase ret", purchaseData);
			setProductDatas(purchaseData.items.map((item) => ({
				card_id: randomString(10),
				product_id: item.product_id,
				price: Number(item.price),
				discount: Number(item.discount),
			})));
			setAftercareTypeId(purchaseData.aftercare_type_id);
			setSelectedPaymentMethod(PAYMENT_METHOD_ITEMS.find((item) => item.value === purchaseData.payment_method));
			setSelectedCurrency(CURRENCY_ITEMS.find((item) => item.value === purchaseData.currency));
			// setMainPrice(purchaseData.main_price);
			// setSubPrice(purchaseData.sub_price);
			// setSelectedFinancialFeeType(FINANCIAL_FEE_TYPE_ITEMS.find((item) => item.value === purchaseData.financial_fee_type));
			setFinancialFee(purchaseData.financial_fee);
			setDeliveryFee(purchaseData.shipping_cost);
			setEarnedPoint(purchaseData.earned_point);
			setUsedPoint(purchaseData.used_point);
			setMemo(purchaseData.memo);
			setNote(purchaseData.note);
			if (!_.isNil(purchaseData.shipping_address)) {
				setShippingAddress(purchaseData.shipping_address);
			}
			setSuccessGetPurchase(true);
		} catch (e) {
			console.log(`purchase data for ${submissionId} not found`, e)
		}
	}


	const fetchProductInfos = async () => {
		const params = {
			clinic_id: clinicId,
			page: 1,
			limit: 100000
		}
		try {
			const res = await adminClient.get(`/products`, { params });
			setProductInfos(res?.data?.data);
		} catch (e) {
			console.error("[fetchProductInfos]", e);
		}
	}

	const fetchTrackingInfos = async () => {
		try {
			const ret = await adminClient.get(`/trackings/${submissionId}/info`);
			console.log("[fetchTrackingInfos]", ret);
			const { clinic_id, user_id, clinic_user_id } = ret.data;
			setClinicId(clinic_id);
			setUserId(user_id);
			setClinicUserId(clinic_user_id);
		} catch (e) {
			console.error("[fetchTrackingInfos]", e);
		}
	}

	const fetchUserInfo = async () => {
		try {
			const ret = await adminClient.get(`/users/${userId}`);
			console.log("[fetchUserInfo]", ret);
			const { name, address } = ret.data;
			setUserName(name);
			if (_.isEmpty(shippingAddress)) {
				if (!successGetPurchase) {
					return;
				}
				if (_.isEmpty(address)) {
					return
				}
				const ok = window.confirm(`배송지 정보가 없습니다. 사용자 정보의 주소를 배송지에 입력하시겠습니까? 회원주소: ${address}`);
				if (!ok) {
					return;
				}
				setShippingAddress(address);
			}
		} catch (e) {
			console.error("[fetchUserInfo]", e);
		}

	}

	const fetchAftercareTypes = async () => {
		try {
			const ret = await adminClient.get(`/aftercares/types`);
			console.log("[fetchAftercareTypes] ret", ret);
			const temp = ret.data;
			console.log("[fetchAftercareTypes] temp", temp);
			// setTableData(tempTableData);
			const items = temp.map((item) => ({
				name: item.name,
				value: item.aftercare_type_id,
			}))
			setAftercareItems(items);
			setSelectedAftercareItem(items[0]);
		} catch (e) {
			console.error("[fetchAftercareTypes] error", e);
		}
	}

	const onProductAddButtonClicked = () => {
		console.log("[onProductAddButtonClicked]")
		const newProductData = {
			card_id: randomString(10),
			product_id: "",
			discount: "",
			// price: "",
		}
		// insert new product data after selected product card
		let newProductDatas = [];
		if (_.isNil(selectedProductCardId)) {
			newProductDatas = [...productDatas, newProductData];
		} else {
			newProductDatas = productDatas.flatMap((productData) => {
				if (productData.card_id === selectedProductCardId) {
					return [productData, newProductData];
				}
				return [productData];
			})
		}
		setProductDatas(newProductDatas);
		setSelectedProductCardId(newProductData.card_id);
	}

	const onProductRemoveButtonClicked = () => {
		console.log("[onProductRemoveButtonClicked]", selectedProductCardId);
		const newProductFormDatas = productDatas.filter((productFormData) => productFormData.card_id !== selectedProductCardId);
		setProductDatas(newProductFormDatas);
		setSelectedProductCardId(null);
	}

	const onProductOrderChanged = (items) => {
		console.log("[onProductOrderChanged]", items);
		const newProductFormDatas = items.map((item) => productDatas.find((productFormData) => productFormData.card_id === item.id));
		setProductDatas(newProductFormDatas);
	}

	const onProductFormChanged = (cardId: string, formData: ProductCardFormData) => {
		console.log("[onProductFormChanged]", cardId, formData)
		const idx = productDatas.findIndex((productFormData) => productFormData.card_id === cardId);
		const newProductFormDatas = [...productDatas];
		newProductFormDatas[idx] = { ...newProductFormDatas[idx], ...formData };
		setProductDatas(newProductFormDatas);
	}

	const onCardClicked = (cardId) => {
		console.log("[onCardClicked]", cardId);
		if (selectedProductCardId === cardId) {
			setSelectedProductCardId(null);
			return;
		}
		setSelectedProductCardId(cardId);
	}

	const onSave = async () => {
		const data = {
			submission_id: submissionId,
			user_id: userId,
			user_name: userName,
			clinic_id: clinicId,
			clinic_user_id: clinicUserId,
			purchase_at: new Date(),
			payment_method: selectedPaymentMethod.value,
			currency: selectedCurrency.value,
			// financial_fee_type: selectedFinancialFeeType.value,
			financial_fee: Number(financialFee),
			purchase_price: Number(Number(totalPrice) - Number(totalDiscount) + Number(financialFee) + Number(deliveryFee) - Number(usedPoint)),
			// main_price: Number(mainPrice),
			// sub_price: Number(subPrice),
			shipping_cost: Number(deliveryFee),
			earned_point: Number(earnedPoint),
			used_point: Number(usedPoint),
			memo: memo,
			note: note,
			items: productDatas.map((productData) => ({
				product_id: productData.product_id,
				discount: Number(productData.discount),
				price: Number(productData.price)
			})),
			shipping_address: shippingAddress,
			aftercare_type_id: selectedAftercareItem.value,
			assigned: assigned,
		}
		console.log("data", data);
		try {
			let ret;
			if (successGetPurchase) {
				ret = await adminClient.put(`/purchases/${submissionId}`, data);
				console.log("[onSave] update", ret);
				toast.success("구매정보가 저장되었습니다.")
			}
			else {
				ret = await adminClient.post(`/purchases`, data);
				console.log("[onSave] create", ret);
				toast.success("구매정보가 생성되었습니다.")
				fetchPurchaseInfo();
			}
		} catch (e) {
			toast.error("구매정보 저장에 실패했습니다.")
			console.error("error", e);
		}
	}

	const onComplete = async () => {
		// if (_.isEmpty(shippingAddress)) {
		//     toast.error("배송지를 입력해주세요.");
		//     return;
		// }
		if (_.isEmpty(selectedAftercareItem)) {
			toast.error("복용 후 관리 유형을 선택해주세요.");
			return;
		}
		onSave();
		try {
			const body = {
				shipping_address: shippingAddress,
			}
			const ret = await adminClient.patch(`/trackings/${submissionId}/payment_completed`, body);
			console.log(ret);
			toast.success("완료되었습니다.");
			navigate(-1)
		} catch (e) {
			toast.error("완료에 실패했습니다.")
			console.error(e);
		}
	}

	return (
		<>
			<Card>
				<div className="p-2">
					<div className="grid md:grid-cols-2 mb-2">
						<div className="flex flex-col space-y-2 p-2 md:border-r">
							<div className="">
								<h1 className="text-lg font-bold">고객명</h1>
								<div className="">
									<Input
										value={userName}
										disabled={true}
									/>
								</div>
							</div>
							<div className="">
								<h1 className="text-lg font-bold">고객번호</h1>
								<div className="">
									<Input
										value={clinicUserId}
										disabled={true}
									/>
								</div>
							</div>
							<div className="">
								<h1 className="text-lg font-bold">담당자</h1>
								<div className="">
									<Input
										value={assigned}
										disabled={true}
									/>
								</div>
							</div>
							<div className="flex justify-between items-center space-x-2">
								<h1 className="text-lg font-bold">구매제품</h1>
								<div className="flex space-x-2">
									<div>
										<IconButton
											onButtonClicked={onProductAddButtonClicked}>
											<PlusIcon className="h-5 w-5 text-gray-500" />
										</IconButton>
									</div>
									<div>
										<IconButton
											onButtonClicked={onProductRemoveButtonClicked}
											disabled={_.isNil(selectedProductCardId)}
										>
											<MinusIcon className="h-5 w-5 text-gray-500" />
										</IconButton>
									</div>
								</div>
							</div>
							<div className="px-2 py-1.5 ring-1 ring-inset ring-gray-300 rounded-md">
								{productDatas.length === 0 && <div className="text-center text-gray-500 cursor-pointer hover:text-gray-500 hover:font-semibold sm:text-sm sm:leading-6" onClick={onProductAddButtonClicked}>구매제품을 추가해주세요</div>}
								<VerticalDragList
									isGripTop={false}
									itemsExternal={productDatas.map((product) => ({
										id: product.card_id,
										content: (
											<div
												id={product.card_id}
												onClick={() => { onCardClicked(product.card_id) }}
												className={classNames("p-3 hover:bg-indigo-50 rounded-b-md",
													selectedProductCardId === product.card_id ? "ring-indigo-500 ring-[3px] ring-inset" : "")}
											>
												<ProductCard
													cardId={product.card_id}
													productInfos={productInfos}
													onChange={(cardId, formData) => onProductFormChanged(cardId, formData)}
													initialData={product}
												/>
											</div>
										)
									}))}
									setItemsExternal={(items) => onProductOrderChanged(items)}
								/>
							</div>
							<h1 className="text-lg font-bold">복용 후 관리 유형</h1>
							<div className="">
								<Select items={aftercareItems} selectedItem={selectedAftercareItem} onChange={setSelectedAftercareItem}></Select>
							</div>
						</div>

						<div className="flex flex-col space-y-2 p-2">
							<div>
								<h1 className="text-lg font-bold">제품가격</h1>
								<Input
									value={String(totalPrice)}
									// onChange={(value) => setMainPrice(value)}
									// format={InputFormat.NUMBER}
									disabled={true}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">할인금</h1>
								<Input
									value={String(totalDiscount)}
									disabled={true}
								// onChange={(value) => setMainPrice(value)}
								// format={InputFormat.NUMBER}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">할인 후 제품가격</h1>
								<Input
									value={String(totalPrice - totalDiscount)}
									disabled={true}
								// onChange={(value) => setMainPrice(value)}
								// format={InputFormat.NUMBER}
								/>
							</div>
							{/* <div>
                                <h1 className="text-lg font-bold">주 상품 구매가격</h1>
                                <Input
                                    value={mainPrice}
                                    onChange={(value) => setMainPrice(value)}
                                    format={InputFormat.NUMBER}
                                />
                            </div>
                            <div>
                                <h1 className="text-lg font-bold">보조 상품 구매가격</h1>
                                <Input
                                    value={subPrice}
                                    onChange={(value) => setSubPrice(value)}
                                    format={InputFormat.NUMBER}
                                />
                            </div> */}
							{/* <div>
                                <h1 className="text-lg font-bold">결제수수료 유형</h1>
                                <Select
                                    items={FINANCIAL_FEE_TYPE_ITEMS}
                                    selectedItem={selectedFinancialFeeType}
                                    onChange={(item) => setSelectedFinancialFeeType(item)}
                                />
                            </div> */}
							<div>
								<h1 className="text-lg font-bold">결제수수료</h1>
								<Input
									value={financialFee}
									onChange={(value) => setFinancialFee(value)}
									format={InputFormat.NUMBER}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">배송비</h1>
								<Input
									value={deliveryFee}
									onChange={(value) => setDeliveryFee(value)}
									format={InputFormat.NUMBER}
								/>
							</div>

							<h1 className="text-lg font-bold">사용포인트</h1>
							<Input
								value={usedPoint}
								onChange={(value) => setUsedPoint(value)}
								format={InputFormat.NUMBER}
							/>
							<div>
								<h1 className="text-lg font-bold">총 구매가격</h1>
								<Input
									value={String(totalPrice - totalDiscount + Number(financialFee) + Number(deliveryFee) - Number(usedPoint))}
									disabled={true}
								// onChange={(value) => setMainPrice(value)}
								// format={InputFormat.NUMBER}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">결제수단</h1>
								<Select
									items={PAYMENT_METHOD_ITEMS}
									selectedItem={selectedPaymentMethod}
									onChange={(item) => setSelectedPaymentMethod(item)}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">통화</h1>
								<Select
									items={CURRENCY_ITEMS}
									selectedItem={selectedCurrency}
									onChange={(item) => setSelectedCurrency(item)}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">적립포인트</h1>
								<Input
									value={earnedPoint}
									onChange={(value) => setEarnedPoint(value)}
									format={InputFormat.NUMBER}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">메모</h1>
								<Input
									value={memo}
									onChange={(value) => setMemo(value)}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">비고</h1>
								<Input
									value={note}
									onChange={(value) => setNote(value)}
								/>
							</div>
							<div>
								<h1 className="text-lg font-bold">배송지</h1>
								<Input
									value={shippingAddress}
									onChange={(value) => setShippingAddress(value)}
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-between space-x-2">
						<div className="flex">
							<div>
								<Button onButtonClicked={openDetailModal}>
									진료내용확인
								</Button>
							</div>
						</div>
						<div className="flex justify-end space-x-2">
							<div>
								<Button onButtonClicked={() => { navigate(-1) }}>
									뒤로가기
								</Button>
							</div>
							<div>
								<Button onButtonClicked={() => { onSave() }}>
									저장
								</Button>
							</div>
							<div>
								<Button onButtonClicked={() => { onComplete() }}>
									완료
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Card>
			<DetailModal>
				{/* <DetailViewer row={row} onClose={closeDetailModal}></DetailViewer> */}
				<div>
					<DetailViewerContent submissionId={submissionId} onClose={closeDetailModal}></DetailViewerContent>
				</div>
			</DetailModal>
		</>
	)
}

export default PurchaseInfoEditor;