
import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminUserMemberPage from "pages/Admin/User/Member";
import AdminUserStaffPage from "pages/Admin/User/Staff";
import AdminUserDoctorPage from "pages/Admin/User/Doctor";

const AdminUserRoute = () => (
    <Routes>
        <Route path="/member" element={<AdminUserMemberPage />}></Route>
        <Route path="/staff" element={<AdminUserStaffPage />}></Route>
        <Route path="/doctor" element={<AdminUserDoctorPage />}></Route>
    </Routes>
)

export default AdminUserRoute;