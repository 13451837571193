import React, { useEffect, useState } from "react";
import _ from "lodash";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { classNames } from "components/uiUtils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import "./style.scss";

export const CustomDatePickerInput = React.forwardRef<HTMLButtonElement, React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>(
    ({ value, onClick, className, placeholder }, ref) => (
        <button
            className={classNames("relative inline-flex items-center place-content-center bg-white px-3 py-1.5 sm:text-sm sm:leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 text-nowrap focus:ring-2 focus:ring-indigo-500 focus:ring-inset", className)}
            type="button"
            onClick={onClick}
        >
            {_.isElement(value) ? value : _.isEmpty(value) ? placeholder : value}
        </button>
    )
)

// 80 years from now
const YEARS = Array.from({ length: 80 }, (_, i) => new Date().getFullYear() - i);
const MONTHS = Array.from({ length: 12 }, (_, i) => i);

export const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <div className="flex justify-center items-center text-center">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <ChevronLeftIcon className="h-8 w-8" />
        </button>
        <div className="text-base font-semibold px-1 w-fit">
            <select
                className="w-full p-0 border-none bg-transparent text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-sm"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
                style={{ backgroundImage: "none" }}
            >
                {YEARS.map((i) => (
                    <option key={i} value={i}>
                        {new Date(i, 6, 31).toLocaleString('default', { year: 'numeric' })}
                    </option>
                ))}
            </select>
        </div>
        <div className="text-base font-semibold px-1 w-fit">
            <select
                className="w-full p-0 border-none bg-transparent text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-sm"
                value={date.getMonth()}
                onChange={({ target: { value } }) => changeMonth(value)}
                style={{ backgroundImage: "none" }}
            >
                {MONTHS.map((i) => (
                    <option key={i} value={i}>
                        {new Date(0, i).toLocaleString('default', { month: 'short' })}
                    </option>
                ))}
            </select>
        </div>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <ChevronRightIcon className="h-8 w-8" />
        </button>
    </div>
);


export const SimpleDatePicker = ({ label = "", placeholderText="Click to select a date", dts = [], selectedDt = new Date(), onDtChanged = (dt: any) => { }, onClick = () => { }, ...otherProps },) => (
    <div className="flex rounded-md shadow-sm">
        {label &&
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm text-nowrap">
                {label}
            </span>
        }
        <div className={"w-full"} onClick={onClick}>
            <DatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={selectedDt}
                onChange={(date: Date) => { onDtChanged(date) }}
                showPopperArrow={false}
                className={classNames("w-full", "rounded-r-md", label || "rounded-l-md")}
                customInput={<CustomDatePickerInput />}
                renderCustomHeader={renderCustomHeader}
                dropdownMode="select"
                dateFormatCalendar="YYYY-MM"
                placeholderText={placeholderText}
                {...!_.isEmpty(dts) && { includeDates: dts }}
                {...otherProps}
            />
        </div>
    </div>
)

export const useDatePicker = ({ label, dts, onDtChanged }) => {
    const [selectedDt, setSelectedDt] = useState(dts[0]);

    return {
        selectedDt,
        setSelectedDt,
        DatePicker: ({ onClick, ...otherProps }) => (
            <SimpleDatePicker
                label={label}
                selectedDt={selectedDt}
                onDtChanged={(dt) => { setSelectedDt(dt); onDtChanged(dt) }}
                onClick={onClick}
                dts={dts}
                {...otherProps}
            />
        )
    }
}