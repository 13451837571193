import CRUDTable from "components/Table/CRUD";
import React, { useEffect, useRef, useState } from "react";
import { adminClient } from "apis/admin";
import { variableColumnInfos } from "./config";
import Card from "components/Card";
import Textarea from "components/Form/Textarea";
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import Button from "components/Form/Button";
import _, { set } from "lodash";
import { useModal } from "components/Modal/Simple";
import Select from "components/Form/Select";
import { useNavigate, useParams } from "react-router-dom";
import QuillWithVariable, { VARIABLE_BG_COLOR } from "./QuillWithVariable";
import { toast } from "react-toastify";
import { useQuill } from 'react-quilljs';
import Quill from "quill";
import { assembleQuillContentsWithFields, validateAllFieldsIncludeInContents } from "./helper";
import { PrescriptionTemplateFieldType } from "./types";
import QuillMadeArticle from "components/Article/QuillMade";

const PrescriptionTemplateEditorPage = () => {
    const prescription_template_id = useParams().prescription_template_id;
    const [templateData, setTemplateData] = useState<any>({});
    const navigate = useNavigate();
    const [variableTableData, setVariableTableData] = useState(null);
    const [variableSelectItems, setVariableSelectItems] = useState(null);
    const [originQuill, setOriginQuill] = useState(null);
    const [transQuill, setTransQuill] = useState(null);
    const [previewHtml, setPreviewHtml] = useState("");
    const { Modal: PreviewModal, openModal: openPreviewModal, closeModal: closePreviewModal } = useModal({});
    const [previewQuill, setPreviewQuill] = useState(new Quill(document.createElement('div')));

    useEffect(() => {
        console.log("[PrescriptionTemplatePage] use effect prescription_template_id", prescription_template_id);
        updateTemplateFromDb();
    }, [prescription_template_id])

    const updateTemplateFromDb = async () => {
        try {
            const ret = await adminClient.get(`/prescription-templates/${prescription_template_id}`);
            console.log("[PrescriptionTemplatePage] updateTemplateFromDb", ret.data)
            setTemplateData(_.omit(ret.data, ["_id"]));
            setVariableTableData(ret.data.fields);
        } catch (e) {
            alert("해당 템플릿이 존재하지 않습니다.");
            navigate("/admin/clinic/prescription-template");
        }
    }

    const onVariableCreateRequested = async (data) => {
        console.log("[PrescriptionTemplatePage] onVariableCreateRequested", data);
        if (variableTableData.find((row) => row.key === data.key)) {
            alert("이미 존재하는 변수입니다.");
            return false;
        }
        setVariableTableData([...variableTableData, data]);
        return true;
    };

    const onVariableEditRequested = async (data) => {
        console.log("[PrescriptionTemplatePage] onVariableEditRequested", data);
        const newTableData = variableTableData.map((row) => {
            if (row.key === data.key) {
                return data;
            }
            return row;
        });
        setVariableTableData(newTableData);
        return true;
    }

    const onVariableDeleteRequested = async (data) => {
        console.log("[PrescriptionTemplatePage] onVariableDeleteRequested", data);
        const newTableData = variableTableData.filter((row) => row.key !== data.key);
        setVariableTableData(newTableData);
        return true;
    }

    useEffect(() => {
        if (_.isNil(variableTableData)) {
            return;
        }
        const tempItems = variableTableData.map((elm) => ({ name: elm.key }));
        setVariableSelectItems(tempItems);
    }, [variableTableData])

    useEffect(() => {
        if (_.isNil(originQuill) || _.isNil(transQuill)) {
            return;
        }
        if (_.isNil(templateData.contents)) {
            return;
        }
        console.log("[SET ORIGIN CONTENT BY UPDATE]", templateData.contents);
        originQuill.setContents(templateData.contents);
        console.log("[SET TRANS CONTENT BY UPDATE]", templateData.translated_contents);
        transQuill.setContents(templateData.translated_contents);
    }, [originQuill, transQuill, templateData])

    const onSaveRequested = async () => {
        const originContents = originQuill.getContents();
        const transContents = transQuill.getContents();
        const fields = variableTableData;
        const newTemplateData = {
            ...templateData,
            contents: originContents,
            translated_contents: transContents,
            fields: fields
        }
        const notExistVarsInOrigin = validateAllFieldsIncludeInContents(originContents, fields);
        console.log("[PrescriptionTemplatePage] onSaveRequested notExistVarsInOrigin", notExistVarsInOrigin)
        if (!_.isEmpty(notExistVarsInOrigin)) {
            alert(`원본에 존재하지 않는 변수가 있습니다. ${notExistVarsInOrigin.map((elm) => elm.key).join(", ")}`);
            return;
        }
        const notExistVarsInTrans = validateAllFieldsIncludeInContents(transContents, fields);
        if (!_.isEmpty(notExistVarsInTrans)) {
            alert(`번역본에 존재하지 않는 변수가 있습니다. ${notExistVarsInTrans.map((elm) => elm.key).join(", ")}`);
            return;
        }
        console.log("[PrescriptionTemplatePage] onSaveRequested", newTemplateData);
        try {
            const ret = await adminClient.post(`/prescription-templates`, newTemplateData);
            toast.success("템플릿 저장에 성공했습니다");
        } catch (e) {
            toast.error("템플릿 저장에 실패했습니다");
        }
    }

    const onOriginQuillReady = (quill) => {
        console.log("[ON ORIGIN QUILL READY]", quill, templateData);
        setOriginQuill(quill);
    }

    const onTransQuillReady = (quill) => {
        console.log("[ON TRANS QUILL READY]", quill, templateData);
        setTransQuill(quill);
    }

    const createAndSetPreviewHtml = (contents, isTranslated) => {
        const fields = variableTableData;
        const shortFields = fields.map((elm) => ({ ...elm, type: PrescriptionTemplateFieldType.SHORT })) // because just table data. so, type is short
        const newContents = assembleQuillContentsWithFields(contents, shortFields, isTranslated);
        console.log("[createAndSetPreviewHtml] newContents", newContents)
        previewQuill.setContents(newContents);
        setPreviewHtml(previewQuill.getSemanticHTML());
    }

    return (
        <>
            <Card>
                <div className="p-3">
                    <Card>
                        <h1 className="text-md font-semibold ms-2 mt-2">변수</h1>
                        <div className="p-2">
                            <CRUDTable
                                data={variableTableData}
                                columns={variableColumnInfos}
                                onCreate={onVariableCreateRequested}
                                onEdit={onVariableEditRequested}
                                onDelete={onVariableDeleteRequested}
                                isReordable={true}
                                onReorder={async (newData) => { setVariableTableData(newData); return true; }}    
                            />
                        </div>
                    </Card>
                    <div className="grid lg:grid-cols-2">
                        <div>
                            <Card>
                                <h1 className="text-md font-semibold ms-2 mt-2">원본</h1>
                                <QuillWithVariable uid={"origin"} variableSelectItems={variableSelectItems} onQuillReady={onOriginQuillReady}></QuillWithVariable>
                                <div className="flex justify-end me-2 mb-2">
                                    <div>
                                        <Button onButtonClicked={() => { createAndSetPreviewHtml(originQuill.getContents(), false); openPreviewModal() }}>미리보기</Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div>
                            <Card>
                                <h1 className="text-md font-semibold ms-2 mt-2">번역본</h1>
                                <QuillWithVariable uid={"trans"} variableSelectItems={variableSelectItems} onQuillReady={onTransQuillReady}></QuillWithVariable>
                                <div className="flex justify-end me-2 mb-2">
                                    <div>
                                        <Button onButtonClicked={() => { createAndSetPreviewHtml(transQuill.getContents(), true); openPreviewModal() }}>미리보기</Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <div>
                            <Button onButtonClicked={() => { navigate("/admin/clinic/prescription-template") }}>뒤로가기</Button>
                        </div>
                        <div>
                            <Button onButtonClicked={onSaveRequested}>저장</Button>
                        </div>
                    </div>
                </div>
            </Card>
            <PreviewModal>
                <div>
                    <QuillMadeArticle htmlText={previewHtml}></QuillMadeArticle>
                </div>
            </PreviewModal>
        </>

    )
}

export default PrescriptionTemplateEditorPage;
