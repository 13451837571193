import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import QuestionMaker from './QuestionMaker';
import Select from 'components/Form/Select';
// import Select from 'components/Form/Select/deprecated';
import Dropdown from 'components/Form/Dropdown';
// import Dropdown from 'components/Form/Dropdown/deprecated';
import Button from 'components/Form/Button';
import { randomString } from 'utils/math';
import VerticalDragList from 'components/Drag/VerticalList';
import HorizontalDragList from 'components/Drag/HorizontalList';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import IconButton from 'components/Form/Button/Icon';
import { classNames, scrollToElm } from 'components/uiUtils';
import { QuestionType, AnswerFormat } from 'containers/Questions/Question/types';
import { Option } from './ChoiceMaker';
import { createNewQuestionItem, findQuestionTypeItem, findAnswerFormatItem, filterQuestions, updateRecursive, findParentQuestionItem, addSubQuestion, removeQuestion, addQuestion, convertQuestionsItemsToDbSchema, createNewSectionItem, generateNewIntakeId, convertQuestionsDbSchemaToItems, createNewIntake } from './helpers';
import TopBar from 'components/Bar/Top';
import SectionManager from './SectionManager';
import IntakeEditForm, { IntakeInfo } from 'containers/Intake/Form/Edit';

import { useModal } from 'components/Modal/Simple';

import _, { set } from 'lodash';
import { adminClient } from 'apis/admin';
import { toast } from 'react-toastify';
import { SectionItem } from './SectionManager/types';
import { Question } from 'containers/Questions/Question/types';

import SpinnerMedium from 'components/Spinner/Medium';
import { confirmDelete } from 'utils/browser';

export type QuestionItem = {
    question_id: string;
    data: {
        questionText: string;
        type: QuestionType;
        format?: AnswerFormat;
        options: Option[];
        condition?: string;
        conditionText?: string;
    };
    sub_items?: QuestionItem[];
};

export type IntakeItem = {
    intake_id: string;
    name: string;
}

// Inherit from IntakeInfo
export type Intake = IntakeInfo & {
    sections: Section[];
}

export type Section = {
    section_id: string;
    name: string;
    questions: Question[];
}

const INITIAL_QUESTION_ITEMS = [
    // createNewQuestionItem(QuestionType.SHORT_ANSWER),
    // createNewQuestion(),
]

// INITIAL_ITEMS[0].sub_items = [
//     createNewQuestion(),
//     createNewQuestion(),
// ]

// INITIAL_ITEMS[0].sub_items[0].sub_items = [
//     createNewQuestion(),
//     createNewQuestion(),
// ]

const INITIAL_SECTION_ITEMS = [
    createNewSectionItem(),
]

const SELECT_SECTION_ITEMS: SectionItem[] = [
    {
        section_id: "section1",
        name: "제목없는 섹션 1"
    },
    {
        section_id: "section2",
        name: "제목없는 섹션 2"
    },
    {
        section_id: "section3",
        name: "제목없는 섹션 3"
    },
]

const COMBO_INTAKE_ITEMS = [
    {
        name: "라연 한의원 초진"
    },
    {
        name: "라연 한의원 재진"
    },
    {
        name: "참경희 클레어 초진"
    },
    {
        name: "참경희 클레어 재진"
    },
    {
        name: "다이어트닥터 초진"
    },
    {
        name: "다이어트닥터 재진"
    },
]

const WORK_DROPDOWN_ITEMS = [
    {
        name: "진료지 목록"
    },
    {
        name: "진료지 번역"
    },
    null,
    {
        name: "변경사항 저장"
    },
    {
        name: "변경사항 초기화"
    },
    null,
    {
        name: "미리보기"
    },
]

// const LOCAL_STORAGE_QUESTIONS_VAR = "intake_editor_question_items";
const LOCAL_STORAGE_SECTIONS_VAR = "section_manager_section_items";

const LOCAL_STORAGE_LAST_INTAKE_ID = "intake_editor_last_intake_id";
const LOCAL_STORAGE_LAST_SECTION_ID = "intake_editor_last_section_id";

const postIntake = async (intake: Intake): Promise<boolean> => {
    console.log("[Form Maker] postIntakeInfo", intake);
    try {
        const ret = await adminClient.post(`/intakes`, intake);
        console.log("[Form Maker] postIntakeInfo ret", ret);
        toast.success("진료지 저장이 완료되었습니다.");
    } catch (e) {
        console.error("[Form Maker] postIntakeInfo error", e?.response?.data);
        if (e?.response?.data?.includes("isNotEmpty")) {
            toast.error("진료지 저장에 실패했습니다. 모든 필드를 입력해주세요.");
            return false;
        }
        toast.error("진료지 저장에 실패했습니다.");
        return false;
    }
    return true;
}

const putIntake = async (intake: Intake): Promise<boolean> => {
    console.log("[Form Maker] putIntake", intake);
    try {
        const ret = await adminClient.put(`/intakes/${intake.intake_id}`, intake);
        console.log("[Form Maker] putIntake ret", ret);
        toast.success("진료지 수정이 완료되었습니다.");
    } catch (e) {
        console.error("[Form Maker] putIntake error", e);
        if (e?.response?.data?.message?.includes("There are no changes to that ID")) {
            toast.warn("변경사항이 없습니다.");
            return false;
        }
        toast.error("진료지 수정에 실패했습니다.");
        return false;
    }
    return true;
}

const getIntakeItems = async () => {
    console.log("[Form Maker] getIntakeItems");
    try {
        const ret = await adminClient.get(`/intakes/distinct/latest`);
        console.log("[Form Maker] getIntakeItems ret", ret);
        // toast.success("진료지 목록을 불러왔습니다.");
        return ret.data;
    } catch (e) {
        console.error("[Form Maker] getIntakeItems error", e);
        toast.error("진료지 목록을 불러오는데 실패했습니다.");
        return false;
    }
}

const getIntakeItem = async (intakeId: string) => {
    console.log("[Form Maker] getIntakeItem", intakeId);
    try {
        const ret = await adminClient.get(`/intakes/${intakeId}`);
        console.log("[Form Maker] getIntakeItem ret", ret);
        toast.success("진료지를 불러왔습니다.");
        return ret.data;
    } catch (e) {
        console.error("[Form Maker] getIntakeItem error", e);
        toast.error("진료지를 불러오는데 실패했습니다.");
        return false;
    }
}

const IntakeEditor = () => {
    const pathParams = useParams();
    const navigate = useNavigate();
    const [questionItems, setQuestionItems] = useState(() => {
        const savedItems = localStorage.getItem("form_maker_question_items");
        return savedItems ? JSON.parse(savedItems) : null;
    });
    const [selectedQuestionId, setSelectedQuestionId] = useState<string>(null);
    const { Modal: SectionManageModal, openModal: openSectionManagerModal, closeModal: closeSectionManagerModal, isOpen: isSectionManagerOpen } = useModal({ styles: { content: { width: "350px", overflow: "hidden" } } });
    const [sectionItems, setSectionItems] = useState(() => {
        const sections = localStorage.getItem(LOCAL_STORAGE_SECTIONS_VAR);
        console.log("[Form Maker] useState initializer local storage", sections)
        if (sections) {
            return JSON.parse(sections);
        }
        return null;
    });
    const [selectedSectionId, setSelectedSectionId] = useState<string>(null);
    const { Modal: IntakeEditModal, openModal: openIntakeEditModal, closeModal: closeIntakeEditModal, isOpen: isIntakeEditModalOpen } = useModal({ styles: { content: { width: "350px", overflow: "hidden" } } });
    const [intakeItems, setIntakeItems] = useState(null);
    const [selectedIntakeItem, setSelectedIntakeItem] = useState(null);
    const [intakeDocFromDb, setIntakeDocFromDb] = useState<Intake>(null);

    const updateIntakeItemsFromDb = async () => {
        const intakesRet = await getIntakeItems();
        if (!intakesRet) {
            return;
        }
        const intakeItems = intakesRet.map((elm: Intake) => ({ name: elm.title, intake_id: elm.intake_id, clinic_id: elm.clinic_id }))
        setIntakeItems(intakeItems);
        const lastIntakeId = localStorage.getItem(LOCAL_STORAGE_LAST_INTAKE_ID);
        if (!lastIntakeId) {
            console.log("[FormMaker:updateIntakeItemsFromDb] lastIntakeId is null")
            // toast.warn("마지막으로 수정중이던 진료지가 없습니다. 가장 처음의 진료지를 선택합니다.");
            setSelectedIntakeItem(intakeItems[0]);
            return;
        }
        const lastIntakeItem = intakeItems.find((elm: IntakeItem) => elm.intake_id === lastIntakeId);
        if (!lastIntakeItem) {
            console.error("[FormMaker:updateIntakeItemsFromDb] lastIntakeItem is null")
            // toast.warn("마지막으로 수정중이던 진료지가 서버에 존재하지 않습니다. 가장 처음의 진료지를 선택합니다.");
            setSelectedIntakeItem(intakeItems[0]);
            return;
        }
        setSelectedIntakeItem(lastIntakeItem);
    }

    const updateIntakeDocFromDb = async (intakeItem) => {
        const intakeRet: Intake = await getIntakeItem(intakeItem.intake_id);
        if (!intakeRet) {
            return;
        }
        console.log("[FormMaker:updateSectionItemsFromDb] setIntakeDocFromDb", intakeRet);
        setIntakeDocFromDb(intakeRet);
    }

    const updateSectionItemsUsingLocalStorage = (intakeId: string) => {
        console.log("[Form Maker::updateSectionItemsUsingLocalStorage] intakeId", intakeId);
        const intakeDoc = JSON.parse(localStorage.getItem(intakeId));
        if (_.isNil(intakeDoc)) {
            console.error("[Form Maker::updateSectionItemsUsingLocalStorage] intakeDoc is null");
            toast.error("로컬 스토리지에 진료지 정보가 없습니다.");
            return;
        }
        const { sections } = intakeDoc;
        if (_.isEmpty(sections)) {
            setSectionItems(null);
            toast.warn("섹션 정보가 없습니다.");
            return;
        }
        const sectionItems = sections.map((section) => ({
            section_id: section.section_id,
            name: section.name
        }));
        setSectionItems(sectionItems);
        const lastSectionId = localStorage.getItem(LOCAL_STORAGE_LAST_SECTION_ID);
        console.log("[FormMaker:updateSectionItemsUsingLocalStorage] lastSectionId", lastSectionId)
        if (_.isNil(lastSectionId)) {
            console.log("[FormMaker:updateSectionItemsUsingLocalStorage] lastSectionId is null")
            setSelectedSectionId(sectionItems[0].section_id);
            return;
        }
        const found = sectionItems.find((elm: SectionItem) => elm.section_id === lastSectionId);
        if (!found) {
            console.error("[FormMaker:updateSectionItemsUsingLocalStorage] lastSectionId is not found in sectionItems")
            setSelectedSectionId(sectionItems[0].section_id);
            return;
        } else {
            console.log("[FormMaker:updateSectionItemsUsingLocalStorage] lastSectionId is found in sectionItems")
            setSelectedSectionId(lastSectionId);
        }
    }

    const onAddButtonClicked = () => {
        console.log("[Form Maker] onAddButtonClicked", selectedQuestionId)
        const newItem = createNewQuestionItem(QuestionType.SHORT_ANSWER);
        const newQuestions = addQuestion(questionItems, selectedQuestionId, newItem);
        setQuestionItems(newQuestions);
        // scroll to new Item's top
        // setTimeout(() => {
        //     const elm = document.getElementById(newItem.question_id);
        //     if (elm) {
        //         elm.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        //         setSelectedId(newItem.question_id);
        //     }
        // }); // zero if the argument was omitted
        scrollToElm(newItem.question_id);
        setSelectedQuestionId(newItem.question_id);
    };

    const onRemoveButtonClicked = () => {
        const newQuestions = removeQuestion(questionItems, selectedQuestionId);
        setQuestionItems(newQuestions);
        setSelectedQuestionId(null);
    };

    const onQuestionTextChanged = (id, text) => {
        const newQuestions = updateRecursive("questionText")([...questionItems], id, text);
        setQuestionItems(newQuestions);
    };

    const onConditionalAddButtonClicked = (parentQuestionId, optionId, idx) => {
        // just add last subitem
        const newItem = createNewQuestionItem(QuestionType.SHORT_ANSWER, optionId); // condition
        const newQuestions = addSubQuestion(questionItems, parentQuestionId, newItem);
        setQuestionItems(newQuestions);
    };

    const onOrderChanged = (itemsWithContents) => {
        const newItems = itemsWithContents.map((item) => {
            const found = itemsWithContents.find((elm) => elm.id === item.id);
            if (!found) return item;
            return found;
        });
        setQuestionItems(newItems);
    }

    const onMouseOver = (e, id) => {
        // used for debugging
        // console.log("DIVMOUSEOVER", e, id);
    }

    const onDivClicked = (e, id) => {
        // console.log("DIVCLICKED", id);
        if (selectedQuestionId === id) {
            setSelectedQuestionId(null);
            return;
        }
        setSelectedQuestionId(id);
    }

    const onOptionChanged = (questionId: string, optionId: string, value: string) => {
        console.log("[Form Maker] onOptionChanged", questionId, optionId, value);
        // Do Nothing because it is already removed by onOptionsReceived
    }

    const onTypeReceived = (questionId: string, type: QuestionType) => {
        console.log("[Form Maker] onTypeReceived", questionId, type);
        const newQuestions = updateRecursive("type")([...questionItems], questionId, type);
        setQuestionItems(newQuestions);
    }

    const onFormatReceived = (questionId: string, format: AnswerFormat) => {
        console.log("[Form Maker] onFormatReceived", questionId, format);
        const newQuestions = updateRecursive("format")([...questionItems], questionId, format);
        setQuestionItems(newQuestions);
    }

    const onOptionsReceived = (questionId: string, optionLists: Option[]) => {
        console.log("[Form Maker] onOptionsReceived", optionLists);
        const newQuestions = updateRecursive("options")([...questionItems], questionId, optionLists);
        const clearedQuestions = filterQuestions(newQuestions, []);
        setQuestionItems(clearedQuestions);
    }

    const createItemWithContent = (item: QuestionItem, idx: number, parentOrderIdx = '') => {
        const currentOrderIdx = parentOrderIdx ? `${parentOrderIdx}-${idx + 1}` : `${idx + 1}`;
        const parentItem = findParentQuestionItem(questionItems, item);
        const hideSubItems = ![QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE].includes(item.data.type);

        return {
            ...item,
            id: item.question_id,
            content: (
                <div
                    id={item.question_id}
                    className={classNames("p-3 hover:bg-indigo-50 rounded-b-md",
                        selectedQuestionId === item.question_id ? "ring-indigo-500 ring-[3px] ring-inset" : "")}
                    onMouseOver={(e) => { onMouseOver(e, item.question_id) }}
                    onClick={(e) => { onDivClicked(e, item.question_id) }}
                >
                    <QuestionMaker
                        orderIdx={currentOrderIdx}
                        item={item}
                        parentItem={parentItem}
                        selectedTypeExternal={findQuestionTypeItem(item.data.type)}
                        setSelectedTypeExternal={onTypeReceived}
                        selectedFormatExternal={findAnswerFormatItem(item.data.format)}
                        setSelectedFormatExternal={onFormatReceived}
                        setOptions={onOptionsReceived}
                        onQuestionTextChanged={(id, text) => { onQuestionTextChanged(id, text) }}
                        onConditionalAddButtonClicked={onConditionalAddButtonClicked}
                        onOptionChanged={onOptionChanged}
                    />
                </div>
            ),
            sub_items: item.sub_items?.map((sub_elm, sub_idx) => createItemWithContent(sub_elm, sub_idx, currentOrderIdx)),
            hideSubItems
        };
    };

    const onDropdownItemClicked = (item) => {
        console.log("[Form Maker] onDropdownItemClicked", item);
        if (item.name === "진료지 목록") {
            navigate("/admin/intake/list")
        } else if (item.name === "진료지 번역") {
            navigate(`/admin/intake/translate/${selectedIntakeItem.intake_id}`)
        }
        else if (item.name === "변경사항 초기화") {
            const selectedIntakeId = selectedIntakeItem.intake_id;
            localStorage.setItem(selectedIntakeId, JSON.stringify(intakeDocFromDb));
            window.location.reload();
            // remove local storage
            // localStorage.removeItem(LOCAL_STORAGE_QUESTIONS_VAR);
            // setQuestionItems(INITIAL_QUESTION_ITEMS);
            // setSelectedQuestionId(null);
        }
        else if (item.name === "미리보기") {
            // Do Something
            // new tab open
            const intake_id = selectedIntakeItem.intake_id;
            window.open(`${process.env.REACT_APP_SITE_URL}/intake/preview/${intake_id}`, "_blank");
            // window.open(`http://localhost:4000/intake/preview/${intake_id}`, "_blank");
        } else if (item.name === "변경사항 저장") {
            onSaveButtonClicked();
        }
    }

    const onSaveButtonClicked = async () => {
        console.log("[Form Maker:onSaveButtonClicked] questionItems", questionItems);
        const selectedIntakeId = selectedIntakeItem.intake_id;
        //Pre Process
        // 1. remove 'options' in non choice
        // Use This In Save Button
        // if (type !== QuestionType.SINGLE_CHOICE && type !== QuestionType.MULTIPLE_CHOICE) {
        // remove all sub_items in questionId // not filter
        // const clearedQuestions = deleteSubItems(newQuestions, questionId);
        // setQuestionItems(clearedQuestions);
        // return;
        // }
        // 2. remove 'format' in non short answer
        const questionDocArr = convertQuestionsItemsToDbSchema(questionItems);
        console.log("[Form Marker:onSaveButtonClicked] questionDocArr", questionDocArr);
        // creat sections for all sections
        // const sections = sectionItems.map((sectionItem: SectionItem) => {
        const lastLocalStorageIntake = localStorage.getItem(selectedIntakeId);
        if (_.isNil(lastLocalStorageIntake)) {
            console.error("[Form Maker::onSaveButtonClicked] lastLocalStorageIntake is null")
            return;
        }
        const lastIntakeDoc = JSON.parse(lastLocalStorageIntake);
        console.log("[Form Maker::onSaveButtonClicked] lastIntakeDoc", lastIntakeDoc)
        if (lastIntakeDoc.intake_id !== selectedIntakeId) {
            console.error("[Form Maker::onSaveButtonClicked] lastIntakeDoc.intake_id !== selectedIntakeItem.intake_id")
            toast.error("수정중인 진료지 정보가 일치하지 않습니다.");
            return;
        }
        // send to server
        try {
            console.log("[Form Maker::onSaveButtonClicked] putIntake", lastIntakeDoc)
            // const ok = await putIntake(lastIntakeDoc); // not use now
            const ok = await postIntake(lastIntakeDoc);
            console.log("[Form Maker::onSaveButtonClicked] putIntake success. ret is", ok)
            if (!ok) {
                return
            }
            console.log("[Form Maker::onSaveButtonClicked] putIntake success. so clear local storage of", selectedIntakeId);
            localStorage.removeItem(selectedIntakeId);
            await updateIntakeItemsFromDb();
        } catch (e) {
            console.error("Post ERROR", e)
        }
    }

    const omEditButtonClicked = () => {
        console.log("[Form Maker] omEditButtonClicked");
        openSectionManagerModal();
    }

    const onModalSectionItemSaved = (items: SectionItem[]) => {
        console.log("[Form Maker] onModalSectionItemSaved", items);
        if (_.isEmpty(items)) {
            return;
        }
        setSectionItems(items);
        closeSectionManagerModal();
    }

    const onSectionItemSelected = (item: SectionItem) => {
        console.log("[Form Maker] onSectionItemSelected", item);
        if (_.isNil(item)) {
            return;
        }
        setSelectedSectionId(item.section_id);
    }

    const onNewIntakeButtonClicked = () => {
        console.log("[Form Maker] onNewIntakeButtonClicked");
        openIntakeEditModal();
    }

    const onDeleteIntakeButtonClicked = async () => {
        console.log("[Form Maker] onDeleteIntakeButtonClicked", selectedIntakeItem);
        const ok = confirmDelete(`"${selectedIntakeItem.name}" 진료지 삭제를 진행합니다.`);
        if (!ok) {
            return;
        }
        const intakeId = selectedIntakeItem.intake_id;
        try {
            const ret = await adminClient.delete(`/intakes/${intakeId}`);
            console.log("[Form Maker] onDeleteIntakeButtonClicked ret", ret);
            toast.success("진료지 삭제가 완료되었습니다.");
            localStorage.removeItem(intakeId);
            await updateIntakeItemsFromDb();
        } catch (e) {
            console.error("[Form Maker] onDeleteIntakeButtonClicked error", e);
            toast.error("진료지 삭제에 실패했습니다.");
        }
    }

    const onNewIntakeSaveRequested = async (intakeInfo: IntakeInfo) => {
        console.log("[Form Maker] onNewIntakeSaveRequested", intakeInfo);
        const newIntake = createNewIntake(intakeInfo);
        const ok = await postIntake(newIntake);
        if (ok) {
            localStorage.setItem(LOCAL_STORAGE_LAST_INTAKE_ID, newIntake.intake_id);
            await updateIntakeItemsFromDb();
            closeIntakeEditModal();
        }
    }

    const onIntakeDocFromDbChanged = () => {
        console.log("[Form Maker] onIntakeDocFromDbChanged")
        console.log("[Form Maker] new intake is selected. so decide to use local storage or db intake doc");
        const selected_intake_id = selectedIntakeItem?.intake_id;
        const itakeDocWithoutDbVariables = _.omit(intakeDocFromDb, ["_id", "_updated_at"]);
        console.log("[Form Maker] useEffect [intakeDocFromDb] try update intakeDocFromDb to local storage", itakeDocWithoutDbVariables);
        const lastLocalStorageIntake = localStorage.getItem(selected_intake_id);
        const lastIntakeDoc = JSON.parse(lastLocalStorageIntake);
        if (_.isEqual(itakeDocWithoutDbVariables, lastIntakeDoc)) {
            console.log("[Form Maker] useEffect [intakeDocFromDb] intakeDocFromDb is same with local storage intake doc. so do nothing");
            return;
        }
        console.log("[Form Maker] useEffect [intakeDocFromDb] intakeDocFromDb is different with local storage intake doc. local", lastIntakeDoc, "db", itakeDocWithoutDbVariables);
        if (!_.isNil(lastLocalStorageIntake)) {
            const ok = window.confirm("수정중이던 진료지가 있습니다. 불러오시겠습니까?");
            if (ok) {
                console.log("[Form Maker] useEffect [intakeDocFromDb] intakeDocFromDb to local storage canceled. using local storage intake doc");
                return;
            }
        }
        console.log("[Form Maker] useEffect [intakeDocFromDb] intakeDocFromDb to local storage confirmed. using intake doc from db")
        console.log("[Form Maker] useEffect [intakeDocFromDb] intakeDocFromDb to local storage saved");
        // updateSectionItemsFromDb(selectedIntakeItem);
        localStorage.setItem(selected_intake_id, JSON.stringify(itakeDocWithoutDbVariables));
    }

    const onIntakeItemSelected = (item) => {
        console.log("[Form Maker] onIntakeItemSelected newItem", item, "origin", selectedIntakeItem);
        const newItem = { ...item };
        setSelectedIntakeItem(newItem);
    }

    useEffect(() => {
        console.log("[Form Maker] useEffect []");
        const intakeIdFromUrl = pathParams.intakeId;
        console.log("[Form Maker] useEffect [] intakeIdFromUrl", intakeIdFromUrl);
        if (!_.isNil(intakeIdFromUrl)) {
            console.log("[Form Maker] useEffect [] pathParam intakeIdFromUrl found. so set to local storage")
            localStorage.setItem(LOCAL_STORAGE_LAST_INTAKE_ID, intakeIdFromUrl);
        }
        updateIntakeItemsFromDb();
    }, [])

    useEffect(() => {
        console.log("[Form Maker] useEffect [intakeDocFromDb]", intakeDocFromDb, selectedIntakeItem);
        if (_.isNil(intakeDocFromDb)) {
            console.log("[Form Maker] useEffect [intakeDocFromDb] intakeDocFromDb is null");
            return;
        }
        if (_.isNil(selectedIntakeItem)) {
            console.log("[Form Maker] useEffect [intakeDocFromDb] selectedIntakeItem is null");
            return;
        }
        onIntakeDocFromDbChanged();
        updateSectionItemsUsingLocalStorage(selectedIntakeItem.intake_id);
    }, [intakeDocFromDb])

    useEffect(() => {
        console.log("[Form Maker] useEffect question items", questionItems);
        if (_.isNil(questionItems)) {
            console.warn("useEffect [questionItems] QuestionItems is null")
            return;
        }
        if (_.isEmpty(questionItems)) {
            console.warn("useEffect [questionItems] QuestionItems is empty")
            return;
        }
        if (_.isNil(selectedIntakeItem)) {
            console.warn("useEffect [questionItems] SelectedIntakeItem is null");
            return;
        };
        if (_.isNil(sectionItems)) {
            console.warn("useEffect [questionItems] SectionItems is empty");
            return;
        }
        // save to local storage
        // localStorage.setItem(LOCAL_STORAGE_QUESTIONS_VAR, JSON.stringify(questionItems));
        const section: Section = {
            section_id: selectedSectionId,
            name: sectionItems.find((elm: SectionItem) => elm.section_id === selectedSectionId).name,
            questions: convertQuestionsItemsToDbSchema(questionItems)
        }
        const lastLocalStorageIntake = localStorage.getItem(selectedIntakeItem.intake_id);
        if (_.isNil(lastLocalStorageIntake)) {
            console.error("useEffect [questionItems] LastLocalStorageIntake is null");
            return;
        }
        const lastIntakeDoc = JSON.parse(lastLocalStorageIntake);
        // update section that section_id is matched
        const newSections = lastIntakeDoc.sections.map((elm: Section) => {
            if (elm.section_id === section.section_id) {
                return section;
            }
            return elm;
        })
        const newIntakeDoc = {
            ...lastIntakeDoc,
            sections: newSections
        }
        console.log("[Form Maker] useEffect [questionItems] update LastLocalStorageIntake", newIntakeDoc);
        localStorage.setItem(selectedIntakeItem.intake_id, JSON.stringify(newIntakeDoc));
    }, [questionItems]);

    useEffect(() => {
        console.log("[Form Maker] useEffect section items", sectionItems);
        if (_.isEmpty(sectionItems)) {
            console.warn("useEffect [sectionItems] SectionItems is empty")
            setSelectedSectionId(null);
            return;
        }
        if (_.isNil(selectedIntakeItem)) {
            console.warn("useEffect [sectionItems] SelectedIntakeItem is null")
            return;
        }
        // save to local storage
        localStorage.setItem(LOCAL_STORAGE_SECTIONS_VAR, JSON.stringify(sectionItems));
        const lastLocalStorageIntake = localStorage.getItem(selectedIntakeItem.intake_id);
        if (_.isNil(lastLocalStorageIntake)) {
            console.warn("useEffect [sectionItems] LastLocalStorageIntake is null");
            return;
        }
        const lastIntakeDoc = JSON.parse(lastLocalStorageIntake);
        console.log("useEffect [sectionItems] lastIntakeDoc", lastIntakeDoc);
        // update section using sectionItems
        const newSections = sectionItems.map((sectionItem: SectionItem) => {
            const section = lastIntakeDoc.sections.find((elm: Section) => elm.section_id === sectionItem.section_id);
            if (_.isNil(section)) {
                console.warn("useEffect [sectionItems] lastIntakeDoc.sections.find is null, so it's new section");
                const newSection: Section = {
                    section_id: sectionItem.section_id,
                    name: sectionItem.name,
                    questions: []
                }
                return newSection;
            }
            return {
                ...section,
                name: sectionItem.name
            }
        })
        const newIntakeDoc = {
            ...lastIntakeDoc,
            sections: newSections
        }
        console.log("[Form Maker] useEffect sectionItems] update last local storage intake", newIntakeDoc);
        localStorage.setItem(selectedIntakeItem.intake_id, JSON.stringify(newIntakeDoc));
    }, [sectionItems])

    useEffect(() => {
        console.log("[Form Maker] useEffect selectedIntakeItem", selectedIntakeItem);
        setSelectedQuestionId(null);
        setSelectedSectionId(null);
        setQuestionItems(null);
        setSectionItems(null);
        if (_.isNil(selectedIntakeItem)) {
            return;
        }
        updateIntakeDocFromDb(selectedIntakeItem);
        localStorage.setItem(LOCAL_STORAGE_LAST_INTAKE_ID, selectedIntakeItem?.intake_id);
        navigate(selectedIntakeItem?.intake_id)
    }, [selectedIntakeItem])

    useEffect(() => {
        console.log("[Form Maker] useEffect selectedSectionId", selectedSectionId);
        setSelectedQuestionId(null);
        if (_.isNil(selectedSectionId)) {
            console.log("useEffect [selectedSectionId] selectedSectionId is null, so clear questionItems")
            setQuestionItems(null);
            return;
        }
        // load from last local storage
        const lastLocalStorageIntake = localStorage.getItem(selectedIntakeItem.intake_id);
        if (_.isNil(lastLocalStorageIntake)) {
            console.warn("useEffect [selectedSectionId] lastLocalStorageIntake is null");
            return;
        }
        const lastIntakeDoc = JSON.parse(lastLocalStorageIntake);
        console.log("useEffect [selectedSectionId] lastIntakeDoc", lastIntakeDoc)
        const section = lastIntakeDoc.sections.find((elm: Section) => elm.section_id === selectedSectionId);
        if (_.isNil(section)) {
            console.warn("useEffect [selectedSectionId] Section is null");
            return;
        }
        const questionItems = convertQuestionsDbSchemaToItems(section.questions);
        setQuestionItems(questionItems);
        localStorage.setItem(LOCAL_STORAGE_LAST_SECTION_ID, selectedSectionId);
    }, [selectedSectionId]);

    if (_.isNil(intakeItems)) {
        console.log("[Form Maker] intakeItems is null")
        return <SpinnerMedium />
    }

    if (_.isNil(selectedIntakeItem)) {
        console.log("[Form Maker] selectedIntakeItem is null")
        return <SpinnerMedium />
    }

    if (_.isNil(sectionItems)) {
        console.log("[Form Maker] sectionItems is null")
        return <SpinnerMedium />
    }

    if (_.isNil(questionItems)) {
        console.log("[Form Maker] questionItems is null")
        return <SpinnerMedium />
    }

    return (
        <div className="rounded-lg">
            <TopBar>
                <div className="flex justify-between py-3 ps-12 sm:ps-0 me-1"> {/* sm:hidden */}
                    <div className="flex flex-end me-2">
                        <Select items={intakeItems.filter((elm) => elm.clinic_id !== "COMMON")} selectedItem={intakeItems.find((elm: IntakeItem) => elm.intake_id === selectedIntakeItem?.intake_id)} onChange={onIntakeItemSelected}></Select>
                    </div>
                    <div className="flex flex-end justify-end">
                        <div>
                            <Dropdown items={WORK_DROPDOWN_ITEMS} onClick={onDropdownItemClicked}></Dropdown>
                        </div>
                    </div>
                </div>
            </TopBar >
            <div className="px-2 w-full bg-white shadow-xl rounded-lg sm:pt-[50px]">
                <div className='max-w-5xl mx-auto'>
                    <div className={classNames("fixed top-[74px] right-[7px] z-20")}>
                        <div className="flex">
                            <div className="me-2">
                                <IconButton onButtonClicked={onAddButtonClicked} disabled={_.isNil(selectedSectionId)}>
                                    <PlusIcon className="h-5 w-5 text-gray-500" />
                                </IconButton>
                            </div>
                            <div className="me-2">
                                <IconButton onButtonClicked={onRemoveButtonClicked} disabled={_.isNil(selectedQuestionId)}>
                                    {/* <TrashIcon className="h-5 w-5" /> */}
                                    <MinusIcon className="h-5 w-5 text-gray-500" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                    </div>
                    <div className="rounded-md bg-white px-2 pt-6 py-5 sm:px-8 shadow-sm ring-[0.5px] ring-gray-200">
                        <div className="flex me-2 mb-2">
                            <div className="me-2">
                                <Select items={sectionItems} selectedItem={sectionItems?.find((elm: SectionItem) => elm.section_id === selectedSectionId)} onChange={onSectionItemSelected} />
                            </div>
                            <div className="me-2">
                                <IconButton onButtonClicked={omEditButtonClicked} disabled={_.isNil(selectedIntakeItem)}>
                                    <PencilSquareIcon className="h-5 w-5 text-gray-500" />
                                </IconButton>
                            </div>
                        </div>
                        <div className="">
                            <VerticalDragList
                                itemsExternal={questionItems.map((elm, idx) => createItemWithContent(elm, idx))}
                                setItemsExternal={onOrderChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SectionManageModal>
                <SectionManager
                    sectionsExternal={sectionItems} // not use because rerendering in modal
                    onSectionSave={onModalSectionItemSaved}
                    onCancelButtonClicked={closeSectionManagerModal}
                />
            </SectionManageModal>
            <IntakeEditModal>
                <IntakeEditForm
                    intakeId={generateNewIntakeId()}
                    onCancel={closeIntakeEditModal}
                    onSave={onNewIntakeSaveRequested}
                />
            </IntakeEditModal>
        </div >
    )
}

export default IntakeEditor