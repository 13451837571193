import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { adminClient } from 'apis/admin';
import Button from 'components/Form/Button';
import SimpleTable, { RowInfo } from 'components/Table/Simple';
import { tr } from 'utils/translate';
import Card from 'components/Card';
import { columnInfos, userTableColumnInfo } from './config';
import { TranslateForm } from 'pages/Admin/Intake/Translate';
import { useModal } from 'components/Modal/Simple';
import { QuestionType } from 'containers/Questions/Question/types';
import { formatDate } from 'components/Table/Simple/presets';
import { toast } from 'react-toastify';
import { useSelectDialog } from 'components/Dialog/Select';
import { FAILURE_ITEMS } from 'pages/Admin/Customer/Status/TaskManager';
import Textarea from 'components/Form/Textarea';
import IconButton from 'components/Form/Button/Icon';

export const flattenQuestions = (questions, parentCondition = null, prefix = "") => {
    console.log("[flattenQuestions]", questions, parentCondition)
    let flatQuestions = [];

    questions.forEach((question, index) => {
        const questionIdx = prefix ? `${prefix}-${index + 1}` : `${index + 1}`;
        const flatQuestion = {
            question_id: question.question_id,
            text: question.text,
            type: question.type,
            options: question.options,
            condition: parentCondition,
            question_idx: questionIdx
        };

        flatQuestions.push(flatQuestion);

        if (question?.conditional_questions && question?.conditional_questions.length > 0) {
            const conditionalQuestions = flattenQuestions(question.conditional_questions, question.options.find(opt => opt.option_id === question.condition)?.option_id, questionIdx);
            flatQuestions = flatQuestions.concat(conditionalQuestions);
        }
    });

    return flatQuestions;
};

export const flattenSubmission = (submission) => {
    const flatAnswers = [];

    const processAnswers = (answers, sectionId) => {
        answers.forEach(answer => {
            const flatAnswer = {
                submission_id: submission.submission_id,
                intake_id: submission.intake_id,
                user_id: submission.user_id,
                section_id: sectionId,
                question_id: answer.question_id,
                answer: answer.answer,
                created_at: submission.created_at,
                updated_at: submission.updated_at
            };
            flatAnswers.push(flatAnswer);

            if (answer.conditional_answers && answer.conditional_answers.length > 0) {
                processAnswers(answer.conditional_answers, sectionId);
            }
        });
    };

    submission.answer_sections.forEach(section => {
        processAnswers(section.answers, section.section_id);
    });

    return flatAnswers;
};

export const parseSubmissionDetails = (flatQ, submissionDetailRet) => {
    const { user, intakeTranslations, submission, submissionTranslations } = submissionDetailRet;
    console.log("[fetchSubmission] flatQuestions", flatQ);
    const flatA = flattenSubmission(submission);
    console.log("[fetchSubmission] flatAnswers", flatA);
    const reverseIntakeTranslations = reverseTranslate(intakeTranslations);
    const trs = [...reverseIntakeTranslations, ...submissionTranslations];
    const tempTableData = flatA.flatMap(a => {
        let { question_id, answer } = a;
        const question = flatQ.find(q => q.question_id === question_id);
        if (_.isNil(question)) return;
        let answer_translated = tr(answer, trs, false);
        if (_.isArray(answer)) {
            answer_translated = answer.map(ans => tr(ans, trs)).join(", ");
        }
        if (question.type === QuestionType.DATE) {
            answer = formatDate(answer);
            answer_translated = answer;
        }
        if (question.type === QuestionType.TIME) {
            answer = answer;
            answer_translated = answer;
        }
        return {
            question_id,
            question_idx: question.question_idx,
            question: question.text,
            question_type: question.type,
            question_translated: tr(question.text, intakeTranslations),
            answer,
            answer_translated: answer_translated
        }
    }).filter(elm => !_.isNil(elm));
    return tempTableData;
}

export const reverseTranslate = (trs) => {
    const reversed = trs.map(tr => {
        return {
            origin: tr.translated,
            translated: tr.origin,
        }
    });
    return reversed;
}


const TranslateSubmissionEditor = () => {
    const { Modal: TranslateModal, openModal, closeModal } = useModal();
    const { submissionId } = useParams();
    const [currentRow, setCurrentRow] = useState(null);
    const [tableDataBySection, setTableDataBySection] = useState([]);
    const [comment, setComment] = useState(null);
    const { DialogProvider, openDialog } = useSelectDialog();
    const [userTableData, setUserTableData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (_.isNil(submissionId) || _.isEmpty(submissionId)) {
            navigate("/admin/customer/status");
        }
    }, [])

    console.log("[AdminCustomerTranslatePage] submissionId", submissionId);
    useEffect(() => {
        fetchSubmission();
    }, [])

    const fetchSubmission = async () => {
        try {
            const params = {
                lang: "jp"
            }
            const ret = await adminClient.get(`/submissions/${submissionId}/details`, { params });
            console.log("SUBMISSION", ret.data)
            const tempTableDataBySection = [];
            const { intake, submission, user } = ret.data;
            intake.sections.forEach((section) => {
                const flatQ = flattenQuestions(section.questions);
                const tempTableData = parseSubmissionDetails(flatQ, ret.data);
                tempTableDataBySection.push({
                    section_id: section.section_id,
                    section_title: section.name,
                    data: tempTableData
                });
            })
            console.log("[fetchSubmission] tempTableDataBySection", tempTableDataBySection)
            setTableDataBySection(tempTableDataBySection);
            setComment(submission.comment);
            console.log("[fetchSubmission] user", user);
            setUserTableData([_.pick(user,["user_id", "name", "phone_number", "birthdate", "nation", "gender", "created_at"])]);
        } catch (e) {
            console.error(e);
        }
    }

    const onComplete = async () => {
        await onCommentSave();
        const ret = await adminClient.patch(`/trackings/${submissionId}/awaiting_prescription`);
        console.log(ret);
        // reload
        // window.location.reload();
        toast.success("완료되었습니다.");
        navigate(-1)
        // onSubmit();
    }

    const onRowClicked = (rowIdx, row) => {
        if ([QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE].includes(QuestionType[row.question_type])) {
            toast.warn("선택형 질문입니다");
            return;
        }
        if (QuestionType[row.question_type] === QuestionType.DATE) {
            toast.warn("날짜형 질문입니다");
            return;
        }
        if (QuestionType[row.question_type] === QuestionType.TIME) {
            toast.warn("시간형 질문입니다");
            return;
        }
        setCurrentRow(row);
        openModal();
    }

    const onSave = () => {
        closeModal();
        fetchSubmission();
    }

    const onCommentSave = async () => {
        console.log("[onCommentSave] comment", comment);
        // PUT /submissions/{id}
        try {
            const body = {
                comment
            }
            const ret = await adminClient.put(`/submissions/${submissionId}`, body);
            console.log("[onCommentSave] ret", ret);
            toast.success("주석이 저장되었습니다.");
        } catch (e) {
            console.error(e);
            toast.error("주석 저장에 실패했습니다.");
        }
    }

    const onFailureButtonClicked = async () => {
        const ret = await openDialog("구매진행 중단 사유", FAILURE_ITEMS);
        if (_.isNil(ret)) {
            return;
        }
        const body = {
            purchase_failure_reason: ret.value,
        }
        try {
            const ok = window.confirm("구매진행 중단 사유를 저장하시겠습니까?");
            if (!ok) {
                return;
            }
            const ret = await adminClient.patch(`/trackings/${submissionId}/purchase_failure`, body);
            console.log("[onFailureButtonClicked] ret", ret);
            toast.success("구매진행 중단 사유가 저장되었습니다.");
            navigate("/admin/task/translate-submission")
        } catch (e) {
            console.error(e);
            toast.error("구매진행 중단 사유 저장에 실패했습니다.");
        }

    }

    const rowInfo: RowInfo = {
        rowStyle: (row) => {
            if ([QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE, QuestionType.DATE, QuestionType.TIME].includes(QuestionType[row.question_type])) {
                return {
                    backgroundColor: "rgb(249,250,251)", // gray-50
                    cursor: "not-allowed"
                }
            }
        },
    }

    return (
        <Card>
            <div className='p-3'>
                <div className="mb-2">
                    <h1 className="text-lg font-semibold">응답지 번역</h1>
                </div>
                <div>
                    <h2 className="text-base font-semibold mb-1">고객정보</h2>
                    <div className="px-2">
                        <SimpleTable data={userTableData} columns={userTableColumnInfo}></SimpleTable>
                    </div>
                </div>
                {tableDataBySection.map((section) => (
                    <div key={section.section_id}>
                        <h2 className="text-base font-semibold mb-1">{section.section_title}</h2>
                        <Card paddingClassNames='px-0'>
                            <div className="p-2">
                                <SimpleTable
                                    data={section.data}
                                    columns={columnInfos}
                                    onRowClicked={onRowClicked}
                                    rowInfo={rowInfo}
                                ></SimpleTable>

                            </div>
                        </Card>
                    </div>
                ))}
                <div className="flex mt-2">
                    <div className="w-full">
                        <h1 className="text-base font-semibold mb-1">주석</h1>
                        <div className="relative">
                            <Textarea value={comment} onChange={setComment} />
                            <div className="absolute top-[4px] right-[7px]">
                                <IconButton paddingClassName='px-2 py-1' onButtonClicked={onCommentSave}>
                                    주석 저장
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between space-x-2 mt-2">
                    <div>
                        <Button onButtonClicked={() => { navigate(-1) }}>뒤로가기</Button>
                    </div>
                    <div className="flex space-x-2">
                        <div>
                            <Button onButtonClicked={onFailureButtonClicked}>구매진행 중단</Button>
                            <DialogProvider />
                        </div>
                        <div>
                            <Button onButtonClicked={onComplete}>완료</Button>
                        </div>
                    </div>
                </div>
            </div >
            <TranslateModal>
                <TranslateForm
                    data={{
                        origin: currentRow?.answer,
                        translated: currentRow?.answer_translated
                    }}
                    submissionId={submissionId}
                    origin_lang="jp"
                    postUrl={`/translations/submissions`}
                    onSave={onSave}
                ></TranslateForm>
            </TranslateModal>
        </Card >
    )
}

export default TranslateSubmissionEditor;
