import axios from "axios";
import { Cookies } from "react-cookie";
import _ from "lodash";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { tryAssignDevAuthCookie } from "./common";

const cookies = new Cookies();

export const adminClient = axios.create({
    baseURL: process.env.REACT_APP_REST_SERVER_URL,
    withCredentials: true,
});

export const logoutAndMoveToLoginPage = () => {
    const originPath = window.location.pathname;
    localStorage.setItem("logoutFrom", originPath);
    cookies.remove("Authorization");
    localStorage.removeItem("refreshToken");
    window.location.replace("/account/login");
}

const handle401Error = async (error) => {
    console.log("401 error", error);
    error.config._retryCount = error.config._retryCount || 0;
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshRet = await AdminClient.tryRequestRefreshToken(refreshToken); // if replaced
    console.log("[Handle 401 Error] tryRequestRefreshToken ret", refreshRet)
    if (refreshRet?.isReplaced || refreshRet) {
        console.log("[adminClient] RETRY COUNT", error.config._retryCount);
        if (error.config._retryCount < 5) {
            error.config._retryCount += 1;
            return adminClient(error.config);
        }
        // toast.error("토큰 갱신 시도 횟수 초과");
    }
    // toast.error("토큰 갱신에 실패했습니다.")
    // alert("인증정보가 유효하지 않습니다. 다시 로그인해주세요.");
    logoutAndMoveToLoginPage();
    // toast("로그아웃되었습니다.")
    return Promise.reject(error);
}

const handle403Error = async (error) => {
    console.log("403 error");
    logoutAndMoveToLoginPage();
    return Promise.reject(error);
}

export const responseErrorInterceptor = async (error) => {
    console.log("[adminClient] response error", error);
    if (error.response.status === 401) {
        return handle401Error(error);
    } else if (error.response.status === 403) {
        return handle403Error(error);
    }
    return Promise.reject(error);
}

export const tokenRefreshInterceptor = async (config) => {
    // console.log("[tokenRefreshInterceptor] cookies", cookies)
    try {
        if (config.url.includes("/refresh-token")) {
            return config;
        }
        const token = await cookies.get("Authorization");
        const decodedToken = jwtDecode(token);
        // console.log("[tokenRefreshInterceptor] decodedToken", decodedToken);
        const { iat, exp } = decodedToken;
        const now = new Date();
        // console.log("[tokenRefreshInterceptor] auth iat", new Date(iat * 1000));
        // console.log("[tokenRefreshInterceptor] auth now", now);
        // console.log("[tokenRefreshInterceptor] auth exp", new Date(exp * 1000));

        const refreshToken = localStorage.getItem("refreshToken");
        if(_.isNil(refreshToken)) {
            console.warn("[tokenRefreshInterceptor] refreshToken is null");
            return config;
        }
        const decodedRefreshToken = jwtDecode(refreshToken);
        const { iat: refreshIat, exp: expIat } = decodedRefreshToken;
        // console.log("[tokenRefreshInterceptor] refresh iat", new Date(refreshIat * 1000));
        // console.log("[tokenRefreshInterceptor] refresh now", now);
        // console.log("[tokenRefreshInterceptor] refresh exp", new Date(expIat * 1000));
    } catch (e) {
        console.error("[tokenRefreshInterceptor] error", e);
    }

    return config;
}

adminClient.interceptors.request.use(
    tokenRefreshInterceptor
    , error => {
        console.log("[userClient] request error", error);
        return Promise.reject(error);
    }
);

adminClient.interceptors.response.use(
    response => {
        return response;
    },
    responseErrorInterceptor
);


export namespace AdminClient {

    export const tryRequestRefreshToken = async (refreshToken) => {
        if (_.isNil(refreshToken)) {
            // toast.error("Refresh 토큰이 없습니다.");
            alert("인증정보가 없습니다.");
            logoutAndMoveToLoginPage();
            return null;
        }
        const decodedRefreshToken = jwtDecode(refreshToken);
        console.log("[tryRequestRefreshToken] decodedRefreshToken", decodedRefreshToken);
        const { iat, exp } = decodedRefreshToken;
        const now = new Date();

        const authToken = cookies.get("Authorization");
        if (_.isNil(authToken)) {
            toast.error("Auth 토큰이 없습니다.");
            return null;
        }

        const decodedAuthToken = jwtDecode(authToken);
        const { exp: authTokenExp } = decodedAuthToken;

        const body = {
            refreshToken
        }
        try {
            const ret = await adminClient.post("/refresh-token", body);
            console.log("[requestRefreshToken] ret", ret)
            if (ret.status === 204) {
                console.log("[requestRefreshToken] 204. refresh token is replaced");
                // toast.info("토큰이 교체되어있습니다.");
                return { isReplaced: true };
            }
            const { token, refreshToken } = ret.data;
            tryAssignDevAuthCookie(token);
            localStorage.setItem("refreshToken", refreshToken);
            // toast.info("토큰 갱신 성공");
            return ret.data;
        } catch (e) {
            // toast.error("토큰 갱신 실패");
            console.error(e);
            return null;
        }
    }

    export const getClinicInfos = async (params = { page: 1, limit: 100000 }) => {
        try {
            const ret = await adminClient.get(`/clinics`, { params });
            const tempClinicInfos = ret.data.data;
            return tempClinicInfos;
        } catch (e) {
            console.log("[Admin Apis] fetchClinicInfos Error", e)
        }
    }

    export const downloadXlsx = async (url, params, fileName = "download.xlsx") => {
        const ret = await adminClient.get(url, { params, responseType: 'blob' });
        const blob = new Blob([ret.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName; // 다운로드할 파일 이름 설정
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
    }
}
