import React from 'react';
import DatePicker from 'react-datepicker';
import { classNames } from 'components/uiUtils';
import _ from 'lodash';
import { CustomDatePickerInput } from '..';

const DEFAULT_DT = new Date();
DEFAULT_DT.setHours(0, 0, 0, 0);

export const SimpleTimePicker = ({ label = "",placeholderText="Click to select a time", dts = [], selectedDt = null, onDtChanged = (dt: any) => { }, onClick = () => { }, ...otherProps },) => (
    <div className="flex rounded-md shadow-sm">
        {label &&
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm text-nowrap">
                {label}
            </span>
        }
        <div className={"w-full"} onClick={onClick}>
            <DatePicker
                dateFormat={"h:mm aa"}
                timeIntervals={10}
                selected={selectedDt}
                onChange={(date: Date) => { onDtChanged(date) }}
                showPopperArrow={false}
                className={classNames("w-full", "rounded-r-md", label || "rounded-l-md")}
                customInput={<CustomDatePickerInput />}
                showTimeSelect
                showTimeSelectOnly
                dropdownMode="select"
                placeholderText={placeholderText}
                {...otherProps}
            />
        </div>
    </div>
)