import React, { useState } from 'react';
import { tr } from 'utils/translate';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { CommonClient, commonClient } from 'apis/common';
import SignUpContainer from 'containers/Member/SignUp';
import _ from 'lodash';
import { UserType } from 'pages/Admin/User/types';

const LogInContainer = ({ onSubmit = (id, password) => { } }) => {
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit(id, password);
        }
    };

    const handleClick = () => {
        onSubmit(id, password);
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <div className="mt-3">
                <Input label={tr("아이디")} value={id} onChange={(v) => { setId(v?.toLowerCase()) }}></Input>
            </div>
            <div className="mt-3">
                <Input label={tr("비밀번호")} value={password} onChange={setPassword} type="password"></Input>
            </div>
            <div className="mt-3">
                <Button onButtonClicked={handleClick}>{tr("로그인")}</Button>
            </div>
        </div>
    )
}

export default LogInContainer;
