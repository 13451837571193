import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime } from "components/Table/Simple/presets";

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "ID",
        visible: false,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "change",
        label: "변동",
        visible: true,
    },
    {
        field: "reason",
        label: "변동이유",
        visible: true,
    },
    {
        field: "updated_at",
        label: "변동일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "created_at",
        label: "생성일",
        visible: false,
    }
]