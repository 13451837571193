import React from 'react'

const BasicArticle = ({children}) => {
    return (
        <article className={`prose prose-sm prose-p:my-1 prose-ul:my-0 min-w-full whitespace-normal whitespace-pre-wrap`}>
            {children}
        </article>
    )
}

export default BasicArticle;
