import React, { useEffect, useState } from "react"
import _ from "lodash";
import QuillMadeArticle from "components/Article/QuillMade";
import { assembleQuillContentsWithFields } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/helper";
import Quill from "quill";
import Card from "components/Card";
import Button from "components/Form/Button";
import { toast } from "react-toastify";
import { adminClient } from "apis/admin";

const PrescriptionSendForm = ({ submissionId, onPatched }) => {
    const [prescriptionHtml, setPrescriptionHtml] = useState('');
    const [quill] = useState(new Quill(document.createElement('div')));

    useEffect(() => {
        if (_.isNil(submissionId)) {
            return;
        }
        fetchPrescription();
    }, [submissionId])


    const fetchPrescription = async () => {
        console.log("[PrescriptionSendForm] fetchPrescription", submissionId);
        try {
            const ret = await adminClient.get(`/prescriptions/${submissionId}/details`);
            console.log("[PrescriptionSendForm] fetchPrescription ret", ret.data);
            const { fields, contents, translated_contents } = ret.data;
            const assembledContents = assembleQuillContentsWithFields(translated_contents, fields, true);
            quill.setContents(assembledContents);
            const html = quill.getSemanticHTML();
            setPrescriptionHtml(html);
        } catch (e) {
            console.error(e);
        }
    }

    const onSend = async () => {
        const ok = window.confirm("처방전을 발송하고 결제대기 상태로 변경하시겠습니까?");
        if (!ok) {
            return;
        }
        try {
            const ret = await adminClient.patch(`/trackings/${submissionId}/awaiting_customer_payment`);
            toast.success("처방전 발송완료 및 결제대기 상태로 변경되었습니다.");
            onPatched();
        } catch (e) {
            console.error(e);
            toast.error("처방전 발송에 실패했습니다.");
        }
    }

    return (
        <div>
            <h1 className="font-semibold">처방전</h1>
            <div className="min-w-80">
                <Card>
                    <div className="p-2">
                        <QuillMadeArticle htmlText={prescriptionHtml} />
                    </div>
                </Card>
            </div>
            <div className="flex justify-end space-x-2 mt-2">
                <div>
                    <Button
                        onButtonClicked={() => {
                            toast.success("처방전이 클립보드에 복사되었습니다.");
                            const txt = quill.getText();
                            navigator.clipboard.writeText(txt);
                        }}
                    >
                        처방전 복사
                    </Button>
                </div>
                <div>
                    <Button onButtonClicked={onSend}>처방전 발송완료 및 결제대기</Button>
                </div>
            </div>
        </div>
    )
}

export default PrescriptionSendForm;

