import { Routes, Route } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";
import MemberRoute from "containers/SiteRoute/Member";

const MemberSiteRoute = () => {
    return (
        <Routes>
            <Route path="/*" element={<MainRoute />}></Route>
            <Route path="/member/*" element={<MemberRoute />}></Route>
        </Routes>
    )
}

export default MemberSiteRoute;