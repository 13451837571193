
import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminClinicListPage from "pages/Admin/Clinic/List";
import AdminClinicProductPage from "pages/Admin/Clinic/Product";
import AdminClinicPrescriptionTemplatePage from "pages/Admin/Clinic/PrescriptionTemplate";
import AdminClinicPrescriptionTemplateEditorPage from "pages/Admin/Clinic/PrescriptionTemplate/Editor";
import AdminClinicAftercareTypePage from "pages/Admin/Clinic/AftercareType";

const AdminUserRoute = () => (
    <Routes>
        <Route path="/list" element={<AdminClinicListPage />}></Route>
        <Route path="/product" element={<AdminClinicProductPage />}></Route>
        <Route path="/prescription-template" element={<AdminClinicPrescriptionTemplatePage />}></Route>
        <Route path="/prescription-template/editor" element={<AdminClinicPrescriptionTemplateEditorPage />}>
            <Route path=":prescription_template_id" element={<AdminClinicPrescriptionTemplateEditorPage />}></Route>
        </Route>
        <Route path="/aftercare-type" element={<AdminClinicAftercareTypePage />}></Route>
    </Routes>
)

export default AdminUserRoute;