import { Routes, Route } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";
import DoctorRoute from "containers/SiteRoute/Doctor";
import AccountRoute from "containers/SiteRoute/Account";

const MemberSiteRoute = () => {
    return (
        <Routes>
            <Route path="/*" element={<MainRoute />}></Route>
            <Route path="/doctor/*" element={<DoctorRoute />}></Route>
            <Route path="/account/*" element={<AccountRoute />}></Route>
        </Routes>
    )
}

export default MemberSiteRoute;