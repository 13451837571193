import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";

// CURRENCY
export const CURRENCY_ITEMS = [
    { value: "JPY", name: "엔" },
    { value: "KRW", name: "원" },
    { value: "USD", name: "달러" },
    // { value: "ETC", name: "기타" },
]

const PRODUCT_TYPE_ITEMS = [
    { name: "월", value: "MONTH" },
    { name: "일", value: "DAY" },
    { name: "기타", value: "ETC" }
];

export const columnInfos: Column[] = [
    {
        field: "clinic_id",
        label: "의원ID",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "name",
        label: "제품명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "product_id",
        label: "제품ID",
        visible: false,
    },
    {
        field: "name",
        label: "제품명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "name_jp",
        label: "일본어 제품명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "quantity",
        label: "수량",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "price",
        label: "가격",
        visible: true,
        creatable: true,
        editable: true,
    },

    {
        field: "currency",
        label: "통화",
        visible: true,
        creatable: true,
        editable: true,
        selectable: {
            getOptions: () => {
                return CURRENCY_ITEMS;
            }
        },
        render: (value, field, row) => {
            const item = CURRENCY_ITEMS.find((item) => item.value === value);
            return item ? item.name : value;
        }
    },
    {
        field: "created_at",
        label: "생성일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: true,
        render: formatDate
    },
    {
        field: "global_name",
        label: "글로벌 이름",
        visible: false
    },
    {
        field: "order",
        label: "순서",
        visible: false
    }
];
// export const columnInfos: Column[] = [
//     {
//         field: "clinic_id",
//         label: "의원ID",
//         visible: true,
//     },
//     {
//         field: "clinic_name",
//         label: "의원명",
//         visible: true,
//     },
//     {
//         field: "product_id",
//         label: "제품ID",
//         visible: true,
//     },
//     {
//         field: "product_name",
//         label: "제품명",
//         visible: true,
//     },
//     {
//         field: "price",
//         label: "가격",
//         visible: true,
//     },
//     {
//         field: "stage",
//         label: "단계",
//         visible: true,
//     },
//     {
//         field: "created_at",
//         label: "생성일",
//         visible: true,
//         render: formatDate
//     },
//     {
//         field: "updated_at",
//         label: "수정일",
//         visible: true,
//         render: formatDate
//     }
// ];
