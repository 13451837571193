import React, { useEffect, useMemo, useState } from "react";
import { adminClient } from "apis/admin";
import Card from "components/Card";
import PagingTable from "components/Table/Paging";
import { useModal } from "components/Modal/Simple";
import _ from "lodash";
import { columnInfos } from "./config";
import { toast } from "react-toastify";
import { Column } from "components/Table/Simple";
import './styles.scss';
import ColorPickForm from "./ColorPickForm";
import PageTitle from "components/Title/Page";
import { confirmDelete } from "utils/browser";

const ClinicListPage = () => {
    const [randomKey, setRandomKey] = useState(0);
    const { Modal: ColorPickerModal, openModal: openColorPickerModal, closeModal: closeColorPickerModal } = useModal();
    const [selectedRow, setSelectedRow] = useState<any>({});

    const fetchClinicInfos = async (page, limit) => {
        try {
            const params = {
                page,
                limit
            }
            const ret = await adminClient.get(`/clinics`, { params });
            console.log(ret);
            const tempTableData = ret.data.data;
            return {
                data: tempTableData,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error("[ClinicListPage] fetchClinicInfos", e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const onCreate = async (data) => {
        const ok = window.confirm(`${data.name}를 생성하시겠습니까?`);
        if (!ok) return false;
        try {
            await adminClient.post(`/clinics`, data);
            toast.success("의원이 생성되었습니다.");
            setRandomKey(Math.random());
            return true;
        } catch (e) {
            console.error("[MemberUserPage] onCreate", e)
            if (e?.response?.data?.error?.includes("E11000 duplicate key error collection")) {
                toast.error("이미 존재하는 의원입니다.");
                return false;
            }
            toast.error("의원 생성에 실패했습니다.");
            return false;
        }
    }

    const onEdit = async (data) => {
        const ok = window.confirm(`${data.name}를 수정하시겠습니까?`);
        const newData = _.omit(data, ["created_at", "updated_at"]);
        if (!ok) return false;
        try {
            await adminClient.put(`/clinics/${data.clinic_id}`, newData);
            toast.success("의원이 수정되었습니다.");
            setRandomKey(Math.random());
            return true;
        } catch (e) {
            toast.error("의원 수정에 실패했습니다.");
            return false;
        }
    }

    const onDelete = async (data) => {
        const ok = confirmDelete(`${data.name} 삭제를 진행합니다.`);
        if (!ok) return false;
        try {
            await adminClient.delete(`/clinics/${data.clinic_id}`);
            toast.success("의원이 삭제되었습니다.");
            setRandomKey(Math.random());
            return true;
        } catch (e) {
            toast.error("의원 삭제에 실패했습니다.");
            return false;
        }
    }

    const onReorder = async (datas, row, isUp) => {
        console.log("[On Reorder]", row, datas, isUp);
        const ok = window.confirm("순서를 변경하시겠습니까?");
        if (!ok) {
            return;
        }
        try {
            const clinicId = row.clinic_id;
            const directionPath = isUp ? "move-up" : "move-down";
            const ret = await adminClient.put(`/clinics/${clinicId}/${directionPath}`);
            console.log("[On Reorder] Ret", ret);
            toast.success("의원 순서가 변경되었습니다.");
            setRandomKey(Math.random());
            return true;
        } catch (e) {
            toast.error("의원 순서 변경에 실패했습니다.");
            return false;
        }
    }

    const onColorSave = async (newColor) => {
        console.log("[On Color Save]", selectedRow, newColor);
        try {
            const ret = await onEdit({ ...selectedRow, clinic_color: newColor });
            closeColorPickerModal();
            return true;
        } catch (e) {
            console.error("[On Color Save]", e);
            return false;
        }
    }

    const columnInfosWithColorPicker: Column[] = [
        {
            field: "clinic_color",
            label: "색상설정",
            render: (value, field, row) => (
                <button
                    className="rounded-md ring-1 ring-gray-200 shadow-md size-4 rounded-md p-0 align-middle"
                    style={{
                        backgroundColor: value ?? "",
                    }}
                    onClick={(e) => {
                        setSelectedRow(row);
                        openColorPickerModal();
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                ></button>
            ),
            align: "center",
        },
        ...columnInfos
    ]

    return (
        <>
            <Card>
                <div className="p-3" key={randomKey}>
                    <PageTitle>의원정보 관리</PageTitle>
                    <PagingTable
                        fetchData={fetchClinicInfos}
                        columns={columnInfosWithColorPicker}
                        windowSize={100}
                        isCrudable={true}
                        onCreate={onCreate}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onReorder={onReorder}
                        isReordable={true}
                        disableSort={true}
                    />
                </div>
            </Card>
            <ColorPickerModal>
                <ColorPickForm
                    currentColor={selectedRow.clinic_color}
                    onCancel={closeColorPickerModal}
                    onSave={onColorSave}
                />
            </ColorPickerModal>
        </>
    )
}

export default ClinicListPage;