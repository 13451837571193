import React, { useState } from "react";
import { useModal } from "components/Modal/Simple";
import Button from "components/Form/Button";
import Input, { InputFormat } from "components/Form/Input";
import { classNames } from "components/uiUtils";

export const SimpleInputForm = ({ externalData = null, closeModal = () => { }, resolveRef = null, format = null }) => {
    const [value, setValue] = useState(externalData?.value);
    const [isProcessing, setIsProcessing] = useState(false);

    const onSubmit = () => {
        setIsProcessing(true);
        try {
            resolveRef.current(value);
        } catch (e) {
            console.error("[SimpleInputForm] onSubmit", e);
        } finally {
            setIsProcessing(false);
        }
        closeModal();
    }

    return (
        <div className={
            classNames(
                "flex flex-col space-y-2",
                externalData?.className
            )}>
            <h1 className="text-lg">{externalData?.message}</h1>
            <Input value={value} onChange={setValue} format={format}></Input>
            <div className="flex justify-end">
                <div>
                    <Button onButtonClicked={onSubmit} disabled={isProcessing}>입력</Button>
                </div>
            </div>
        </div>
    )
}

export const useInputDialog = ({ format } = {} as { format: InputFormat }) => {
    const { Modal, openModalSync } = useModal();

    const openDialog = async (message, value, className?): Promise<string> => {
        return await openModalSync({ message, value, className });
    }

    return {
        DialogProvider: () =>
            <Modal>
                <SimpleInputForm format={format} />
            </Modal>,
        openDialog
    }

}