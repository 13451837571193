
import React from "react";
import { Routes, Route } from "react-router-dom";

// import AdminCustomerSubmissionPage from "pages/Admin/Customer/Submission";
import AdminCustomerStatusPage from "pages/Admin/Customer/Status";
import AdminCustomerUnpaidPage from "pages/Admin/Customer/Unpaid";
import AdminCustomerAftercarePage from "pages/Admin/Customer/Aftercare";
import AdminCustomerFeedbackPage from "pages/Admin/Customer/Feedback";
import AdminCustomerPurchaseInfoPage from "pages/Admin/Customer/PurchaseInfo";
import AdminCustomerPointHistoryPage from "pages/Admin/Customer/PointHistory";
import AdminCostomerPurchaseFailurePage from "pages/Admin/Customer/PurchaseFailure";
import AdminCustomerPurchaseStatisticPage from "pages/Admin/Customer/PurchaseStatistic";

const AdminCustomerRoute = () => (
    <Routes>
        <Route path="/status" element={<AdminCustomerStatusPage />}></Route>
        <Route path="/unpaid" element={<AdminCustomerUnpaidPage />}></Route>
        <Route path="/aftercare" element={<AdminCustomerAftercarePage />}></Route>
        <Route path="/purchase-info" element={<AdminCustomerPurchaseInfoPage />}></Route>
        <Route path="/point-history" element={<AdminCustomerPointHistoryPage />}></Route>
        <Route path="/feedback" element={<AdminCustomerFeedbackPage />}></Route>
        <Route path="/purchase-failure" element={<AdminCostomerPurchaseFailurePage />}></Route>
        <Route path="/purchase-statistic" element={<AdminCustomerPurchaseStatisticPage />}></Route>
    </Routes>
)

export default AdminCustomerRoute;