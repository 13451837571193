import React from "react";
import GeneralTaskList from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import Card from "components/Card";
import PageTitle from "components/Title/Page";

const AwaitingPrescription = () => {
    const states: (keyof typeof CustomerState)[] = ["AWAITING_PRESCRIPTION"]

    const columnInfosWithTask = [
        ...columnInfos.map((info) => {
            if ([
                "clinic_user_id",
                "awaiting_at",
                "purchase_at",
                "shipping_address",
                "deliver_request_at",
                "shipping_company",
                "tracking_number",
                "tracking_shipping_link",
                "deliver_at",
                "delivered_at",
                "nudge"
            ].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            return info;
        })
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>처방전 작성 대기</PageTitle>
                <GeneralTaskList states={states} columnInfos={columnInfosWithTask}></GeneralTaskList>
            </div>
        </Card>

    )
}

export default AwaitingPrescription;