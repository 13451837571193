import React from "react";
import GeneralTaskList from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import { useNavigate } from "react-router-dom";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import IconButton from "components/Form/Button/Icon";

const TranslatePrescription = () => {
    const states: (keyof typeof CustomerState)[] = ["AWAITING_PRESCRIPTION_TRANSLATION"]
    const navigate = useNavigate();

    const columnInfosWithTask = [
        {
            field: "task",
            label: "작업",
            visible: true,
            render: (value, field, row) =>
                <IconButton
                    paddingClassName="px-2 py-1"
                    onButtonClicked={() => {
                        navigate(`/admin/task/translate-prescription/editor/${row.submission_id}`)
                    }}
                >
                    <div className="text-xs">
                        처방전 번역
                    </div>
                </IconButton>,
            align: "center"
        },
        ...columnInfos.map((info) => {
            if ([
                "awaiting_at",
                "purchase_at",
                "shipping_address",
                "deliver_request_at",
                "shipping_company",
                "tracking_number",
                "tracking_shipping_link",
                "deliver_at",
                "delivered_at",
                "nudge"
            ].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            return info;
        })
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>처방전 번역</PageTitle>
                <GeneralTaskList states={states} columnInfos={columnInfosWithTask}></GeneralTaskList>
            </div>
        </Card>

    )
}

export default TranslatePrescription;