import { Option } from "pages/Admin/Intake/Editor/ChoiceMaker";

export enum QuestionType {
    SINGLE_CHOICE = "SINGLE_CHOICE",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    SHORT_ANSWER = "SHORT_ANSWER",
    LONG_ANSWER = "LONG_ANSWER",
    DATE = "DATE",
    TIME = "TIME"
}

export enum AnswerFormat {
    NONE = "NONE",
    NUMBER = "NUMBER",
    ID = "ID",
    PHONE = "PHONE",
    EMAIL = "EMAIL",
}

export type Question = {
    question_id: string;
    text: string;
    type: QuestionType;
    options?: Option[];
    format?: string;
    placeholder?: string;
    condition?: string;
    conditional_questions?: Question[];
}

export const defaultValueOf = (questionType: QuestionType): string | string[] | Date => {
    switch (questionType) {
        case QuestionType.MULTIPLE_CHOICE:
            return [];
        case QuestionType.DATE:
            return undefined;
        default:
            return "";
    }
}