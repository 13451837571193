import { useState, useEffect, useMemo } from 'react';
import _, { set } from 'lodash';

const useTable = ({ data, columns }) => {
    // const temp = data;
    const [tableData, setTableData] = useState(data);
    const [sortOrder, setSortOrder] = useState(0)

    useEffect(() => {
        setTableData(data);
    }, [data])

    const getData = () => {
        return tableData
    };

    const getFields = () => _.uniq(_.flatten(tableData
        .map((row: string) =>
            Object.keys(row).filter(((fieldName: string) => columns.find((column: any) => column.field === fieldName)?.hidden !== true))
        )
    ))

    const getSortState = () => {
        return sortOrder;
    }

    // console.log("[useTable] current sort order", sortOrder)

    const sortData = (field) => {
        let sortedData = data;
        const newSortOrder = (sortOrder === 1) ? 0 : (sortOrder === -1) ? 1 : -1;
        if (newSortOrder !== 0) {
            sortedData = [...data].sort((elmA, elmB) => {
                if (typeof Number(elmA[field]) === 'number' && !_.isNaN(Number(elmA[field]))) {
                    // console.log("@@@@@ NUMBER SORT", Number(elmA[field]))
                    return Number(elmA[field]) < Number(elmB[field]) ? -newSortOrder : newSortOrder
                } else if (typeof elmA[field] === 'string') {
                    // console.log("@@@@@ STRING SORT", Number(elmA[field]))
                    return elmA[field].localeCompare(elmB[field]) * newSortOrder
                } else {
                    return 0;
                }
            });
        }
        // console.log(sortedData);
        const tempTableData = [...sortedData];
        setTableData(tempTableData);
        setSortOrder(newSortOrder)
    };

    return { getData, getFields, sortData, getSortState, setTableData };
}

export { useTable };