import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { SHIPPING_COMPANY, SHIPPING_COMPANY_ITEMS } from "pages/Admin/Customer/Status/TaskManager";
import { TruckIcon } from "@heroicons/react/24/outline";

export const formatShippingLink = (value, field, row) => {
    const trackingNumber = row.tracking_number;
    const shippingCompany = row.shipping_company;
    // console.log("[tracking_shipping_link] trackingNumber", trackingNumber);
    if (_.isNil(trackingNumber)) return "";
    let link = null

    if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.KOREA_POST) {
        link = "https://trackings.post.japanpost.jp/services/srv/search/input";
    }
    else if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.CU) {
        link = "https://www.cupost.co.kr/postbox/delivery/all.cupost";
    }
    else if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.HANJIN) {
        link = "https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillSch.do?mCode=MN038";
    }
    else if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.SAGAWA) {
        link = "https://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp?_ga=2.41919330.1784910259.1711958981-1799900706.1711958981";
    }
    else if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.YAMATO) {
        link = "https://toi.kuronekoyamato.co.jp/cgi-bin/tneko";
    }
    else if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.ECOHAI) {
        link = "https://www.ecohai.co.jp/cargo_tracking";
    }
    else if (SHIPPING_COMPANY[shippingCompany] === SHIPPING_COMPANY.CJ) {
        link = "https://www.cjlogistics.com/ko/tool/parcel/tracking";
    }
    else {
        return "";
    }
    return link ? (
        <a
            className="cursor-pointer" href={link} target="_blank"
            onClick={() => {
                navigator.clipboard.writeText(trackingNumber.replace(/-/g, ""));
                toast.success("송장번호가 복사되었습니다.");
                window.open(link, 'newWindow', 'width=1280, height=720, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no') //HD 720p
            }}
        >
            {<TruckIcon className="size-4"></TruckIcon>}
        </a>
    ) : "";
}

export const formatShippingCompanyName = (value, field, row) => {
    const found = SHIPPING_COMPANY_ITEMS.find((item) => item.value === value);
    return found?.name ?? value;
}
