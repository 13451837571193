import React, { useEffect, useState } from 'react';
import { classNames } from "components/uiUtils";

type StackedCardItem = {
    id: string;
    name: string;
    data?: any
}
const EXAMPLE_ITEMS: StackedCardItem[] = [
    { id: "1", name: 'Woman' },
    { id: "2", name: 'Man' },
];

export default function SelectableList({ items = EXAMPLE_ITEMS, multiSelect = false, onItemChanged = (name: string[] | string) => { }, externalNames = [] }) {
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        const initialSelectedItems = items.filter((item: StackedCardItem) => externalNames.includes(item.name));
        if (!multiSelect && initialSelectedItems.length > 1) {
            console.warn("Multiple selected items detected in single select mode. Only the first item will be selected.");
            window.alert("Multiple selected items detected in single select mode. Only the first item will be selected.");
            initialSelectedItems.splice(1);
        }
        setSelectedItems(initialSelectedItems);
    }, [items])

    const toggleItem = (item: StackedCardItem) => {
        if (multiSelect) {
            setSelectedItems((currentItems) =>
                currentItems.includes(item)
                    ? currentItems.filter(i => i !== item)
                    : [...currentItems, item]
            );
        } else {
            setSelectedItems([item]);
        }
    };

    const isSelected = (item: StackedCardItem) => {
        return selectedItems.includes(item);
    };

    useEffect(() => {
        const selectedNames = selectedItems.map((item) => item.name);
        multiSelect ? onItemChanged(selectedNames) : onItemChanged(selectedNames[0] ?? "");
    }, [selectedItems])

    return (
        <div className="space-y-2">
            {items.map((item) => (
                <div
                    key={item.name}
                    className={classNames(
                        'relative block cursor-pointer rounded-lg border px-6 py-3 shadow-sm focus:outline-none sm:flex sm:justify-between',
                        isSelected(item) ? 'border-indigo-600 bg-indigo-50' : 'border-gray-300 bg-white'
                    )}
                    onClick={() => toggleItem(item)}
                >
                    <span className="flex items-center text-sm">
                        {multiSelect ? (
                            <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                checked={isSelected(item)}
                            />
                        ) : (
                            <div className="w-fit">
                                <span
                                    className={classNames(
                                        isSelected(item) ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                        'h-4 w-4 rounded-full border flex items-center justify-center'
                                    )}
                                    aria-hidden="true"
                                >
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                            </div>
                        )}
                        <div>
                            <span className="ms-2 font-medium text-gray-900">{item.name}</span>
                        </div>
                    </span>
                    <span
                        className={classNames(
                            'pointer-events-none absolute -inset-px rounded-lg',
                            isSelected(item) ? 'border-2 border-indigo-600' : 'border-2 border-transparent',
                        )}
                        aria-hidden="true"
                    />

                </div>
            ))}
        </div>
    );
}