import React, { useState } from 'react';
import { tr } from 'utils/translate';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { CommonClient, commonClient } from 'apis/common';
import SignUpContainer from 'containers/Member/SignUp';
import _ from 'lodash';
import { UserType } from 'pages/Admin/User/types';
import LogInContainer from 'containers/Member/LogIn';

const AccountLoginForm = () => {
    const navigate = useNavigate();
    const [isSignUp, setIsSignUp] = useState(false);
    const location = useLocation();

    const login = async (id, password) => {
        try {
            const ret = await CommonClient.loginViaAccount(id, password);
            if (_.isNil(ret)) {
                toast.error("로그인에 실패했습니다");
                return;
            }
            if (ret.password_reset_required) {
                navigate("/account/reset-password");
                return;
            }
            // if my url is localhost
            toast.success("로그인이 완료되었습니다");
            console.log("lastPath", localStorage.getItem("logoutFrom"));
            const lastPath = localStorage.getItem("logoutFrom");
            if (lastPath) {
                navigate(lastPath);
                localStorage.removeItem("logoutFrom");
                return;
            }
            navigate("/");
        }
        catch (e) {
            toast.error("로그인에 실패했습니다");
        }
    }

    const onLoginRequested = async (id, password) => {
        login(id, password);
    }

    const onSignupRequested = async (authInfo) => {
        const user = {
            user_id: authInfo.id,
            password: authInfo.password,
            user_type: UserType.MEMBER,
            name: "",
            phone_number: "",
            birthdate: "",
            gender: "",
            nation: "",
            address: "",
            source: "SIGNUP",
            point: 0,
            note: "",
            password_reset_required: false,
        }
        try {
            const signupRet = await commonClient.post(`/signup`, user);
            console.log("[Intake Page] signUp Ret", signupRet);
            const { token, refreshToken } = signupRet.data;
            // login
            toast.success("회원가입이 완료되었습니다");
        } catch (e) {
            console.error(e);
            toast.error("회원가입이 실패했습니다");
        }
        login(authInfo.id, authInfo.password);

    }

    return (
        <div>
            {isSignUp ?
                <div>
                    <h1 className="mb-3 text-2xl font-extrabold tracking-tight text-slate-900">
                        {tr("회원가입")}
                    </h1>
                    <SignUpContainer onSubmit={onSignupRequested}></SignUpContainer>
                    <div className="mt-2 border-b"></div>
                    <div className="flex mt-2">
                        <h1 className="text-sm text-blue-600 cursor-pointer" onClick={() => { setIsSignUp(false) }}>{tr("뒤로가기")}</h1>
                    </div>
                </div> :
                <div>
                    <div className="text">
                        <h1 className="text-2xl font-bold tracking-tight text-slate-900">
                            {tr("계정으로 로그인")}
                        </h1>
                    </div>
                    <LogInContainer onSubmit={onLoginRequested}></LogInContainer>
                    {/* <div className="mt-2 border-b"></div>
                    <div className="flex justify-end mt-2">
                        <h1 className="text-sm text-blue-600 cursor-pointer" onClick={() => { setIsSignUp(true) }}>{tr("아직 회원이 아니세요?")}</h1>
                    </div> */}
                </div>
            }
        </div>


    )
}

export default AccountLoginForm;
