import React, { useEffect, useState } from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import _ from "lodash";
import SimpleTable from "components/Table/Simple";
import { columnInfos } from "./config";
import Card from "components/Card";
import Quill from "quill";
import { assembleQuillContentsWithFields } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/helper";
import { memberClient } from "apis/member";
import { useTranslation } from "utils/translate";
import { parseSubmissionDetailTableData } from "pages/Admin/Customer/Status/DetailViewer";
import BasicArticle from "components/Article/Basic";
import PageTitle from "components/Title/Page";
import Button from "components/Form/Button";

export const fetchAndCreateSubmissionDetailTableData = async (submissionId) => {
    try {
        const params = {
            lang: "jp"
        }
        const ret = await memberClient.get(`public/submissions/${submissionId}/details`, { params });
        const { intake, intakeTranslations } = ret.data;
        if (_.isNil(intake)) return [];
        const tableDataBySection = parseSubmissionDetailTableData(ret.data);
        return { tableDataBySection, intakeTranslations };
    } catch (e) {
        console.log("[fetchAndCreateSubmissionDetailTableData] error", e);
        return {};
    }
}

export const fetchAndCreatePrescriptionDetailHtmls = async (submissionId) => {
    const quill = new Quill(document.createElement("div"));
    try {
        const ret = await memberClient.get(`/public/prescriptions/${submissionId}/details`);
        console.log("[fetchAndCreatePrescriptionDetailHtml] ret", ret);
        const { contents, translated_contents, fields } = ret.data;
        const originContent = assembleQuillContentsWithFields(contents, fields, false);
        const translatedContent = assembleQuillContentsWithFields(translated_contents, fields, true);
        quill.setContents(originContent);
        const originHtml = quill.getSemanticHTML();
        quill.setContents(translatedContent);
        const translatedHtml = quill.getSemanticHTML();
        return {
            originHtml,
            translatedHtml
        }
    } catch (e) {
        console.log("[fetchAndCreatePrescriptionDetailHtml] error", e)
    }
}

const MemberDetailViewer = ({ value, row }) => {
    const { Modal: DetailModal, openModal: openDetailModal, closeModal: closeDetailModal } = useModal();
    const [submissionDetailTableDataBySection, setSubmissionDetailTableDataBySection] = useState<any>([]);
    const { tr, addTrs } = useTranslation();

    useEffect(() => {
        // console.log("[DetailViewer] row", row);
    }, [row])

    const updateSubmissionDetail = async (submissionId) => {
        try {
            const details: any = await fetchAndCreateSubmissionDetailTableData(submissionId);
            if (_.isEmpty(details)) {
                console.log("[updateSubmissionDetail] details is empty")
                return;
            }
            console.log("[updateSubmissionDetail] details", details);
            setSubmissionDetailTableDataBySection(details.tableDataBySection);
            addTrs(details.intakeTranslations);
        } catch (e) {
            console.error(e);
        }
    }

    const onButtonClicked = async () => {
        console.log("onButtonClicked", row);
        await updateSubmissionDetail(row.submission_id);
        openDetailModal();
    }

    const translatedColumnInfos = columnInfos.map(info => {
        return {
            ...info,
            label: tr(info.label)
        }
    })

    return (
        <>
            <button className="align-sub" onClick={onButtonClicked}>
                <DocumentTextIcon className="size-4 align-center p-0" />
            </button>
            <DetailModal>
                <div className="flex flex-col space-y-2">
                    <div>
                        <PageTitle>
                            {tr("응답지")}
                        </PageTitle>
                        <Card paddingClassNames="px-0 py-1">
                            <div className="p-2">
                                {submissionDetailTableDataBySection.map((section) => {
                                    return (
                                        <div key={section.section_id}>
                                            <h2 className="text-md font-semibold">{tr(section.section_title)}</h2>
                                            {/* <SimpleTable data={section.data} columns={translatedColumnInfos}></SimpleTable> */}
                                            {section.data?.map((elm) => {
                                                return (
                                                    <div className="py-1">
                                                        <div className="flex">
                                                            <div className="">
                                                                <span className="inline-flex align-text-top rounded-full bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-700 me-1 text-nowrap">
                                                                    {elm.question_idx}
                                                                </span>
                                                            </div>
                                                            <div className="w-full">
                                                                <BasicArticle>
                                                                    {elm.question_translated}
                                                                </BasicArticle>
                                                            </div>
                                                        </div>
                                                        <Card paddingClassNames="px-1 py-2">
                                                            <BasicArticle>
                                                                <div className="px-2 py-1">
                                                                    {elm.answer}
                                                                </div>
                                                            </BasicArticle>
                                                        </Card>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </Card>
                    </div>
                    <div className="flex justify-end">
                        <div>
                            <Button onButtonClicked={closeDetailModal}>
                                {tr("닫기")}
                            </Button>
                        </div>
                    </div>
                </div>
            </DetailModal>
        </>
    )
}

export default MemberDetailViewer;
