import React, { useEffect, useState } from "react";
import Card from "components/Card";
import CRUDTable from "components/Table/CRUD";
import _ from "lodash";
import { UserType } from "../types";
import { columnInfos } from "./config";
import { toast } from "react-toastify";
import { adminClient } from "apis/admin";
import { postResetPassword } from "../Staff";
import PageTitle from "components/Title/Page";
import PagingTable from "components/Table/Paging";
import { randomString } from "utils/math";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import Input, { InputFormat } from "components/Form/Input";
import Button from "components/Form/Button";
import { confirmDelete } from "utils/browser";
import { formatDate } from "components/Table/Simple/presets";
import { useDateDialog } from "components/Dialog/Date";

export const PointEditForm = ({ field, currentPoint, id, onSubmit = (id, field, diffPoint, reason) => { } }) => {
    const [diffPoint, setDiffPoint] = useState("");
    const [reason, setReason] = useState("");

    const onMemoChange = () => {
        onSubmit(id, field, diffPoint, reason);
    }

    return (
        <div className="flex flex-col space-y-2 w-[70vw] max-w-md">
            <h1 className="text-lg">포인트 변동분 입력</h1>
            <div>
                <h1 className="text-sm">현재 포인트</h1>
                <Input value={currentPoint} disabled={true}></Input>
            </div>
            <div>
                <h1 className="text-sm">변동후 포인트</h1>
                <Input value={String(Number(currentPoint) + Number(diffPoint))} disabled={true}></Input>
            </div>
            <div>
                <h1 className="text-sm">변동분</h1>
                <Input value={diffPoint} onChange={setDiffPoint} format={InputFormat.NUMBER}></Input>
            </div>
            <div>
                <h1 className="text-sm">변경이유</h1>
                <Input value={reason} onChange={setReason}></Input>
                <div className="text-xs">회원에게 노출되는 문구입니다</div>
            </div>
            <Button onButtonClicked={onMemoChange}>적용</Button>
        </div >
    )
}

const MemberUserPage = () => {
    const [refreshKey, setRefreshKey] = useState(randomString());
    const [filterParams, setFilterParams] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const { Modal: PointEditModal, openModal: openPointEditModal, closeModal: closePointEditModal } = useModal();
    const { DialogProvider, openDialog: openDateDialog } = useDateDialog();

    useEffect(() => {
        // updateUserData();
    }, [])

    useEffect(() => {
        setFilterParams({
            ...(searchText && { search_text: searchText })
        })
        setRefreshKey(randomString());
    }, [searchText])

    const fetchUserData = async (page, limit) => {
        try {
            const params = {
                user_type: UserType.MEMBER,
                page,
                limit,
                ...(filterParams ? filterParams : {}),
            }
            const ret = await adminClient.get(`/users`, { params });
            const tempTableData = ret.data.data;
            const data = {
                data: tempTableData.map((elm) => (_.omit(elm, ["_id", 'password', "global_name"]))),
                page: ret.data.page,
                total: ret.data.total,
            }
            return data;
        } catch (e) {
            console.error(e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const onEditRequested = async (data) => {
        if (data.password_reset_required === true) {
            const ok = window.confirm("비밀번호를 초기화 하시겠습니까?");
            if (!ok) return;
            await postResetPassword(data.user_id);
        }
        try {
            const ret = await adminClient.put(`/users/${data.user_id}`, _.omit(data, ["updated_at", "created_at"]));
            toast.success(`사용자 ${data.user_id}의 정보가 수정되었습니다.`);
            console.log("[MemberUserPage] onSaveRequested update ret: ", ret);
            setRefreshKey(randomString());
            return true;
        } catch (e) {
            console.log("[MemberUserPage] onSaveRequested error: ", e);
            toast.error("사용자 정보 저장에 실패했습니다.");
            return false;
        }
    }

    const onCreateRequested = async (data) => {
        try {
            const ret = await adminClient.post(`/users`, data);
            toast.success("사용자가 추가되었습니다.");
            console.log("[MemberUserPage] onSaveRequested insert ret: ", ret);
            setRefreshKey(randomString());
            return true;
        } catch (e) {
            console.log("[MemberUserPage] onSaveRequested error: ", e);
            toast.error("사용자 정보 저장에 실패했습니다.");
            return false;
        }
    }

    const onDeleteRequested = async (data) => {
        try {
            const ok = confirmDelete(`사용자 ${data.user_id} 삭제를 진행합니다.`);
            if (!ok) {
                return false;
            }
            const ret = await adminClient.delete(`/users/${data.user_id}`);
            toast.success(`사용자 ${data.user_id}의 정보가 삭제되었습니다.`);
            console.log("[MemberUserPage] onDeleteRequested ret: ", ret);
            setRefreshKey(randomString());
            return true;
        } catch (e) {
            console.log("[MemberUserPage] onDeleteRequested error: ", e);
            toast.error("사용자 정보 삭제에 실패했습니다.");
            return false;
        }
    }

    const onEditFormButtonClicked = (row, field) => {
        setSelectedUserId(row.user_id);
        setSelectedData(row[field]);
        setSelectedField(field);
    }

    const onPointEditFormSubmit = async (id, field, diffPoint, reason) => {
        console.log("[MemberUserPage] onPointEditFormSubmit", id, field, diffPoint, reason);
        try {
            const body = {
                user_id: id,
                point: Number(diffPoint),
                reason: reason,
            }
            const ret = await adminClient.post("/users/point", body);
            toast.success(`회원 ${id}의 포인트가 변경되었습니다.`);
            closePointEditModal();
            setRefreshKey(randomString());
        } catch (e) {
            console.error("[MemberUserPage] onPointEditFormSubmit error: ", e);
            toast.error("포인트 변경에 실패했습니다.");
        }
    }

    const columnInfosWithPoint = columnInfos.map((elm) => {
        if (["point"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex justify-end space-x-2 items-center cursor-pointer" onClick={() => { onEditFormButtonClicked(row, field); openPointEditModal() }}>
                        <div>{value}</div>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                    </div>
                )
            }
        }
        if (["birthdate"].includes(elm.field)) {
            return {
                ...elm,
                render: (value, field, row) => (
                    <div className="flex justify-end space-x-2 items-center cursor-pointer" onClick={async () => {
                        const result = await openDateDialog("생년월일", value);
                        console.log("[MemberUserPage] birthdate result", result);
                        if (_.isNil(result)) {
                            return;
                        }
                        const ok = window.confirm(`사용자 ${row.user_id}의 생년월일을 ${formatDate(value)}에서 ${formatDate(String(result))}으로 변경하시겠습니까?`);
                        if (!ok) {
                            return;
                        }
                        try {
                            const ret = await adminClient.put(`/users/${row.user_id}`, { birthdate: result });
                            console.log("[MemberUserPage] birthdate update ret", ret);
                            toast.success(`사용자 ${row.user_id}의 정보가 수정되었습니다.`);
                            setRefreshKey(randomString());
                        } catch (e) {
                            console.error("[MemberUserPage] birthdate update error", e);
                            toast.error(`사용자 ${row.user_id}의 정보 수정에 실패했습니다.`);
                        }
                    }}>
                        <div>{formatDate(value)}</div>
                        <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                    </div>
                )
            }
        }
        return elm;
    });

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>회원정보 관리</PageTitle>
                    <PagingTable
                        fetchData={fetchUserData}
                        filtersComponent={
                            <div>
                                <ToggleSearchInput
                                    upperLabel="회원검색"
                                    upButtonLabel="적용"
                                    downButtonLabel="해제"
                                    placeholder="사용자명, ID, 전화번호 검색"
                                    onToggle={(isUp, txt) => { isUp ? setSearchText(null) : setSearchText(txt) }}
                                />
                            </div>
                        }
                        windowSize={20}
                        columns={columnInfosWithPoint}
                        onEdit={onEditRequested}
                        onCreate={onCreateRequested}
                        onDelete={onDeleteRequested}
                        isCrudable={true}
                        isCreatable={false}
                        refreshKey={refreshKey}
                    />
                </div>
            </Card>
            <PointEditModal>
                <PointEditForm
                    field={selectedField}
                    currentPoint={selectedData}
                    id={selectedUserId}
                    onSubmit={onPointEditFormSubmit}
                />
            </PointEditModal>
            <DialogProvider />
        </>
    )
}

export default MemberUserPage;
