
import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime, truncate } from "components/Table/Simple/presets";
import _ from 'lodash';
import { formatShippingCompanyName, formatShippingLink } from "pages/Admin/Task/InputDeliveryInfo/helper";


export enum CustomerState {
    AWAITING_SUBMISSION_TRANSLATION = "응답지번역대기중", //초기 제출
    AWAITING_PRESCRIPTION = "처방전작성대기중", //응답지 번역 완료
    AWAITING_PRESCRIPTION_TRANSLATION = "처방전번역대기중", // 처방전 작성 완료
    AWAITING_CUSTOMER_NOTI = "처방전발송대기중", // 처방전 번역 완료
    AWAITING_CUSTOMER_PAYMENT = "구매대기중", // 구매 대기중
    PAYMENT_COMPLETED = "배송대기중", // 결제 완료
    SHIPPING = "배송중", // 배송중
    DELIVERED = "배송완료", // 배송 완료
    PURCHASE_FAILURE = "구매진행중단", // 구매 실패
}
export const formatStatus = (value) => {
    return CustomerState[value] ?? value;
}

export const renderStatusNumber = (value, field, row) => {
    const state = row.state as CustomerState;
    if (CustomerState[state] === CustomerState.AWAITING_SUBMISSION_TRANSLATION) return 1;
    if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION) return 2;
    if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION_TRANSLATION) return 3;
    if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_NOTI) return 4;
    if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_PAYMENT) return 5;
    if (CustomerState[state] === CustomerState.PAYMENT_COMPLETED) return 6;
    if (CustomerState[state] === CustomerState.SHIPPING) return 7;
    if (CustomerState[state] === CustomerState.DELIVERED) return 8;
    if (CustomerState[state] === CustomerState.PURCHASE_FAILURE) return 9;
    return -1;
}

// user_id submission_id	intake_id	user_id	state	purchase_at	created_at	updated_at
export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "id",
        visible: false
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
    },
    {
        field: "clinic_user_id",
        label: "고객번호",
        visible: true,
        render: (value, field, row) => {
            return value ?? ""
        }
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
        render: truncate,
        // multiselect: true
    },
    {
        field: "submission_id",
        label: "응답지번호",
        align: "center",
        visible: true,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
    {
        field: "clinic_id",
        label: "의원ID",
        visible: true
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
        // multiselect: true
    },
    {
        field: "state_number",
        label: "상태번호",
        visible: true,
        align: "center",
        mutate: renderStatusNumber
    },
    {
        field: "state",
        label: "상태",
        visible: true,
        render: formatStatus,
        // multiselect: true,
    },
    {
        field: "created_at",
        label: "응답지 제출일",
        visible: true,
        render: formatDate
    },
    {
        field: "submission_translated_at",
        label: "응답지 번역일",
        visible: true,
        render: formatDate
    },
    {
        field: "awaiting_at",
        label: "구매대기시작일",
        visible: true,
        render: formatDate
    },
    {
        field: "purchase_at",
        label: "구매일",
        visible: true,
        render: formatDate
    },
    {
        field: "address",
        label: "회원주소",
        visible: false,
    },
    {
        field: "shipping_address",
        label: "배송지",
        visible: true,
    },
    {
        field: "deliver_request_at",
        label: "배송접수일",
        visible: true,
        render: formatDate
    },
    {
        field: "shipping_company",
        label: "배송사",
        visible: true,
        render: formatShippingCompanyName
    },
    {
        field: "tracking_number",
        label: "송장번호",
        visible: true,
    },
    {
        field: "tracking_shipping_link",
        label: "배송조회",
        render: formatShippingLink,
        align: "center",
    },
    {
        field: "deliver_at",
        label: "배송시작일",
        visible: true,
        render: formatDate
    },
    {
        field: "delivered_at",
        label: "배송완료일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "마지막 수정시간",
        visible: true,
        render: formatDateTime
    },
    {
        field: "unpaid_noti",
        label: "미납알림",
        visible: false,
    },
    {
        field: "purchase_failure_reason",
        label: "구매실패사유",
        visible: false,
    },
    {
        field: "nudge",
        label: "확인중",
        visible: false,
    },
    {
        field: "nudge_memo",
        label: "확인중 메모",
        visible: false,
    }
];