import React, { useState } from "react";
import GeneralTaskList from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import PrescriptionSendForm from "./SendForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { randomString } from "utils/math";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import IconButton from "components/Form/Button/Icon";

const SendPrescription = () => {
    const states: (keyof typeof CustomerState)[] = ["AWAITING_CUSTOMER_NOTI"]
    const { Modal: SendModal, openModal: openSendModal, closeModal: closeSendModal } = useModal();
    const [submissionId, setSubmissionId] = useState(null);
    const [randomKey, setRandomKey] = useState(randomString());
    const navigate = useNavigate();

    const columnInfosWithTask = [
        {
            field: "task",
            label: "작업",
            visible: true,
            render: (value, field, row) =>
                <IconButton
                    paddingClassName="px-2 py-1"
                    onButtonClicked={() => {
                        setSubmissionId(row.submission_id);
                        openSendModal();
                    }}
                >
                    <div className="text-xs">
                        처방전 발송
                    </div>
                </IconButton>,
            align: "center"
        },
        ...columnInfos.map((info) => {
            if ([
                    "awaiting_at",
                    "purchase_at",
                    "shipping_address",
                    "deliver_request_at",
                    "shipping_company",
                    "tracking_number",
                    "tracking_shipping_link",
                    "deliver_at",
                    "delivered_at",
                    "nudge"
                ].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            return info;
        })
    ]

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>처방전 발송</PageTitle>
                    <GeneralTaskList states={states} columnInfos={columnInfosWithTask} randomKeyExternal={randomKey}></GeneralTaskList>
                </div>
            </Card>
            <SendModal>
                <PrescriptionSendForm submissionId={submissionId} onPatched={() => { closeSendModal(); setRandomKey(randomString()) }}></PrescriptionSendForm>
            </SendModal>
        </>
    )
}

export default SendPrescription;
