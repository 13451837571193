import React, { useState } from 'react';
import { tr } from 'utils/translate';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { memberClient } from 'apis/member';

const PasswordReset = () => {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");

    const onLoginRequested = async () => {
        if (password !== rePassword) {
            toast.error("비밀번호가 일치하지 않습니다.");
            return;
        }

        const body = {
            current_password: oldPassword,
            new_password: password
        }

        try {
            const ret = await memberClient.post(`/public/users/update-password`, body);
            toast.success("비밀번호 변경 성공");
            navigate("/");
            console.log(ret);
        } catch (e) {
            toast.error("비밀번호 변경 실패");
        }
    }

    return (
        <div className="flex justify-center min-h-screen h-fit w-full bg-gradient-to-bl from-pink-300 to-transparent bg-pink-100">
            <div className="w-full max-w-screen-lg px-3 pt-[4svh] pb-[4svh] md:mt-[15vh] md:mb-[15vh]">
                <div className="mx-auto max-w-sm mt-3 mb-3 bg-white p-4 rounded-md shadow-xl">
                    <div className="text">
                        <h1 className="text-2xl font-bold tracking-tight text-slate-900">
                            {tr("비밀번호 재설정")}
                        </h1>
                    </div>
                    <div className="mt-3">
                        <Input label={tr("현재 비밀번호")} value={oldPassword} onChange={setOldPassword} type="password"></Input>
                    </div>
                    <div className="mt-3">
                        <Input label={tr("비밀번호")} value={password} onChange={setPassword} type="password"></Input>
                    </div>
                    <div className="mt-3">
                        <Input label={tr("비밀번호 확인")} value={rePassword} onChange={setRePassword} type="password"></Input>
                    </div>
                    <div className="mt-3">
                        <Button onButtonClicked={onLoginRequested}>확인</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordReset;
