import React from "react";
import Button from "components/Form/Button";
import { useTranslation } from "utils/translate";
export default ({ onSubmit = () => { } }) => {
    const { tr } = useTranslation();
    // 진료지가 완료되었을 때 보여지는 페이지
    return (
        <div>
            <div className="mx-auto max-w-2xl mt-3 mb-3">
                <div className="flex flex-col justify-between space-y-3 md:flex-row md:space-y-0 md:space-x-3 mb-4">
                    <h1>{tr("진료지 제출을 완료합니다.")}</h1>
                </div>
                <div>
                    <Button onButtonClicked={onSubmit}>{tr("확인")}</Button>
                </div>
            </div>
        </div>
    )
}
