import { Column } from "components/Table/Simple";
import React from "react";
import { getIdFromCookie } from "utils/cookie";
import { encode64 } from "utils/crypto";
import { INTAKE_TYPE_SELECT_ITEMS } from "containers/Intake/Form/Edit";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

const formatIntakeLink = (intakeId: string) => {
    const id = getIdFromCookie();
    const encodedId = encode64(id);
    const url = `${process.env.REACT_APP_SITE_URL}/intake/${intakeId}?src=${encodedId}`;
    return (
        <div className="flex justify-center space-x-1.5 align-bottom">
            <div className="content-center cursor-pointer">
                <ClipboardDocumentIcon className="h-4 w-4 align-end" onClick={() => { navigator.clipboard.writeText(url); toast.success("클립보드에 복사되었습니다.") }} />
            </div>
            {/* <div className="text-blue-600 cursor-pointer" onClick={() => { window.open(url) }}>
                {url}
            </div> */}
        </div>
    )
}

// clinic_id	treatment_type	title	description	sections	created_at	updated_at
export const columnInfos: Column[] = [
    {
        field: "clinic_id",
        label: "의원ID",
        visible: true,
        creatable: true,
    },
    {
        field: "title",
        label: "제목",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "description",
        label: "설명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "treatment_type",
        label: "진료타입",
        visible: true,
        creatable: true,
        editable: true,
        selectable: {
            getOptions: () => INTAKE_TYPE_SELECT_ITEMS.map((item) => ({ value: item.data, name: item.name }))
        },
        render: (value) => {
            const item = INTAKE_TYPE_SELECT_ITEMS.find((item) => item.data === value);
            return item?.name ?? value;
        }
    },
    {
        field: "sections",
        label: "섹션",
        visible: false,
    },
    {
        field: "intake_id",
        label: "링크",
        visible: true,
        render: formatIntakeLink
    },
    {
        field: "version",
        label: "버전",
        visible: true,
    },
];