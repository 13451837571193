import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Panels from "components/Steps/Panels";
import Questions from "containers/Questions";
import IntroPanel from "./Intro";
import OutroPanel from "./Outro";
import SubmitPanel from "./Submit";
import { parseAnswers } from "./helper";
import _ from "lodash";
import { CommonClient, commonClient } from "apis/common";
import { memberClient } from "apis/member";
import { toast } from "react-toastify";
import { Section } from "pages/Admin/Intake/Editor";
import SpinnerMedium from "components/Spinner/Medium";
import { useTranslation } from "utils/translate";
import { decode64 } from "utils/crypto";
import { UserType } from "pages/Admin/User/types";
import { jwtDecode } from "jwt-decode";
import { useCookies } from "react-cookie";
import { TreatmentType } from "containers/Intake/Form/Edit";
import { useLocation } from "react-router-dom";
import { randomString } from "utils/math";

const PANEL_STEPS = [
    { id: '1', name: '인적사항', href: '#' },
    { id: '2', name: '진료지', href: '#' },
    { id: '3', name: '제출', href: '#' },
]

enum PanelType {
    INTRO = "INTRO",
    PERSONAL = "PERSONAL",
    CLINIC = "CLINIC",
    SUBMIT = "SUBMIT",
    OUTRO = "OUTRO",
}

type PanelStep = {
    id: string; //section_id
    name: string;
    type: PanelType;
}

const LOCAL_STORAGE_CURRENT_PANEL_INDEX_VAR_SUFFIX = "intake_panel_index";
const LOCAL_STORAGE_MAX_PANEL_INDEX_VAR_SUFFIX = "intake_max_panel_index";

const INTRO_PANEL = {
    id: "intro_panel",
    name: "시작하기",
    type: PanelType.INTRO
}

const SUBMIT_PANEL = {
    id: "submit_panel",
    name: "회원가입",
    type: PanelType.SUBMIT
}

const OUTRO_PANEL = {
    id: "outro_panel",
    name: "완료",
    type: PanelType.OUTRO
}

enum PERSONAL_QUESTION_ID_CONSTANTS {
    NAME = "quPLdgo8WDf4",
    BIRTHDATE = "quEYlj2YBBcI",
    PHONE_NUMBER = "qu69QtLGmYnP",
    GENDER = "quo95pyeNJQj",
    NATION = "quDEfFH7GGTA",
}

const PUBLIC_TREATMENT_TYPES = [TreatmentType.FIRST, TreatmentType.SECOND];

const isPublicTreatmentType = (treatmentType: TreatmentType) => {
    return PUBLIC_TREATMENT_TYPES.includes(treatmentType);
}

export default () => {
    const { tr, getTrs, addTrs } = useTranslation();
    const pathParams = useParams();
    const [cookies, setCookie] = useCookies();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const LOCAL_STORAGE_CURRENT_PANEL_INDEX_VAR = `${pathParams.intakeId}_${LOCAL_STORAGE_CURRENT_PANEL_INDEX_VAR_SUFFIX}`
    const LOCAL_STORAGE_MAX_PANEL_INDEX_VAR = `${pathParams.intakeId}_${LOCAL_STORAGE_MAX_PANEL_INDEX_VAR_SUFFIX}`

    const [currentPanelIndex, setCurrentPanelIndex] = useState(() => {
        const localPanelIndex = localStorage.getItem(LOCAL_STORAGE_CURRENT_PANEL_INDEX_VAR);
        if (localPanelIndex) {
            return parseInt(localPanelIndex);
        }
        return 0;
    });
    const [personalQuestions, setPersonalQuestions] = useState([]);
    const [intakeSections, setIntakeSections] = useState<Section[]>([]);
    const [introTitle, setIntroTitle] = useState("");
    const [introDescription, setIntroDescription] = useState("");
    const [panelSteps, setPanelSteps] = useState<PanelStep[]>([]);
    const [intakeId, setIntakeId] = useState(pathParams.intakeId);
    const [intakeVersion, setIntakeVersion] = useState(999);
    const [treatmentType, setTreatmentType] = useState<TreatmentType>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const intakeIdPath = pathParams.intakeId;
        updateTrs(intakeIdPath);
        updatePanelSteps();
    }, [])

    useEffect(() => {
        console.log("[Intake Page] useEffect [currentPanelIndex] currentPanelIndex", currentPanelIndex)
        localStorage.setItem(LOCAL_STORAGE_CURRENT_PANEL_INDEX_VAR, currentPanelIndex.toString());
        if (_.isNil(panelSteps[currentPanelIndex])) {
            console.warn("[Intake Page] useEffect [currentPanelIndex] panelSteps[currentPanelIndex] is nil. maybe not loaded yet.")
            return;
        }
        // update max panel index
        const maxPanelIndex = localStorage.getItem(LOCAL_STORAGE_MAX_PANEL_INDEX_VAR) ?? currentPanelIndex;
        localStorage.setItem(LOCAL_STORAGE_MAX_PANEL_INDEX_VAR, Math.max(Number(maxPanelIndex), currentPanelIndex).toString());
    }, [currentPanelIndex]);

    useEffect(() => {
        console.log("[Intake Page] useEffect [treatmentType] treatmentType", treatmentType);
        if (_.isNil(treatmentType)) {
            return;
        }
        checkAlreadyLoggedIn();
    }, [treatmentType])

    const checkAlreadyLoggedIn = async () => {
        if (PUBLIC_TREATMENT_TYPES.includes(treatmentType)) {
            return;
        }
        try {
            const ret = await commonClient.get("/auth/check");
            console.log("[Intake Page] checkAlreadyLoggedIn", ret.data);
            const { authenticated, user } = ret.data;
            if (!authenticated) {
                throw new Error("Not authenticated");
            }
            toast.info(tr(`회원님 환영합니다. 진료지를 작성해주세요.`));
        } catch (e) {
            toast.info(tr("로그인이 필요한 진료지입니다."));
            localStorage.setItem("logoutFrom", location.pathname);
            navigate("/account/login");
        }

    }

    const updateTrs = async (intakeId) => {
        if (_.isNil(intakeId)) return;
        // trs
        try {
            const lang = searchParams.get("lang") || "JP";
            const params = {
                lang
            }
            const clinicTrRet = await commonClient.get(`/translations/intakes/${intakeId}`, { params });
            const personalTrRet = await commonClient.get(`/translations/intakes/personal`, { params });
            console.log("[Intake Page] updateTrs", clinicTrRet.data, personalTrRet.data);
            // console.log("hihi", params.id)
            const newTrs = [...clinicTrRet.data, ...personalTrRet.data];
            addTrs(newTrs);
        } catch (e) {
            console.error(e);
            toast.error(tr("번역 데이터를 불러오는데 실패했습니다"));
            navigate("/");
            return false;
        }
    }

    const updatePanelSteps = async () => {
        const { questionSteps, treatmentType } = await updateQuestionsStepsFromDb();
        if (isPublicTreatmentType(treatmentType)) {
            setPanelSteps([INTRO_PANEL, ...questionSteps, SUBMIT_PANEL, OUTRO_PANEL]);
        } else {
            setPanelSteps([INTRO_PANEL, ...questionSteps, OUTRO_PANEL]);
        }
    }

    const updateQuestionsStepsFromDb = async () => {
        let questionSteps = [];
        const ret = await updateIntakeQuestionsStepsFromDb();
        if (_.isNil(ret)) {
            return { questionSteps: [], treatmentType: null };
        }
        const { intakeSteps, treatmentType } = ret;
        if (_.isNil(intakeSteps)) return { questionSteps: [], treatmentType: null };
        questionSteps = [...intakeSteps];
        if (isPublicTreatmentType(treatmentType)) {
            const personalStep = await updatePersonalQuestionsStepFromDb();
            if (!_.isNil(personalStep)) {
                questionSteps = [personalStep, ...questionSteps];
            }
        }
        return { questionSteps, treatmentType };
    }

    const updatePersonalQuestionsStepFromDb = async () => {
        try {
            const ret = await commonClient.get(`/intakes/personal`);
            console.log("[Intake Page] personal questions ret", ret.data);
            const { questions, section_id, name } = ret.data.sections[0];
            setPersonalQuestions(questions);
            return {
                id: section_id,
                name: name,
                type: PanelType.PERSONAL
            } as PanelStep
        } catch (e) {
            console.error(e);
            toast.error(tr("인적사항 진료지를 불러오는데 실패했습니다"));
            navigate("/");
            return false;
        }
    }

    const updateIntakeQuestionsStepsFromDb = async () => {
        const intakeIdPath = pathParams.intakeId;
        try {
            const ret = await commonClient.get(`/intakes/${intakeIdPath}`);
            console.log("[Intake Page: updateIntakeFromDb] ret", ret.data);
            setIntroTitle(ret.data.title);
            setIntroDescription(ret.data.description);
            setIntakeVersion(ret.data.version);
            setTreatmentType(ret.data.treatment_type);
            const sections = ret.data.sections;
            const intakeId = ret.data.intake_id;
            const treatmentType = ret.data.treatment_type;
            setIntakeId(intakeId);
            setIntakeSections(sections);
            const intakeSteps = sections.map((section: Section) => ({
                id: section.section_id,
                name: section.name,
                type: PanelType.CLINIC
            }));
            return { intakeSteps, treatmentType } as { intakeSteps: PanelStep[], treatmentType: TreatmentType };
        } catch (e) {
            console.error(e);
            toast.error(tr("진료지를 불러오는데 실패했습니다."));
            localStorage.removeItem(LOCAL_STORAGE_CURRENT_PANEL_INDEX_VAR);
            navigate("/");
        }
    }

    const preparePersonalInfosAndSource = () => {
        const personalQuestionsSectionId = panelSteps.filter(step => step.type === PanelType.PERSONAL)[0].id;
        const personalAnswersFromLocal = localStorage.getItem(`${personalQuestionsSectionId}_answers`);
        const personalAnswers = JSON.parse(personalAnswersFromLocal);
        console.log("[preparePersonalInfosAndSource] PERSONAL ANSWERS", personalAnswers)
        const src = searchParams.get("src");
        let decodedSrc = "WEB";
        try {
            decodedSrc = decode64(src);
        } catch (e) {
            console.error(e);
        }
        const personalInfos = {
            name: personalAnswers[PERSONAL_QUESTION_ID_CONSTANTS.NAME],
            phone_number: personalAnswers[PERSONAL_QUESTION_ID_CONSTANTS.PHONE_NUMBER],
            birthdate: personalAnswers[PERSONAL_QUESTION_ID_CONSTANTS.BIRTHDATE],
            gender: personalAnswers[PERSONAL_QUESTION_ID_CONSTANTS.GENDER],
            nation: personalAnswers[PERSONAL_QUESTION_ID_CONSTANTS.NATION],
        }
        return { personalInfos, decodedSrc };
    }

    const submitUsingUserId = async () => {
        const profileRet = await memberClient.get("/public/users/profile");
        const { user_id } = profileRet.data;
        console.log("[Intake Page] onOutroPanelSubmit", user_id);
        try {
            const submissionRet = await submitSubmissionMember(user_id);
            console.log("[Intake Page] onOutroPanelSubmit submissionRet", submissionRet);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const submitUsingTempId = async () => {
        const tempId = `_${randomString(8)}`;
        const idOk = await submitUserUsingTempId(tempId);
        if (!idOk) {
            return false;
        }
        const submitOk = await submitSubmissionCommon(tempId);
        if (!submitOk) {
            return false;
        }
        localStorage.setItem("temp_id", tempId);
        return true;
    }

    const submitUserUsingTempId = async (tempId) => {
        const { personalInfos, decodedSrc } = preparePersonalInfosAndSource();
        const userDatas = {
            user_id: tempId,
            user_type: UserType.MEMBER,
            name: personalInfos.name,
            phone_number: personalInfos.phone_number,
            birthdate: personalInfos.birthdate,
            gender: personalInfos.gender,
            nation: personalInfos.nation,
            address: "",
            source: decodedSrc,
            point: 0,
            note: "",
            password_reset_required: false,
        }
        try {
            const ret = await commonClient.post('/signup', userDatas)
            console.log("[Intake Page] submitUserUsingTempId", ret);
            // toast.success("임시 ID로 회원가입이 완료되었습니다");
            return true;
        } catch (e) {
            console.error(e);
            toast.error(tr("임시 제출에 실패했습니다."));
            return false;
        }
    }

    const prepareSubmission = () => {
        const clinicQuestionsSectionIds = panelSteps.filter(step => step.type === PanelType.CLINIC).map(step => step.id);
        const clinicAnswersFromLocalArr = clinicQuestionsSectionIds.map(sectionId => localStorage.getItem(`${sectionId}_answers`));
        const clinicAnswersArr = clinicAnswersFromLocalArr.map((clinicAnswers) => JSON.parse(clinicAnswers));
        const cleanedClinicAnswersArr = clinicAnswersArr.map((clinicAnswers) => (_.omitBy(clinicAnswers, _.isNil)));
        const answerSections = intakeSections.map((section: Section, index) => {
            return {
                section_id: section.section_id,
                answers: parseAnswers(tr(intakeSections[index].questions), cleanedClinicAnswersArr[index])
            }
        })
        return {
            intake_id: intakeId,
            intake_version: intakeVersion,
            lang: searchParams.get("lang") || "jp",
            answer_sections: answerSections
        }
    }

    const submitSubmissionCommon = async (tempId) => {
        const submissionData = prepareSubmission();
        const submission = {
            intake_id: submissionData.intake_id,
            intake_version: submissionData.intake_version,
            user_id: tempId,
            lang: submissionData.lang,
            answer_sections: submissionData.answer_sections
        }
        try {
            console.log("SUBMISSON", submission);
            const submissionRet = await commonClient.post(`/submissions/common`, submission);
            console.log("Submission RET", submissionRet);
            // toast.success(tr("진료지 제출이 완료되었습니다"));
            return true;
        } catch (e) {
            console.error(e);
            toast.error(tr("진료지 제출이 실패했습니다"));
            return false;
        }
    }

    const submitSubmissionMember = async (userId) => {
        const submissionData = prepareSubmission();
        const submission = {
            intake_id: submissionData.intake_id,
            intake_version: submissionData.intake_version,
            user_id: userId,
            lang: submissionData.lang,
            answer_sections: submissionData.answer_sections
        }
        try {
            console.log("SUBMISSON", submission);
            const submissionRet = await memberClient.post(`/submissions`, submission);
            console.log("Submission RET", submissionRet);
            // toast.success(tr("진료지 제출이 완료되었습니다"));
            return true;
        } catch (e) {
            console.error(e);
            toast.error(tr("진료지 제출이 실패했습니다"));
            return false;
        }
    }

    const onQuestionsPanelSubmit = async (answers) => {
        console.log("[Intake Page] onQuestionsPanelSubmit answers", answers);
        const nextPanel = panelSteps[currentPanelIndex + 1];
        if (isPublicTreatmentType(treatmentType)) {
            if (nextPanel.type === PanelType.SUBMIT) {
                const ok = await submitUsingTempId();
                if (!ok) {
                    return false;
                }
            }
        } else {
            if (nextPanel.type === PanelType.OUTRO) {
                const ok = await submitUsingUserId();
                if (!ok) {
                    return false;
                }
            }
        }
        setCurrentPanelIndex(currentPanelIndex + 1);
        return true;
    }

    const onSubmitPannelLoginSuccess = async () => {
        console.log("[Intake Page] onSubmitPannelLoginSuccess");
        setCurrentPanelIndex(currentPanelIndex + 1);
        return 0;
    }

    const onOutroPanelSubmit = async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        localStorage.clear();
        localStorage.setItem("refreshToken", refreshToken);
        navigate("/member/submission");
        return;
    }

    const onIntroPanelSubmit = () => {
        setCurrentPanelIndex(currentPanelIndex + 1);
    }

    const onPanelClicked = (panelId) => {
        console.log("[Intake Page] onPanelClicked", panelId);
        const currentPanel = panelSteps[currentPanelIndex];
        if (currentPanel.type === PanelType.SUBMIT) {
            toast.info(tr("진료지가 이미 제출되었습니다."));
            return;
        }
        // if in local storage
        const panel = panelSteps.find((step) => step.id === panelId);
        if (!panel) {
            console.error("[IntakePage] onPanelClicked Panel not found");
            return;
        }

        const clickedPanelIndex = panelSteps.indexOf(panel);
        console.log("[Intake Page] panel", panel);
        const maxPanelIndex = localStorage.getItem(LOCAL_STORAGE_MAX_PANEL_INDEX_VAR);
        console.log("[Intake Page] onPanelClicked", "clickedPanelIndex", clickedPanelIndex, "maxPanelIndex", maxPanelIndex);
        if (_.isNil(maxPanelIndex)) {
            console.error("[IntakePage] onPanelClicked maxPanelIndex is nil");
            return;
        }
        if (clickedPanelIndex <= Number(maxPanelIndex)) {
            setCurrentPanelIndex(clickedPanelIndex);
            return;
        }
    }

    console.log("[Intake Page] current panelSteps", panelSteps);
    const currentPanelId = panelSteps[currentPanelIndex]?.id;
    const currentPanelType = panelSteps[currentPanelIndex]?.type;
    const isLoading = _.isEmpty(panelSteps) || _.isNil(currentPanelId) || _.isNil(currentPanelType);

    console.log("[Intake Page] currentPanelIndex", currentPanelIndex, currentPanelId, currentPanelType);
    console.log("[trs]", getTrs());

    const ENABLED_PANELS = [PanelType.INTRO, PanelType.PERSONAL, PanelType.CLINIC, PanelType.SUBMIT, isPublicTreatmentType(treatmentType) ? null : PanelType.OUTRO];

    return (
        <div className="flex justify-center min-h-screen h-fit w-full bg-gradient-to-bl from-pink-300 to-transparent bg-pink-100">
            <div className="w-full max-w-screen-lg px-3 pt-[4svh] pb-[4svh] md:mt-[15vh] md:mb-[15vh]">
                {isLoading ? <div><SpinnerMedium></SpinnerMedium></div>
                    : <>
                        {ENABLED_PANELS.includes(currentPanelType) &&
                            (<div className="w-full bg-white shadow-xl rounded-lg">
                                <Panels
                                    steps={tr(panelSteps).filter((step) => ENABLED_PANELS.includes(step.type))}
                                    currentStepId={currentPanelId}
                                    onClick={onPanelClicked}
                                />
                            </div>)
                        }
                        <div className="w-full mt-1 p-3 bg-white shadow-xl rounded-lg">
                            {currentPanelType === PanelType.PERSONAL ?
                                <Questions questions={tr(personalQuestions)} sectionId={currentPanelId} onSubmit={onQuestionsPanelSubmit}></Questions>
                                : currentPanelType === PanelType.CLINIC ?
                                    <Questions questions={tr(intakeSections.find((elm: Section) => elm.section_id === currentPanelId).questions)} sectionId={currentPanelId} onSubmit={onQuestionsPanelSubmit}></Questions>
                                    : currentPanelType === PanelType.SUBMIT ?
                                        <SubmitPanel onLoginSuccess={onSubmitPannelLoginSuccess} ></SubmitPanel>
                                        : currentPanelType === PanelType.OUTRO ?
                                            <OutroPanel onSubmit={onOutroPanelSubmit}></OutroPanel>
                                            : currentPanelType === PanelType.INTRO ?
                                                <IntroPanel onSubmit={onIntroPanelSubmit} title={tr(introTitle)} description={tr(introDescription)}></IntroPanel>
                                                : null
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}