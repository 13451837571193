import { Op } from "quill/core";

export enum PrescriptionTemplateFieldType {
    SHORT = "SHORT",
    LONG = "LONG",
}

export interface PrescriptionTemplateField {
    key: string;
    type: PrescriptionTemplateFieldType;
    description: string;
    data: string | Op[];
    translated_data: string;
}