
import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminIntakleEditorPage from "pages/Admin/Intake/Editor";
import AdminIntakeListPage from "pages/Admin/Intake/List";
import AdminIntakeTranslatePage from "pages/Admin/Intake/Translate";

const AdminIntakeRoute = () => (
    <Routes>
        <Route path="/editor" element={<AdminIntakleEditorPage />}>
            <Route path=":intakeId" element={<AdminIntakleEditorPage />}></Route>
        </Route>
        <Route path="/list" element={<AdminIntakeListPage />}></Route>
        <Route path="/translate" element={<AdminIntakeTranslatePage />}>
            <Route path=":intakeId" element={<AdminIntakeTranslatePage />}></Route>
        </Route>
    </Routes>
)

export default AdminIntakeRoute;