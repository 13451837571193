export const getLineLoginRedirectUrl = (state) => {
    const LINE_LOGIN_URL = "https://access.line.me/oauth2/v2.1/authorize";
    // const LINE_LOGIN_PARAM = "response_type=code&scope=profile%20openid%20email&disable_auto_login=true&disable_ios_auto_login=true&bot_prompt=aggressive"
    const LINE_LOGIN_PARAM = "response_type=code&scope=profile%20openid%20email&bot_prompt=aggressive"
    const LINE_LOGIN_CLIENT_ID = "2005744663"
    const LINE_REDIRECT_URI = `${process.env.REACT_APP_SITE_URL}/account/oauth`
    const redirectUrl = `${LINE_LOGIN_URL}?${LINE_LOGIN_PARAM}&client_id=${LINE_LOGIN_CLIENT_ID}&redirect_uri=${LINE_REDIRECT_URI}&state=${state}&ui_locales=ja`;
    return redirectUrl;
}

