import { AdminClient, adminClient } from 'apis/admin';
import DateWithStep from 'components/Form/Datepicker/DateWithStep';
import RangeDatePicker from 'components/Form/Datepicker/Range';
import SimpleTable, { RowInfo } from 'components/Table/Simple';
import React, { useEffect, useState } from 'react';
import { createDateArray } from 'utils/time';
import { columnInfos } from './config';
import moment from 'moment-timezone';
import PageTitle from 'components/Title/Page';
import { createRowInfoWithClinic } from 'pages/Admin/Clinic/helper';

const START = new Date("2024-01-01");
const TODAY = new Date();
export const DTS = createDateArray(START, TODAY);

const TranslateSubmissionStatViewer = () => {
    const [tableData, setTableData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [clinicInfos, setClinicInfos] = useState([]);

    useEffect(() => {
        fetchData();
    }, [selectedDate])

    const fetchData = async () => {
        if(selectedDate === null) return;
        try {
            const params = {
                submission_translated_at_start: moment(selectedDate).format("YYYY-MM-DD"),
                submission_translated_at_end: moment(selectedDate).format("YYYY-MM-DD"),
            }
            // get /trackings/submission-translated/count
            const ret = await adminClient.get('/trackings/submission-translated/count', { params });
            console.log("[TranslateSubmissionStatViewer] fetchData ret", ret);
            setTableData(ret.data);
        } catch (e) {
            console.error("[TranslateSubmissionStatViewer] fetchData error", e);
        }
        const infos = await AdminClient.getClinicInfos();
        setClinicInfos(infos);
    }

    const rowInfoWithClinic: RowInfo = createRowInfoWithClinic(clinicInfos);
    
    return (
        <div>
            <PageTitle>
                일별 응답지 번역건수
            </PageTitle>
            <div className="mb-1">
                <DateWithStep dts={DTS} onDtChanged={setSelectedDate} upperLabel='응답지 번역일'/>
            </div>
            <SimpleTable data={tableData} columns={columnInfos} rowInfo={rowInfoWithClinic} />
        </div>
    );
}

export default TranslateSubmissionStatViewer;
