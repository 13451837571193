import React, { useEffect, useState } from "react";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import Select from "components/Form/Select";
import Textarea from "components/Form/Textarea";
import axios from "axios";
import { AdminClient } from "apis/admin";

export enum TreatmentType {
    FIRST = "FIRST",
    SECOND = "SECOND",
    THIRD = "THIRD",
    FEEDBACK = "FEEDBACK",
    ETC = "ETC"
}

export const INTAKE_TYPE_SELECT_ITEMS = [
    {
        name: "초진",
        data: TreatmentType.FIRST
    },
    {
        name: "재진",
        data: TreatmentType.SECOND
    },
    {
        name: "재재진",
        data: TreatmentType.THIRD
    },
    {
        name: "피드백",
        data: TreatmentType.FEEDBACK
    },
    {
        name: "기타",
        data: TreatmentType.ETC
    }
]

export type IntakeInfo = {
    intake_id: string;
    clinic_id: string;
    treatment_type: string;
    title: string;
    description: string;
}

const IntakeEditForm = ({ intakeId = "default", onCancel = () => { }, onSave = (intakeInfo: IntakeInfo) => { } }) => {
    const [intakeInfo, setIntakeInfo] = useState({
        intake_id: intakeId,
        clinic_id: "",
        treatment_type: INTAKE_TYPE_SELECT_ITEMS[0].data,
        title: "",
        description: ""
    });
    const [clinicInfos, setClinicInfos] = useState([]);

    useEffect(() => {
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos.map((clinic) => {
                return {
                    name: clinic.name,
                    data: clinic.clinic_id
                }
            }));
        });
    }, [])

    const selectedClinic = clinicInfos.find((item) => item.data === intakeInfo.clinic_id);
    const selectedIntakeType = INTAKE_TYPE_SELECT_ITEMS.find((item) => item.data === intakeInfo.treatment_type);

    return (
        <div>
            <h1 className="mb-2 font-semibold font-lg">
                진료지 수정
            </h1>
            <div className="flex flex-col space-y-1">
                <div>
                    <h1 className="font-semibold font-md">
                        의원명
                    </h1>
                    <div>
                        <Select items={clinicInfos} selectedItem={selectedClinic} onChange={(item) => { setIntakeInfo({ ...intakeInfo, clinic_id: item.data }) }}></Select>
                    </div>
                </div>
                <div>
                    <h1 className="font-semibold font-md">
                        진료유형
                    </h1>
                    <div>
                        <Select items={INTAKE_TYPE_SELECT_ITEMS} selectedItem={selectedIntakeType} onChange={(item) => { setIntakeInfo({ ...intakeInfo, treatment_type: item.data }) }}></Select>
                    </div>
                </div>
                <div>
                    <h1 className="font-semibold font-md">
                        제목
                    </h1>
                    <div>
                        <Input value={intakeInfo.title} onChange={(value) => { setIntakeInfo({ ...intakeInfo, title: value }) }}></Input>
                    </div>
                </div>
                <div>
                    <h1 className="font-semibold font-md">
                        설명
                    </h1>
                    <div>
                        <Textarea value={intakeInfo.description} onChange={(value) => { setIntakeInfo({ ...intakeInfo, description: value }) }}></Textarea>
                    </div>
                </div>
                <div className="flex justify-end space-x-2 pt-1">
                    <div>
                        <Button onButtonClicked={onCancel}>취소</Button>
                    </div>
                    <div>
                        <Button onButtonClicked={() => { onSave(intakeInfo) }}>저장</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntakeEditForm;