import React from "react";
import { Column } from "components/Table/Simple";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import IconButton from "components/Form/Button/Icon";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const PrescriptionTemplateManager = ({ data, field, row }) => {
    const navigate = useNavigate();

    const onModifyButtonClicked = () => {
        const { prescription_template_id } = row;
        if (_.isNil(prescription_template_id)) {
            alert("처방템플릿ID가 존재하지 않습니다.");
            return;
        }
        navigate(`/admin/clinic/prescription-template/editor/${prescription_template_id}`);
    }

    return (
        <div className="flex justify-center cursor-pointer" onClick={onModifyButtonClicked}>
            <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
        </div>
    )
}

const LANG_ITEMS = [
    { value: "JP", name: "일본어" },
    { value: "EN", name: "영어" },
]

export const columnInfos: Column[] = [
    {
        field: "task",
        label: "템플릿 수정",
        render: (data, field, row) => (
            <PrescriptionTemplateManager data={data} field={field} row={row}></PrescriptionTemplateManager>
        )
    },
    {
        field: "prescription_template_id",
        label: "처방템플릿ID",
        visible: false,
    },
    {
        field: "clinic_id",
        label: "의원ID",
        creatable: true,
        editable: true,
    },
    {
        field: "name",
        label: "제목",
        creatable: true,
        editable: true,
    },
    {
        field: "description",
        label: "설명",
        creatable: true,
        editable: true,
    },
    {
        field: "translated_lang",
        label: "번역언어",
        creatable: true,
        editable: true,
        selectable: {
            getOptions: () => {
                return LANG_ITEMS
            }
        },
        render: (value) => {
            const item = LANG_ITEMS.find((item) => item.value === value);
            return item?.name ?? value;
        }
    },
]