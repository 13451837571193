import React, { useState } from "react";
import VerticalDragList from "components/Drag/VerticalList";
import Input from "components/Form/Input";
import TitleBadge from "components/Badges/Title";
import IconButton from "components/Form/Button/Icon";
import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";
import { SectionItem } from "./types";
import { createNewSectionItem } from "../helpers";
import { classNames, scrollToElm } from "components/uiUtils";
import _ from "lodash";
import Button from "components/Form/Button";

const SectionCard = ({ orderIdx = "0", sectionId, value, onChange = (sectionId, text) => { } }) => {
    return (
        <div className="p-2">
            <div className="mb-1">
                <TitleBadge>
                    {`${orderIdx} 번 섹션`}
                </TitleBadge>
            </div>
            <div className="px-1">
                <div className="flex flex-col">
                    <h1 className="text-md font-bold text-slate-900 mb-1">섹션 제목</h1>
                    <Input 
                        value={value} 
                        onChange={(text) => { onChange(sectionId, text) }}
                        placeholder="섹션 제목을 입력하세요"></Input>
                </div>
            </div>
        </div>
    )
}

const EXAMPLE_SECTIONS: SectionItem[] = [
    createNewSectionItem(),
    // createNewSectionItem(),
]

const SectionManager = ({ sectionsExternal = null, onSectionSave = (items: SectionItem[]) => { }, onCancelButtonClicked: onCancelButtonClick = () => { } }) => {
    const [sectionsInternal, setSectionsInternal] = useState(sectionsExternal);
    const [selectedSectionId, setSelectedSectionId] = useState(null);

    const onSectionOrderChanged = (rets) => {
        console.log("[SectionManager] onSectionOrderChanged", rets);
        const newSections = rets.map((elm, i) => {
            const found = sectionsInternal.find((section) => section.section_id === elm.id);
            return { section_id: elm.id, name: found.name };
        })
        console.log("newSections", newSections)
        setSectionsInternal(newSections);
        // onSectionChange(newSections); // do not this becaust it is only update when save
    }

    const onSectionNameChanged = (sectionId: string, value: string) => {
        console.log("[SectionManager] onSectionNameChanged", sectionId, value);
        const newSections = sectionsInternal.map((section) => {
            if (section.section_id === sectionId) {
                return { section_id: sectionId, name: value };
            }
            return section;
        })
        console.log("newSections", newSections)
        setSectionsInternal(newSections);
        // onSectionChange(newSections); // do not this becaust it is only update when save
    }

    const onAddButtonClicked = () => {
        // add new section after selected section
        const newItem = createNewSectionItem()
        if (_.isNil(selectedSectionId)) {
            // add last 
            const newSections = [...sectionsInternal, newItem];
            setSectionsInternal(newSections);
        } else {
            const newSections = sectionsInternal.flatMap((section) => {
                if (section.section_id === selectedSectionId) {
                    return [section, newItem];
                }
                return section;
            });
            setSectionsInternal(newSections);
        }
        scrollToElm(newItem.section_id);
        setSelectedSectionId(newItem.section_id);
    }

    const onRemoveButtonClicked = () => {
        // remove selected section
        const newSections = sectionsInternal.filter((section) => section.section_id !== selectedSectionId);
        setSectionsInternal(newSections);
        setSelectedSectionId(null);
    }

    const onSectionClicked = (sectionId: string) => {
        if (sectionId === selectedSectionId) {
            setSelectedSectionId(null);
            return;
        }
        setSelectedSectionId(sectionId);
    }

    const onSaveButtonClicked = () => {
        // onSectionChange(sections);
        onSectionSave(sectionsInternal);
    }

    return (
        <div className="">
            <div className="flex justify-between items-center mb-2">
                <h1 className="text-2xl font-extrabold tracking-tight text-slate-900">섹션 관리</h1>
                <div className="flex space-x-2">
                    <div>
                        <IconButton onButtonClicked={onAddButtonClicked}>
                            <PlusIcon className="h-5 w-5 text-gray-500" />
                        </IconButton>
                    </div>
                    <div>
                        <IconButton onButtonClicked={onRemoveButtonClicked} disabled={(sectionsInternal.length === 1) || _.isNil(selectedSectionId)}>
                            <MinusIcon className="h-5 w-5 text-gray-500" />
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className="border border-[1px] border-gray-300 shadow-md p-3 rounded-lg overflow-scroll max-h-[50svh] min-h-[200px]">
                <VerticalDragList
                    itemsExternal={sectionsInternal.map((elm: SectionItem, i: number) => ({
                        id: elm.section_id,
                        content: (
                            <div
                                id={elm.section_id}
                                onClick={() => { onSectionClicked(elm.section_id) }}
                                className={classNames("p-3 hover:bg-indigo-50 rounded-b-md",
                                    selectedSectionId === elm.section_id ? "ring-indigo-500 ring-[3px] ring-inset" : "")}
                            >
                                <SectionCard
                                    orderIdx={`${i + 1}`}
                                    sectionId={elm.section_id}
                                    value={elm.name}
                                    onChange={onSectionNameChanged}
                                />
                            </div>
                        )
                    }))}
                    setItemsExternal={onSectionOrderChanged}
                />
            </div>
            <div className="flex justify-end items-center mt-2">
                <div className="flex space-x-2">
                    <div>
                        <Button onButtonClicked={onCancelButtonClick}>취소</Button>
                    </div>
                    <div>
                        <Button onButtonClicked={onSaveButtonClicked}>저장</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionManager;