import Card from "components/Card";
import Button from "components/Form/Button";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { memberClient } from "apis/member";
import { UserType } from "pages/Admin/User/types";
import SimpleTable from "components/Table/Simple";
import _ from "lodash";
import { formatDate } from "components/Table/Simple/presets";
import { toast } from "react-toastify";
import PageTitle from "components/Title/Page";
import { tr } from "utils/translate";
import lineLogo from "assets/logo/line.svg";

const MyPage = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [profileTableData, setProfileTableData] = useState([]);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetchProfile();
    }, [])


    const columnInfos = [
        {
            field: "key",
            label: tr("항목"),
            visible: true,
        },
        {
            field: "value",
            label: tr("내용"),
            visible: true,
        }
    ]

    const fieldNameMapper = {
        "user_id": tr("사용자ID"),
        "name": tr("이름"),
        "team": tr("팀"),
        "user_type": tr("사용자타입"),
        "nation": tr("국가"),
        "note": tr("비고"),
        "clinic_id": tr("의원ID"),
        "position": tr("직책"),
        "phone_number": tr("전화번호"),
        "birthdate": tr("생년월일"),
        "gender": tr("성별"),
        "address": tr("회원주소"),
        "source": tr("가입경로"),
        "point": tr("포인트"),
        "created_at": tr("가입일"),
        "line_user_name": tr("라인 사용자명"),
    }

    const fetchProfile = async () => {
        try {
            const ret = await memberClient.get("/public/users/profile");
            let tempTableData = Object.keys(_.omit(ret.data, [
                "user_type",
                "line_id",
                "password",
                "password_reset_required",
                "address",
                "updated_at",
                "source",
                "note",
                "line_state",
                "line_user_id",
                "clinic_name",
                "line_user_name"
            ])).map((key) => {
                const isDate = ["created_at", "birthdate"].includes(key);
                return {
                    key: fieldNameMapper[key] ?? key,
                    value: isDate ? formatDate(ret.data[key]) : ret.data[key]
                }
            })
            let tempUserId = ret.data.user_id;
            setUserId(tempUserId);
            const isSocial = tempUserId.startsWith("@");
            if (isSocial) {
                tempTableData = tempTableData.filter((elm) => elm.key !== tr("사용자ID"));
            }
            // doctor
            setProfileTableData(tempTableData);
        } catch (e) {

        }
    }

    const onPasswordResetRequested = async () => {
        navigate("/account/reset-password");
    }

    const onLogoutRequested = async () => {
        console.log("[MyAccount] onLogoutRequested");
        const ret = await memberClient.post("/logout");
        console.log("[MyAccount] onLogout ret", ret.data);
        localStorage.removeItem("refreshToken");
        removeCookie("Authorization", { path: "/" });
        toast.success(tr("로그아웃 되었습니다."));
        navigate("/account/login")
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>{tr("내 계정")}</PageTitle>
                <SimpleTable data={profileTableData} columns={columnInfos}></SimpleTable>
                <div className="text-xs mb-1">
                    MIVIEW公式LINEを友達追加して500Pゲットしよう💡
                </div>
                <div className="flex justify-between space-x-2">
                    {/* <div className="line-add-friend">
                        <a data-v-1c96c41e="" href="https://lin.ee/EVv1m3S">
                            <img data-v-1c96c41e="" src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="친구 추가" className="h-9 rounded-md"></img>
                        </a>
                    </div> */}
                    <div className="border shadow rounded-md py-1.5 px-2 text-center text-sm font-semibold hover:bg-gray-100">
                        <div className="flex items-center" onClick={() => {
                            window.open("https://lin.ee/EVv1m3S", 'newWindow', 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
                        }}>
                            <img src={lineLogo} alt="line-logo" className="w-6 h-6 mr-1.5" />
                            <div className="align-center">
                                {tr("친구추가")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button
                            onButtonClicked={onLogoutRequested}
                        >
                            {tr("로그아웃")}
                        </Button>
                    </div>
                </div>

            </div>
        </Card>
    )
}

export default MyPage;
