import _ from 'lodash';
import { Column } from '.';

export const transposeOne = (data = {}, omitFields = []) => {
    return Object.keys(_.omit(data, omitFields)).map((key) => (
        {
            key,
            value: data[key]
        }
    ))
}

export const labelKeysWithColumnInfo = (transposedData = [], columnInfos = [] as Column[]) => {
    return transposedData.map((row) => {
        const columnInfo = columnInfos.find((column) => column.field === row.key);
        if (_.isNil(columnInfo)) {
            return {
                ...row,
                label: row.key
            }
        }
        return {
            ...row,
            label: columnInfo.label
        }
    })
}