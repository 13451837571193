import React, { useEffect, useState } from 'react';
import { doctorClient } from 'apis/doctor';
import Card from 'components/Card';
import PageTitle from 'components/Title/Page';
import PagingTable from 'components/Table/Paging';
import { columnInfos } from './config';
import ToggleSearchInput from 'components/Form/Input/ToggleSearch';
import _ from 'lodash';
import Select, { getUidOfItems } from 'components/Form/Select';
import SpinnerMedium from 'components/Spinner/Medium';
import { DTS, TERM_ITEMS } from 'pages/Admin/Customer/PurchaseInfo';
import DateWithStep from 'components/Form/Datepicker/DateWithStep';
import MonthWithStep from 'components/Form/Datepicker/MonthWithStep';
import moment from 'moment';
import { randomString } from 'utils/math';
import { formatProductName } from 'pages/Admin/Task/InputPurchaseInfo/Editor/helper';

const DoctorSalesPage = () => {
    const [filterParams, setFilterParams] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedTermItem, setSelectedTermItem] = useState(null);
    const [selectedDt, setSelectedDt] = useState(null);
    const [productInfos, setProductInfos] = useState([]);
    const [refreshKey, setRefreshKey] = useState(randomString(10));

    useEffect(() => {
        const uid = getUidOfItems(TERM_ITEMS);
        const initialTermItem = localStorage.getItem(uid);
        if (_.isNil(initialTermItem)) {
            setSelectedTermItem(TERM_ITEMS[0])
        }
    }, [])

    useEffect(() => {
        if (_.isNil(selectedTermItem)) {
            return;
        }
        if (selectedTermItem.value === "ALL") {
            setFilterParams({
                ...(!_.isNil(searchText) ? { search_text: searchText } : {})
            });
            return;
        }
        if (selectedTermItem.value === "DAY") {
            if (_.isNil(selectedDt)) {
                return;
            }
            const startDt = new Date(selectedDt);
            const endDt = new Date(selectedDt);
            setFilterParams({
                startDate: moment(startDt).format("YYYY-MM-DD"),
                endDate: moment(endDt).format("YYYY-MM-DD"),
                ...(!_.isNil(searchText) ? { search_text: searchText } : {})
            });
            return;
        }
        if (selectedTermItem.value === "MONTH") {
            if (_.isNil(selectedDt)) {
                return;
            }
            const startDt = new Date(selectedDt);
            startDt.setDate(1);
            const endDt = new Date(startDt);
            endDt.setMonth(endDt.getMonth() + 1);
            endDt.setDate(endDt.getDate() - 1);
            setFilterParams({
                startDate: moment(startDt).format("YYYY-MM-DD"),
                endDate: moment(endDt).format("YYYY-MM-DD"),
                ...(!_.isNil(searchText) ? { search_text: searchText } : {})
            });
            return;
        }
    }, [searchText, selectedTermItem, selectedDt]);

    useEffect(() => {
        console.log("[DoctorSalesPage] use effect filterParams", filterParams)
        fetchProductInfos();
        setRefreshKey(randomString(10))
    }, [filterParams])

    const fetchData = async (page: number, limit: number) => {
        try {
            const params = {
                page,
                limit,
                ...filterParams
            }
            const ret = await doctorClient.get('/public/purchases', { params });
            if (_.isNil(ret?.data?.data)) {
                return { data: [], total: 0, page: 1 }
            }
            return {
                data: ret.data.data,
                total: ret.data.total,
                page: ret.data.page
            }
        } catch (e) {
            console.error("[DoctorSalesPage] fetchData error", e);
            return { data: [], total: 0, page: 1 }
        }
    }

    const fetchProductInfos = async () => {
        try {
            const params = {
                page: 1,
                limit: 100000
            }
            const ret = await doctorClient.get(`/public/products`, { params });
            console.log("[fetchProductInfos] ret", ret);
            if (_.isNil(ret?.data?.data)) return console.error("No data");
            const tempProductInfos = ret.data.data;
            console.log("[fetchProductInfos] tempProductInfos", tempProductInfos);
            setProductInfos(tempProductInfos);
        } catch (e) {

        }
    }

    const modifiedColumnInfos = [
        ...columnInfos.map((columnInfo) => {
            if (columnInfo.field === "items") {
                return {
                    ...columnInfo,
                    visible: true,
                    render: (value, field, row) => {
                        const items = row.items;
                        const productNames = []
                        items.forEach((item) => {
                            if (!_.isNil(item.name)) {
                                productNames.push(item.name);
                                return;
                            }
                            const product = productInfos.find((productInfo) => productInfo.product_id === item.product_id);
                            if (_.isNil(product)) {
                                return;
                            }
                            const label = formatProductName(product);
                            productNames.push(label);
                        });
                        return productNames.join(", ");
                    }

                }
            }
            return columnInfo
        })
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>판매 내역</PageTitle>
                <div className="flex space-x-2 pb-2">
                    <div>
                        <Select upperLabel="기간 유형" items={TERM_ITEMS} selectedItem={selectedTermItem} onChange={setSelectedTermItem} saveSelectedItems={true} />
                    </div>
                    {_.isNil(selectedTermItem) ? <SpinnerMedium /> :
                        <div hidden={selectedTermItem.value === "ALL"}>
                            {selectedTermItem.value === "DAY" && <DateWithStep upperLabel="구매일" dts={DTS} onDtChanged={setSelectedDt} />}
                            {selectedTermItem.value === "MONTH" && <MonthWithStep upperLabel="구매월" onDtChanged={setSelectedDt} />}
                        </div>
                    }
                    <div className="min-w-36">
                        <ToggleSearchInput
                            upperLabel="고객검색"
                            upButtonLabel="적용"
                            downButtonLabel="해제"
                            onToggle={(isUp, value) => { setSearchText(!isUp ? value : null) }}
                            placeholder="고객명, 고객번호, ID 검색"
                        />
                    </div>
                </div>
                <PagingTable
                    fetchData={fetchData}
                    columns={modifiedColumnInfos}
                    refreshKey={refreshKey}
                    windowSize={20}
                />
            </div>
        </Card>
    )
};

export default DoctorSalesPage;
