import React from "react"

const EXAMPLE_STEPS = [
    { name: 'Step 1', href: '#', status: 'complete' },
    { name: 'Step 2', href: '#', status: 'current' },
    { name: 'Step 3', href: '#', status: 'upcoming' },
]

export default ({ steps = EXAMPLE_STEPS, currentStepIndex = 0, maxStepIndex = 0, onClick = (idx) => { } }) => {
    console.log("[Bullets] currentStepIndex", currentStepIndex, "maxStepIndex", maxStepIndex)
    return (
        <div className="flex items-center px-2 w-full pb-2">
            <p className="text-sm font-medium text-nowrap">
                Step {currentStepIndex + 1} of {steps.length}
            </p>
            <ol role="list" className="flex ml-8 items-center space-x-5">
                {steps.map((step, i) => (
                    <li key={step.name} onClick={() => { onClick(i) }}>
                        {i === currentStepIndex ? (
                            <a href={step.href} className="relative flex items-center justify-center">
                                <span className="absolute flex h-5 w-5 p-px">
                                    <span className="h-full w-full rounded-full bg-indigo-200" />
                                </span>
                                <span className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600 transition-colors duration-300" />
                                <span className="sr-only">{step.name}</span>
                            </a>
                        ) : i <= maxStepIndex ? (
                            <a href={step.href} className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900 transition-colors duration-300">
                                <span className="sr-only">{step.name}</span>
                            </a>
                        ) : (
                            <a href={step.href} className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400 transition-colors duration-300">
                                <span className="sr-only">{step.name}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    )
}
