import { Question } from "containers/Questions/Question/types";
import { Option } from "pages/Admin/Intake/Editor/ChoiceMaker";
import _ from "lodash";

export type Submission = {
    submission_id: number; // Server에서 자동으로 부여
    intake_id: string;
    user_id: string;  // 사용자 ID
    answer_sections: AnswerSection[];
}

export type Answer = {
    question_id: string;
    answer: string | string[] | Date; // 질문 유형에 따라 단일 문자열 또는 문자열 배열
    conditional_answers?: Answer[]; // 조건부 추가 질문에 대한 응답
}

export type AnswerSection = {
    section_id: string;
    answers: Answer[];
}

export function parseAnswers(questions: Question[], rawAnswers: Record<string, any>): Answer[] {
    return questions.map(question => {
        const rawAnswer = rawAnswers[question.question_id];

        // If the answer is undefined, skip creating the answer object
        if (rawAnswer === undefined || rawAnswer === null || rawAnswer === "" || (Array.isArray(rawAnswer) && rawAnswer.length === 0)) {
            return null;
        }

        const answer: Answer = {
            question_id: question.question_id,
            answer: rawAnswer
        };
        const cqs = question.conditional_questions;
        if (cqs && cqs.length > 0) {
            const currentOptionIds = question?.options?.filter((option: Option) => {
                if (option.text === rawAnswer) {
                    return true;
                }
                if (Array.isArray(rawAnswer)) {
                    if(rawAnswer.includes(option.text)){
                        return true;
                    }
                }
            }).map(option => option.option_id);
            console.log("[parseAnswers] currentOptionIds", currentOptionIds, "currentAnswer", rawAnswer);
            if (_.isNil(currentOptionIds)) {
                return answer;
            }
            const matchedCqs = cqs.filter(cq => currentOptionIds.includes(cq.condition));
            if (_.isEmpty(matchedCqs)) {
                return answer;
            }
            console.log("[parseAnswers] matchedCqs", matchedCqs);
            answer.conditional_answers = parseAnswers(matchedCqs, rawAnswers);
            // Filter out any null conditional answers
            answer.conditional_answers = answer.conditional_answers.filter(ans => ans !== null);
        }
        return answer;
    }).filter(ans => ans !== null); // Filter out any null answers
}
