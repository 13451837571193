
import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminCustomerRoute from "./Customer";
import AdminIntakeRoute from "./Intake";
import AdminTaskRoute from "./Task";
import AdminUserRoute from "./User";
import AdminClinicRoute from "./Clinic";
import AdminMasterRoute from "./Master";

export default () => (
    <Routes>
        <Route path="/customer/*" element={<AdminCustomerRoute />}></Route>
        <Route path="/intake/*" element={<AdminIntakeRoute />}></Route>
        <Route path="/task/*" element={<AdminTaskRoute />}></Route>
        <Route path="/user/*" element={<AdminUserRoute />}></Route>
        <Route path="/clinic/*" element={<AdminClinicRoute/>}></Route>
        <Route path="/master/*" element={<AdminMasterRoute/>}></Route>
    </Routes>
)

