import React, { useEffect, useState } from "react";
import Dropdown from "components/Form/Dropdown";
import { Cog6ToothIcon, Cog8ToothIcon, CpuChipIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { Column } from "components/Table/Simple";
import { formatDate, truncate } from "components/Table/Simple/presets";
import _ from 'lodash';
import { useModal } from "components/Modal/Simple";
import { adminClient } from "apis/admin";
import Button from "components/Form/Button";
import Textarea from "components/Form/Textarea";
import Input from "components/Form/Input";
import { useNavigate } from "react-router-dom";
import DetailViewer from "../DetailViewer";
import { toast } from "react-toastify";
import PrescriptionSendForm from "pages/Admin/Task/SendPrescription/SendForm";
import { CustomerState } from "../config";
import Select, { getUidOfItems } from "components/Form/Select";
import SpinnerMedium from "components/Spinner/Medium";

const PURCHASE_FAILURE_REASON = {
    UNPAID: "28일 이후 미결제", // 28일 이후 미결제
    PRESCRIPTION_NOT_POSSIBLE: "처방 불가", // 원장님이 처방못한다고 한 경우(질병, 몸상태 등등) (이 경우만 노 카운팅)
    SEARCH_NOT_POSSIBLE: "라인 검색 불가", // 라인에서 검색이 안되는 경우, 차단당한 경우
    SHIPPING_NOT_POSSIBLE: "배송 불가", // 고객 개인사정으로 배송을 나중에 받아서 당장 구매를 못하는 경우, 주소지 자체가 배송불가인 지역
    PAYMENT_UNAVAILABLE: "결제수단 없음", // 결제수단 없음, 계좌, paypal 이외 결제수단 원하셔서 결제 못하는 분
    FINANCIAL_DIFFICULTY: "구매자금 부족", // 돈이 없어서, 비싸서 구매를 안하시는 경우
    NO_PURCHASE_INTENT: "구매의사 없음", // 상담만, 구매의사 없음, 검토하겠다, 고객의 의사로 안사는 경우
    ISSUE_FOUND: "이슈로 인한 불가", // sns 등에서 이슈 발견으로 구매의사 취소한 경우
    INFLUENCER_CONVERSION: "인플루언서 전환", // 일반고객이 인플루언서로 전환될 경우, 마케팅팀 관리 (노 카운팅)
    DUPLICATE_SUBMISSION: "중복제출", // 중복제출, 중복제출로 인한 구매진행 중단
    ETC: "기타", // 기타
}

export const FAILURE_ITEMS = Object.keys(PURCHASE_FAILURE_REASON).map((key) => ({
    name: PURCHASE_FAILURE_REASON[key],
    value: key
}))

export const formatPurchaseFailureReason = (value) => {
    return PURCHASE_FAILURE_REASON[value] ?? value;
}

export const PurchaseFailureForm = ({ row, onSubmit }) => {
    const [selectedItem, setSelectedItem] = useState(FAILURE_ITEMS[0]);
    const [reason, setReason] = useState("");
    const { submission_id, intake_id, user_id, } = row;
    useEffect(() => {
        console.log("[PurchaseFailureForm] useEffect[]", row);
    }, [])

    const onComplete = async () => {
        const body = {
            purchase_failure_reason: selectedItem.value,
        }
        try {
            const ok = window.confirm("구매 실패 사유를 저장하시겠습니까?");
            if (!ok) {
                return;
            }
            const ret = await adminClient.patch(`/trackings/${submission_id}/purchase_failure`, body);
            console.log("[PurchaseFailureForm] onComplete ret", ret);
            toast.success("구매 실패 사유가 저장되었습니다.");
            onSubmit();
        } catch (e) {
            console.error(e);
            toast.error("구매 실패 사유 저장에 실패했습니다.");
        }
    }

    return (
        <div className="max-w-[200px]">
            구매진행 중단 사유 입력
            <Select items={FAILURE_ITEMS} selectedItem={selectedItem} onChange={(item) => { setSelectedItem(item) }}></Select>
            <div className="flex justify-end">
                <div className="mt-2">
                    <Button onButtonClicked={onComplete}>완료</Button>
                </div>
            </div>
        </div >
    )
}

export enum SHIPPING_COMPANY {
    KOREA_POST = "우체국", // 우체국
    ECOHAI = "에코하이", // 에코하이
    SAGAWA = "사가와", // 사가와
    YAMATO = "야마토", // 야마토
    CU = "CU포스트", // cu포스트
    CJ = "CJ대한통운", // cj대한통운
    HANJIN = "한진", // 한진택배
    ETC = "ETC" // 기타
}

export const SHIPPING_COMPANY_ITEMS = Object.keys(SHIPPING_COMPANY).map((key) => ({
    name: SHIPPING_COMPANY[key],
    value: key
}))

export const TrackingNumberInputForm = ({ submissionId, onSubmit }) => {
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [selectedShippingCompanyItem, setSelectedShippingCompanyItem] = useState(null);

    useEffect(() => {
        console.log("[TrackingNumberInputForm] useEffect[]", submissionId);
        if (_.isNil(submissionId)) {
            return;
        }
        fetchTrackingInfo();
    }, [submissionId])

    const fetchTrackingInfo = async () => {
        const ret = await adminClient.get(`/trackings/${submissionId}/info`);
        console.log("[fetchTrackingInfo] ret", ret);
        setTrackingNumber(ret.data.tracking_number);
        const selectedShippingCompanyItem = SHIPPING_COMPANY_ITEMS.find((item) => item.value === ret.data.shipping_company);
        if (!_.isNil(selectedShippingCompanyItem)) {
            setSelectedShippingCompanyItem(selectedShippingCompanyItem);
        } else {
            const uid = getUidOfItems(SHIPPING_COMPANY_ITEMS);
            const savedItem = localStorage.getItem(uid);
            if(_.isNil(savedItem)) {
                setSelectedShippingCompanyItem(SHIPPING_COMPANY_ITEMS[0]);
            }
        }
    }

    const onSave = async () => {
        if (_.isNil(selectedShippingCompanyItem)) {
            toast.error("배송사를 선택해주세요.");
            return;
        }
        if (_.isNil(trackingNumber)) {
            toast.error("배송정보를 입력해주세요.");
            return;
        }
        const body = {
            "tracking_number": trackingNumber,
            "shipping_company": selectedShippingCompanyItem.value
        }
        try {
            const ret = await adminClient.patch(`/trackings/${submissionId}/field`, body);
            toast.success("배송정보가 저장되었습니다.");
            onSubmit();
        } catch (e) {
            console.log(e);
            toast.error("배송정보 저장에 실패했습니다.");
        }

    }

    return (
        <div className="min-w-[200px]">
            <div className="text-lg font-semibold">
                배송정보 입력
            </div>
            <div className="flex flex-col">
                <div className="">
                    <h1>배송사</h1>
                    <Select items={SHIPPING_COMPANY_ITEMS} selectedItem={selectedShippingCompanyItem} onChange={setSelectedShippingCompanyItem} saveSelectedItems={true}></Select>
                </div>
                <div className="">
                    <h1>송장번호</h1>
                    <Input value={trackingNumber} onChange={setTrackingNumber}></Input>
                </div>
            </div>
            <div className="flex justify-end space-x-2">
                <div className="mt-2">
                    <Button onButtonClicked={onSave}>저장</Button>
                </div>
                {/* <div className="mt-2">
                    <Button onButtonClicked={onComplete}>완료</Button>
                </div> */}
            </div>
        </div>
    )
}

const TaskManager = ({ value, row, onTaskFinished = () => { } }) => {
    const navigate = useNavigate();
    const { Modal: PrescriptionSendModal, openModal: openPrescriptionSendModal, closeModal: closePrescriptionSendModal } = useModal({});
    const { Modal: TrackingNumberInputModal, openModal: openTrackingNumberInputModal, closeModal: closeTrackingNumberInputModal } = useModal({});
    const { Modal: PurchaseFailureModal, openModal: openPurchaseFailureModal, closeModal: closePurchaseFailureModal } = useModal({});

    const state = row.state as CustomerState;
    const submission_id = row.submission_id;
    const taskItems = [
        {
            name: "상태 되돌리기",
            action: async () => {
                const keys = Object.keys(CustomerState);
                const idx = keys.indexOf(state);
                const prevKey = keys[idx - 1];
                const prevState = CustomerState[prevKey];
                let message = `이전 상태 "${prevState}"로 변경하시겠습니까?`;
                const failureKey = keys.find((key) => CustomerState[key] === CustomerState.PURCHASE_FAILURE);
                if (state === failureKey) {
                    message = "구매진행 중단 상태를 되돌리시겠습니까?";
                }
                const deliveredKey = keys.find((key) => CustomerState[key] === CustomerState.DELIVERED);
                if(state === deliveredKey) {
                    message = `이전 상태 "배송중"으로 변경하시겠습니까?\n배송중 상태로 되돌리면 "구매정보 확인"과 "복용후 관리"에서 사라집니다.\n다시 "배송완료 처리"할 경우 포인트가 한 번 더 적립됩니다.\n포인트 조정이 필요할 경우 "회원정보 관리"에서 수정해주세요.`;
                }
                const ok = window.confirm(message);
                if (!ok) {
                    return;
                }
                try {
                    const ret = await adminClient.patch(`/trackings/${submission_id}/rewind`);
                    console.log("[rewind ret]", ret);
                    toast.success("상태가 되돌려졌습니다.");
                    onTaskFinished();
                } catch (e) {
                    console.error(e);
                    // toast.error("상태변경에 실패했습니다.");
                }
            },
            disabled: CustomerState[state] === CustomerState.AWAITING_SUBMISSION_TRANSLATION
        },
        null,
        {
            name: "응답지 번역",
            action: () => {
                navigate(`/admin/task/translate-submission/editor/${submission_id}`)
            },
            disabled: CustomerState[state] !== CustomerState.AWAITING_SUBMISSION_TRANSLATION
        },
        {
            name: "처방전 번역",
            action: () => {
                navigate(`/admin/task/translate-prescription/editor/${submission_id}`)
            },
            disabled: CustomerState[state] !== CustomerState.AWAITING_PRESCRIPTION_TRANSLATION
        },
        {
            name: "처방전 발송",
            action: async () => {
                openPrescriptionSendModal();
            },
            disabled: CustomerState[state] !== CustomerState.AWAITING_CUSTOMER_NOTI
        },
        {
            name: "구매정보 입력",
            action: async () => {
                navigate(`/admin/task/input-purchase-info/editor/${row.submission_id}`)
            },
            disabled: CustomerState[state] !== CustomerState.AWAITING_CUSTOMER_PAYMENT
        },
        {
            name: "배송정보 입력",
            action: () => {
                navigate(`/admin/task/input-delivery-info?user_search_text=${row.clinic_user_id}`);
            },
            disabled: CustomerState[state] !== CustomerState.PAYMENT_COMPLETED
        },
        {
            name: "배송완료 처리",
            action: async () => {
                const body = {
                    deliveredDate: new Date()
                }
                const ret = await adminClient.patch(`/trackings/${row.submission_id}/delivered`, body);
                console.log(ret);
                // reload
                onTaskFinished();
            },
            disabled: CustomerState[state] !== CustomerState.SHIPPING
        },
        null,
        {
            name: "구매진행 중단",
            action: () => {
                openPurchaseFailureModal();
            },
            disabled: CustomerState[state] === CustomerState.PURCHASE_FAILURE
        },
    ]

    return (
        <div>
            <Dropdown
                anchor="bottom start"
                CustomMenuButton={() => {
                    return (
                        <Cog6ToothIcon className="size-4 align-center"></Cog6ToothIcon>
                    )
                }}
                items={taskItems}
            />
            <PrescriptionSendModal>
                <PrescriptionSendForm submissionId={row.submission_id} onPatched={() => { closePrescriptionSendModal(); onTaskFinished() }}></PrescriptionSendForm>
            </PrescriptionSendModal>
            <TrackingNumberInputModal>
                <TrackingNumberInputForm submissionId={row.submission_id} onSubmit={() => { closeTrackingNumberInputModal(); onTaskFinished() }}></TrackingNumberInputForm>
            </TrackingNumberInputModal>
            <PurchaseFailureModal>
                <PurchaseFailureForm row={row} onSubmit={() => { closePurchaseFailureModal(); onTaskFinished() }}></PurchaseFailureForm>
            </PurchaseFailureModal>
        </div>

    )
}

export default TaskManager;