import React, { useEffect, useState } from "react";
import { adminClient } from "apis/admin";
import CRUDTable from "components/Table/CRUD";
import Card from "components/Card";
import { columnInfos } from "./config";
import _ from "lodash";
import PageTitle from "components/Title/Page";
import { toast } from "react-toastify";
import { confirmDelete } from "utils/browser";

const formatAftercareData = (data) => {
    let daysObj = {};
    ["management_day1", "management_day2", "management_day3", "post_management_day1", "post_management_day2"].forEach((key) => {
        daysObj[key] = (_.isNil(data[key]) || data[key] === "") ? null : Number(data[key]);
    });
    console.log("[formatAftercareData] daysObj", daysObj)
    const newData = {
        aftercare_type_id: data.aftercare_type_id,
        ..._.omit(data, ["created_at", "updated_at"]),
        ...daysObj
    }
    return newData;
}

const AftercareTypePage = () => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        fetchAftercareTypes();
    }, [])

    const fetchAftercareTypes = async () => {
        try {
            const ret = await adminClient.get(`/aftercares/types`);
            console.log("[fetchAftercareTypes] ret", ret);
            const tempTableData = ret.data;
            console.log("[fetchAftercareTypes] tempTableData", tempTableData);
            setTableData(tempTableData);
        } catch (e) {
            console.error("[fetchAftercareTypes] error", e);
        }
    }

    const onCreate = async (data) => {
        const ok = window.confirm("생성하시겠습니까?");
        if (!ok) {
            return false;
        }
        try {
            console.log("[AftercareTypePage] onCreate", data)
            if (_.isEmpty(data.name)) {
                alert("관리유형명을 입력해주세요");
                return false;
            }
            const tempData = formatAftercareData(data);
            console.log("[AftercareTypePage] onCreate tempData", tempData)
            const ret = await adminClient.post(`/aftercares/types`, tempData);
            console.log("[AftercareTypePage] onCreate ret", ret);
            fetchAftercareTypes();
            return true;
        } catch (e) {
            console.error("[AftercareTypePage] onCreate error", e);
        }
    }

    const onEdit = async (data) => {
        const tempData = formatAftercareData(data);
        console.log("[AftercareTypePage] onEdit tempData", tempData)
        const ok = window.confirm("수정하시겠습니까?");
        if (!ok) {
            return false;
        }
        try {
            console.log("[AftercareTypePage] onEdit", tempData)
            if (_.isEmpty(data.name)) {
                alert("관리유형명을 입력해주세요");
                return false;
            }

            const ret = await adminClient.put(`/aftercares/types/${tempData?.aftercare_type_id}`, tempData);
            console.log("[AftercareTypePage] onEdit ret", ret);
            await fetchAftercareTypes();
            return true;
        } catch (e) {
            console.error("[AftercareTypePage] onEdit error", e);
        }
    }

    const onDelete = async (data) => {
        const ok = confirmDelete();
        if (!ok) {
            return false;
        }
        try {
            console.log("[AftercareTypePage] onDelete", data)
            const ret = await adminClient.delete(`/aftercares/types/${data?.aftercare_type_id}`);
            console.log("[AftercareTypePage] onDelete ret", ret);
            await fetchAftercareTypes();
            return true;
        } catch (e) {
            console.error("[AftercareTypePage] onDelete error", e);
        }
    }

    const onReorder = async (tableData, row, isUp) => {
        const ok = window.confirm("순서를 변경하시겠습니까?");
        if (!ok) {
            return false;
        }
        try {
            const aftercare_type_id = row.aftercare_type_id;
            const directionPath = isUp ? "move-up" : "move-down";
            const ret = await adminClient.put(`/aftercares/types/${aftercare_type_id}/${directionPath}`);
            console.log("[onReorder] ret", ret);
            await fetchAftercareTypes();
            toast.success("관리유형의 순서가 변경되었습니다.")
            return true;
        } catch (e) {
            console.error(e);
            toast.error("관리유형 순서 변경에 실패했습니다.")
            return false;
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>복용 후 연락 유형 관리</PageTitle>
                <CRUDTable
                    data={tableData}
                    columns={columnInfos}
                    onCreate={onCreate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    isReordable={true}
                    onReorder={onReorder}
                    isStickyFirstCol={true}
                    disableSort={true}
                />
            </div>
        </Card>
    )
}

export default AftercareTypePage;
