import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import CRUDTable from 'components/Table/CRUD';
import { columnInfos } from './config';
import { randomString } from 'utils/math';
import { adminClient } from 'apis/admin';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { AdminClient } from 'apis/admin';
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from '../helper';
import PageTitle from 'components/Title/Page';
import { confirmDelete } from 'utils/browser';

const createNewTemplate = (clinicId, name, description, translatedLang) => {
    return ({
        prescription_template_id: `pt${randomString(10)}`,
        clinic_id: clinicId,
        name,
        description,
        contents: "",
        translated_lang: translatedLang,
        translated_contents: "",
        detail_contents: "",
        fields: [

        ]
    })
}

const PrescriptionTemplateEditorPage = () => {
    const [templateTableData, setTemplateTableData] = useState([]);
    const [clinicInfos, setClinicInfos] = useState([]);

    useEffect(() => {
        updateTemplateList();
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        });
    }, [])

    const updateTemplateList = async () => {
        const ret = await adminClient.get(`/prescription-templates/distinct/latest`);
        const tempTableData = ret.data.map((elm) => {
            return {
                prescription_template_id: elm.prescription_template_id,
                clinic_id: elm.clinic_id,
                name: elm.name,
                description: elm.description,
                translated_lang: elm.translated_lang
            }
        })
        setTemplateTableData(tempTableData);
        console.log(ret);
    }

    const onCreateRequested = async (data) => {
        console.log("[PrescriptionTemplateEditorPage] onCreateRequested", data);
        const newItem = createNewTemplate(data.clinic_id, data.name, data.description, data.translated_lang);
        console.log(newItem);
        try {
            const ret = await adminClient.post(`/prescription-templates`, newItem);
            toast.success("템플릿이 생성되었습니다.");
            await updateTemplateList();
            console.log(ret);
            return true;
        } catch (e) {
            toast.error("템플릿 생성에 실패했습니다.");
            return false;
        }
    }

    const onDeleteRequested = async (data) => {
        console.log("[PrescriptionTemplateEditorPage] onDeleteRequested", data);
        const ok = confirmDelete();
        if (!ok) {
            return false;
        }
        try {
            const ret = await adminClient.delete(`/prescription-templates/${data.prescription_template_id}`);
            toast.success("템플릿이 삭제되었습니다.");
            await updateTemplateList();
            return true;
        } catch (e) {
            toast.error("템플릿 삭제에 실패했습니다.");
            return false;
        }
    }

    const onEditRequested = async (data) => {
        console.log("[PrescriptionTemplateEditorPage] onEditRequested", data);
        try {
            const origin = await adminClient.get(`/prescription-templates/${data.prescription_template_id}`);
            const newTemplate = {
                ..._.omit(origin.data, ["_id", "created_at", "updated_at"]),
                clinic_id: data.clinic_id,
                name: data.name,
                description: data.description,
                translated_lang: data.translated_lang
            }
            const ret = await adminClient.post(`/prescription-templates`, newTemplate);
            toast.success("템플릿 정보가 수정되었습니다.");
            await updateTemplateList();
            return true;
        } catch (e) {
            toast.error("템플릿 정보 수정에 실패했습니다.");
            return false;
        }
    }

    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfos, clinicInfos);
    const rowInfo = createRowInfoWithClinic(clinicInfos);

    return (
        <Card>
            <div className="p-3">
                <PageTitle>처방전 템플릿 관리</PageTitle>
                <CRUDTable data={templateTableData} columns={columnInfosWithClinic} rowInfo={rowInfo} onCreate={onCreateRequested} onDelete={onDeleteRequested} onEdit={onEditRequested}></CRUDTable>
            </div>
        </Card>
    )
}

export default PrescriptionTemplateEditorPage;