import React, { useEffect, useState } from "react";
import PagingTable from "components/Table/Paging";
import { memberClient } from "apis/member";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import { randomString } from "utils/math";
import { useTranslation } from "utils/translate";
import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";

const PointHistory = () => {
    const [refreshKey, setRefreshKey] = useState(randomString());
    const { tr } = useTranslation();

    const fetchTableData = async (page: number, limit: number) => {
        console.log("[PointHistory] fetchTableData", page, limit);
        try {
            const params = {
                page,
                limit,
            }
            const ret = await memberClient.get(`/public/users/point/history`, { params });
            console.log("[fetchTableData] ret", ret);
            if (!ret?.data?.data) {
                return { data: [], page: 1, total: 0 }
            }
            return {
                data: ret.data.data,
                page: ret.data.page,
                total: ret.data.total,
            }
        } catch (e) {
            console.error(e);
            return { data: [], page: 1, total: 0 }
        }
    }


    const columnInfos: Column[] = [
        {
            field: "updated_at",
            label: tr("변동일"),
            visible: true,
            render: formatDate
        },
        {
            field: "_id",
            label: "ID",
            visible: false,
        },
        {
            field: "user_id",
            label: tr("사용자ID"),
            visible: false,
        },
        {
            field: "user_name",
            label: tr("고객명"),
            visible: false,
        },
        {
            field: "phone_number",
            label: tr("전화번호"),
            visible: false,
        },
        {
            field: "change",
            label: tr("변동"),
            visible: true,
        },
        {
            field: "reason",
            label: tr("변동이유"),
            visible: true,
        },
        {
            field: "created_at",
            label: tr("생성일"),
            visible: false,
        }
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>{tr("포인트 내역")}</PageTitle>
                <PagingTable
                    fetchData={fetchTableData}
                    columns={columnInfos}
                    windowSize={20}
                    refreshKey={refreshKey}
                    disableNav={true}
                />
            </div>
        </Card>
    )
}

export default PointHistory;
