import React, { useState } from "react";
import GeneralTaskList from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { formatPurchaseFailureReason } from "../Status/TaskManager";
import DetailViewer from "../Status/DetailViewer";
import IconButton from "components/Form/Button/Icon";
import { adminClient } from "apis/admin";
import { toast } from "react-toastify";
import { randomString } from "utils/math";

const PurchaseFailure = () => {
    const states: (keyof typeof CustomerState)[] = ["PURCHASE_FAILURE"]
    const [refreshKey, setRefreshKey] = useState(randomString());
    const columnInfosWithTask = [
        {
            field: "user_name",
            label: "고객명",
            visible: true,
        },
        {
            field: "task",
            label: "작업",
            render: (value, field, row) => (
                <IconButton paddingClassName="px-2 py-1" onButtonClicked={async () => {
                    const { submission_id } = row;
                    const ok = window.confirm('"구매진행중단" 상태를 "응답지번역대기" 상태로 되돌립니다. 계속하시겠습니까?');
                    if (!ok) {
                        return;
                    }
                    try {
                        const ret = await adminClient.patch(`/trackings/${submission_id}/rewind`);
                        console.log("[rewind ret]", ret);
                        toast.success("상태가 되돌려졌습니다.");
                        setRefreshKey(randomString());
                    } catch (e) {
                        console.error(e);
                        // toast.error("상태변경에 실패했습니다.");

                    }
                }}>
                    <div className="text-xs">
                        되돌리기
                    </div>
                </IconButton>
            )
        },
        ...columnInfos.map((info) => {
            if ([
                "awaiting_at",
                "purchase_at",
                "shipping_address",
                "deliver_request_at",
                "shipping_company",
                "tracking_number",
                "tracking_shipping_link",
                "deliver_at",
                "delivered_at",
                "nudge"
            ].includes(info.field)) {
                return {
                    ...info,
                    visible: false
                }
            }
            if ([
                "purchase_failure_reason",
            ].includes(info.field)) {
                return {
                    ...info,
                    visible: true,
                    render: formatPurchaseFailureReason
                }
            }
            if (["clinic_detail"].includes(info.field)) {
                return {
                    ...info,
                    render: (value, field, row) => (
                        <div hidden={row.intake_title === "삭제된 진료지" || row.user_name === "Unknown User"}>
                            <DetailViewer value={value} row={row}></DetailViewer>
                        </div>
                    ),
                }
            }
            return info;
        })
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>구매진행 중단 목록</PageTitle>
                <GeneralTaskList states={states} columnInfos={columnInfosWithTask} randomKeyExternal={refreshKey}></GeneralTaskList>
            </div>
        </Card>

    )
}

export default PurchaseFailure;
