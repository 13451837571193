
import React from "react";
import { Routes, Route } from "react-router-dom";

import ActionPage from "pages/Admin/Master/Action";

const AdminMasterRoute = () => (
    <Routes>
        <Route path="/action" element={<ActionPage />}></Route>
    </Routes>
)

export default AdminMasterRoute;