import { Cookies } from "react-cookie"
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import { UserType } from "pages/Admin/User/types";
const cookies = new Cookies();

const getDecodedToken = (): { id: string, type: UserType } => {
    const token = cookies.get("Authorization");
    if (_.isNil(token)) {
        console.log("[getDecodedToken] token is nil");
        // alert("[getDecodedToken] token is nil");
        return null;
    }
    try {
        return jwtDecode(token);
    } catch (e) {
        console.log("[getDecodedToken] error", e);
        alert("[parseToken] error")
        return null;
    }
}

export const getIdFromCookie = () => {
    try {
        const parsed = getDecodedToken();
        return parsed.id;
    } catch (e) {
        console.log("[getIdFromCookie] error", e);
        return null;
    }
}

export const getUserTypeFromCookie = (): UserType => {
    try {
        const parsed = getDecodedToken();
        return parsed.type;
    } catch (e) {
        console.log("[getUserTypeFromCookie] error", e);
        return null;
    }
}
