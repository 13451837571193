import React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Column } from "components/Table/Simple";
import { formatStatus } from "pages/Admin/Customer/Status/config";
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import axios from "axios";
import Textarea from "components/Form/Textarea";
import { formatDate } from "components/Table/Simple/presets";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import IconButton from "components/Form/Button/Icon";

const PrescriptionManager = ({ value, row }) => {
    const navigate = useNavigate();
    const { Modal: PrescriptionModal, openModal: openPrescriptionModal, closeModal: closePrescriptionTranslateModal } = useModal();
    const { submission_id } = row;

    return (
        <>
            <div className="flex justify-center items-center w-full">
                <IconButton paddingClassName="px-2 py-1" onButtonClicked={() => { navigate(`/doctor/prescription/write/${submission_id}`) }}>
                    <div className="text-xs">
                        처방전작성
                    </div>
                </IconButton>
            </div>
        </>
    )
}

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "_id",
        visible: false,
    },
    {
        field: "write_prescription",
        label: "작업",
        visible: true,
        render: (value, field, row) => <PrescriptionManager value={value} row={row} />,
        align: "center"
    },
    {
        field: "clinic_user_id",
        label: "고객번호",
        visible: true,
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
    },
    {
        field: "submission_id",
        label: "응답지번호",
        visible: true,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
    },
    {
        field: "state",
        label: "상태",
        visible: true,
        render: formatStatus
    },
    {
        field: "created_at",
        label: "응답지 제출일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: true,
        render: formatDate
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    }
];