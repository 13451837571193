import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import ManagerSideNav from 'containers/SideNav/manager';
import ManagerSiteRoute from 'containers/SiteRoute/managerRoute';
import MemberSideNav from 'containers/SideNav/member';
import MemberSiteRoute from 'containers/SiteRoute/memberRoute';
import DoctorSideNav from 'containers/SideNav/doctor';
import DoctorSiteRoute from 'containers/SiteRoute/doctorRoute';
import IntakePage from 'pages/Intake';
import Login from 'pages/Account/Login';
import "./App.scss";
import { UserType } from 'pages/Admin/User/types';
import { InvalidTokenError, jwtDecode } from "jwt-decode";
import PasswordReset from 'pages/Account/PasswordReset';
import OAuthPage from 'pages/Account/OAuth';
import { useCookies } from 'react-cookie';
import LoggedInLayout from 'containers/Layout/LoggedIn';
import _ from 'lodash';

function App() {
    return (
        <Routes>
            <Route path="/intake" element={<IntakePage></IntakePage>}>
                <Route path=":intakeId" element={<IntakePage></IntakePage>}></Route>
            </Route>
            <Route path="/intake/preview" element={<IntakePage></IntakePage>}>
                <Route path=":intakeId" element={<IntakePage></IntakePage>}></Route>
            </Route>
            <Route path="/account/reset-password" element={<PasswordReset></PasswordReset>}>
            </Route>
            <Route path="/account/login" element={<Login></Login>}>
                {/* <Route path=":loginType" element={<Login></Login>}></Route> */}
            </Route>
            <Route path="/account/oauth" element={<OAuthPage></OAuthPage>}>
            </Route>
            <Route path="/*" element={
                <LoggedInLayout></LoggedInLayout>
            }>
            </Route>
        </Routes>
    );
}

export default App;