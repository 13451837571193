import axios from "axios";
import { Cookies } from "react-cookie";
import { responseErrorInterceptor, tokenRefreshInterceptor } from "./admin";

const cookies = new Cookies();

export const doctorClient = axios.create({
    baseURL: process.env.REACT_APP_REST_SERVER_URL,
    withCredentials: true,
});

doctorClient.interceptors.request.use(
    tokenRefreshInterceptor,
    error => {
        console.log("[userClient] request error", error);
        return Promise.reject(error);
    }
);

doctorClient.interceptors.response.use(
    response => {
        return response;
    },
    responseErrorInterceptor
);
