import React, { useEffect, useRef, useState } from 'react';
import { VariableIcon } from "@heroicons/react/20/solid";
import { useModal } from 'components/Modal/Simple';
import { useQuill } from 'react-quilljs';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';
import _ from "lodash";
import Quill from 'quill';
import { Op } from 'quill/core';
import styles from 'components/Form/Editor/Quill/Basic/styles.module.scss';

// pink
export const VARIABLE_BG_COLOR = "#ffcccc";

const QuillWithVariable = ({ uid, variableSelectItems, onQuillReady = (quill: Quill) => { } }) => {
    const { Modal: VariableSelectModal, openModal: openVariableSelectModal, closeModal: closeVariableSelectModal } = useModal({});
    const [selectedVariableItem, setSelectedVariableItem] = useState(null);
    const existVarsRef = useRef(null);

    const modules = {
        toolbar: {
            container: `#toolbar-${uid}`,
            handlers: {
                "customTag": function () {
                    if (_.isEmpty(existVarsRef.current)) {
                        alert("변수가 없습니다. 변수를 먼저 생성해 주세요.");
                        return;
                    }
                    openVariableSelectModal();
                }
            }
        }
    };
    const { quill, quillRef } = useQuill({ modules, bounds: `#editor-container-${uid}` });

    useEffect(() => {
        if (_.isNil(quill)) {
            return;
        }
        console.log("[PrescriptionTemplatePage] quill Changed", quill);
        quill.on('text-change', (delta, oldDelta, source) => {
            onEditorTextChanged(delta, oldDelta, source);
        });
        quill.on('selection-change', (range, oldRange, source) => {
            onEditorSelectionChanged(range, oldRange, source);
        });
        onQuillReady(quill);
    }, [quill])

    useEffect(() => {
        if (_.isNil(variableSelectItems)) {
            return;
        }
        console.log("[PrescriptionTemplatePage] variableSelectItems Changed", variableSelectItems);
        setSelectedVariableItem(variableSelectItems[0]);
        existVarsRef.current = variableSelectItems.map((item) => item.name);
        validateVariables();
    }, [variableSelectItems])

    const onEditorTextChanged = (delta, oldData, source) => {
        console.log("[PrescriptionTemplatePage] onEditorTextChanged", delta, oldData, source);
        console.log("content:", quill.getContents());
        // find all custom tags
        validateVariables();
    }

    const onEditorSelectionChanged = (range, oldRange, source) => {
        if (range) {
            if (range.length == 0) {
                // console.log('User cursor is on', range.index);
                // const currentContent = quill.getText(range);
                // console.log('Current content:', currentContent);
            } else {
                // const text = quill.getText(range.index, range.length);
                // console.log('User has highlighted', text);
            }
        } else {
            console.log('Cursor not in the editor');
        }
    };

    const onVariableModalAdded = async () => {
        // const text = prompt("Enter text for custom tag");
        const text = selectedVariableItem.name;
        if (_.isNil(text)) {
            return;
        }
        if (_.isNil(quill.getSelection())) {
            return;
        }
        quill.insertText(quill.getSelection()?.index, text, {
            background: VARIABLE_BG_COLOR,
        });
        quill.format('background', false);
        closeVariableSelectModal();
    }

    const validateVariables = () => {
        if (_.isNil(quill)) {
            return;
        }
        const existVars = existVarsRef.current;
        if (_.isNil(existVars)) {
            return;
        }
        const contents = quill.getContents();
        const newContents: any = { ...contents };
        console.log("[PrescriptionTemplatePage] validateVariables", contents.ops);
        if (_.isEmpty(contents.ops)) {
            return;
        }
        let somethingChanged = false;
        const newOps = contents?.ops.map((op: Op) => {
            if (op?.attributes?.background === VARIABLE_BG_COLOR) {
                const key = op?.insert;
                if (_.includes(existVars, key)) {
                    return op;
                } else {
                    delete op.attributes.background;
                    somethingChanged = true;
                    return op;
                }
            } else {
                return op;
            }
        });
        if (!somethingChanged) {
            return;
        }
        const currentCursor = quill.getSelection()?.index;
        newContents.ops = newOps;
        quill.setContents(newContents);
        if (_.isNil(currentCursor)) {
            return;
        }
        quill.setSelection(currentCursor);
    }

    return (
        <>
            <div className={`${styles.customSize}`}>
                <div className="ql-snow p-2" id={`editor-container-${uid}`}>
                    <div id={`toolbar-${uid}`}>
                        <span className="ql-formats">
                            <button className="ql-customTag">
                                <VariableIcon />
                            </button>
                        </span>
                        <span className="ql-formats">
                            <select className="ql-size">
                                <option value="small"></option>
                                <option selected></option>
                                <option value="large"></option>
                                <option value="huge"></option>
                            </select>
                        </span>
                        <span className="ql-formats">
                            <button className="ql-bold"></button>
                            <button className="ql-italic"></button>
                            <button className="ql-underline"></button>
                            <select className="ql-color">
                                <option value="black"></option>
                                <option value="red"></option>
                                <option value="green"></option>
                                <option value="blue"></option>
                                <option value="orange"></option>
                                <option value="violet"></option>
                                <option selected></option>
                            </select>
                            <select className="ql-background">
                                <option value="red"></option>
                                <option value="green"></option>
                                <option value="blue"></option>
                                <option value="orange"></option>
                                <option value="violet"></option>
                                <option selected></option>
                            </select>
                        </span>
                        <span className="ql-formats">
                            <button className="ql-list" value="bullet"></button>
                            <button className="ql-list" value="ordered"></button>
                        </span>
                        <span className="ql-formats">
                            <button className="ql-link"></button>
                            <button className="ql-image"></button>
                        </span>
                    </div>
                    <div ref={quillRef} />
                </div>
            </div>
            <VariableSelectModal>
                <div>
                    <h1 className="font-semibold mb-2">변수 선택</h1>
                    <div className="mb-2">
                        <Select items={variableSelectItems} selectedItem={selectedVariableItem} onChange={setSelectedVariableItem}></Select>
                    </div>
                    <Button onButtonClicked={onVariableModalAdded}>템플릿에 추가</Button>
                </div>
            </VariableSelectModal>
        </>

    )
}

export default QuillWithVariable;
