import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime, truncate } from "components/Table/Simple/presets";

// note	team	password_reset_required	created_at	updated_at
export const columnInfos: Column[] = [
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
        render: truncate,
        creatable: true,
    },
    {
        field: "name",
        label: "사용자명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "user_type",
        label: "사용자구분",
        visible: false,
    },
    {
        field: "team",
        label: "팀",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "note",
        label: "비고",
        visible: true,
        editable: true
    },
    {
        field: "password_reset_required",
        label: "비밀번호재설정필요",
        visible: true,
        render: (value) => value ? "○" : "✕",
        align: "center",
        editable: true,
        selectable: {
            getOptions: () => [
                { value: true, name: "○" },
                { value: false, name: "✕" },
            ]
        }
    },
    {
        field: "created_at",
        label: "생성일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "마지막 수정시간",
        visible: true,
        render: formatDateTime
    },
    {
        field: "refresh_token",
        label: "리프레시토큰",
        visible: false
    },
    {
        field: "clinic_name",
        label: "의원명",
        visible: false
    },
    {
        field: "point",
        label: "포인트",
        visible: false
    }
];