import { Column } from "components/Table/Simple";
import { formatDate, formatDateTime } from "components/Table/Simple/presets";

export const columnInfos: Column[] = [
    {
        field: "userId",
        label: "사용자 ID",
        visible: true,
    },
    {
        field: "userType",
        label: "사용자 타입",
        visible: true,
    },
    {
        field: "url",
        label: "URL",
        visible: true,
    },
    {
        field: "method",
        label: "METHOD",
        visible: true,
    },
    {
        field: "created_at",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "ip",
        label: "IP",
        visible: true,
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: false,
        render: formatDateTime
    },
    {
        field: "userAgent",
        label: "접속환경",
        visible: true,
    },
]