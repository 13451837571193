import React, { useEffect, useRef, useState } from "react";
import _, { set } from "lodash";
import Input from "components/Form/Input";
import { PrescriptionTemplateField, PrescriptionTemplateFieldType } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/types";
import BasicQuillEditor from "components/Form/Editor/Quill/Basic";
import { toast } from "react-toastify";
import { randomString } from "utils/math";
import { ArrowDownOnSquareIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { INTENDED_EMPTY_TEXT } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/helper";

export const NAME_LIKE_FIELDS = ["이름", "name", "NAME"];

const VariableWriteForm = ({ userName = null, initialFormData, templateFields, dataFields = [], onDataChanged = (data) => { }, isForeignExample = false }) => {
    const formDataRef = useRef({})
    const quillsRef = useRef({})
    const [refreshKey, setRefreshKey] = useState(randomString());

    useEffect(() => {
        console.log("[VariableWriteForm] useEffect [initialFormData]", initialFormData);
        if (_.isNil(initialFormData)) {
            return;
        }
        formDataRef.current = initialFormData;
        Object.keys(initialFormData).forEach((key) => {
            if (quillsRef.current[key]) {
                quillsRef.current[key].setContents(initialFormData[key]);
            }
        })
        setRefreshKey(randomString());
    }, [initialFormData])

    useEffect(() => {
        console.log("[VariableWriteForm] useEffect [templateFields]", templateFields)
    }, [templateFields])

    const onQuillReady = (key, quill) => {
        console.log("[VariableWriteForm] onQuillReady", key, quill, initialFormData)
        quillsRef.current[key] = quill;
        if (_.isNil(initialFormData) || _.isEmpty(initialFormData)) {
            return;
        }
        console.log("[VariableWriteForm] set contents", key, initialFormData[key])
        quill.setContents(initialFormData[key]);
    }

    const onChange = (key, value) => {
        console.log("[VariableWriteForm] onChange", key, value, formDataRef.current)
        const newData = {
            ...formDataRef.current,
            [key]: value
        }
        formDataRef.current = newData;
        onDataChanged(newData);
    }

    if (_.isNil(templateFields)) {
        return null;
    }

    return (
        <div className="flex flex-col space-y-2" key={refreshKey}>
            {templateFields.map((field: PrescriptionTemplateField) => {
                const exampleText = (isForeignExample ? field.translated_data : field.data);
                const exampleTextWithGuide = exampleText ? ` (예시: ${exampleText})` : "";
                const placeholder = `${field.description}${exampleTextWithGuide}`;
                const isNameLikeField = NAME_LIKE_FIELDS.includes(field.key);
                const foundField = dataFields.find((elm) => elm.key === field.key);
                if (field.type === PrescriptionTemplateFieldType.SHORT) {
                    return (
                        <div key={field.key}>
                            <div className="flex justify-between">
                                <h1 className="font-semibold text-sm mb-[2.5px]">
                                    {field.key}
                                </h1>
                                <div className="cursor-pointer" onClick={() => {
                                    onChange(field.key, exampleText);
                                }}>
                                    <ArrowDownOnSquareIcon className="size-4" />
                                </div>
                            </div>
                            <Input
                                placeholder={placeholder}
                                onChange={(v) => {
                                    onChange(field.key, v);
                                }}
                                onButtonClicked={() => {
                                    if (!_.isNil(foundField)) {
                                        // toast.success("처방전 변수로부터 데이터를 가져왔습니다.");
                                        onChange(field.key, foundField.data);
                                        return;
                                    }
                                    if (isNameLikeField) {
                                        toast.success("고객정보로부터 이름 데이터를 가져왔습니다.");
                                        onChange(field.key, userName);
                                        return;
                                    }

                                }}
                                value={formDataRef.current[field.key]}
                                withButton={isNameLikeField && !_.isNil(userName) || !_.isEmpty(dataFields)}
                                buttonLabel="가져오기"
                            ></Input>
                        </div>
                    )
                } else if (field.type === PrescriptionTemplateFieldType.LONG) {
                    const txt = quillsRef.current[field.key]?.getText();
                    const isIntendedEmpty = txt === INTENDED_EMPTY_TEXT;
                    return (
                        <div key={field.key}>
                            <div className="flex justify-between space-x-2">
                                <div className="flex space-x-[1px] self-center mb-[2.5px]">
                                    <h1 className="font-semibold text-sm">
                                        {field.key}
                                    </h1>
                                    {!isIntendedEmpty ?
                                        <div className="self-center">
                                            <div className="cursor-pointer" onClick={() => {
                                                quillsRef.current[field.key].setText(INTENDED_EMPTY_TEXT);
                                            }}>
                                                <MinusCircleIcon className="size-4" />
                                            </div>
                                        </div> :
                                        <div className="self-center">
                                            <div className="cursor-pointer" onClick={() => {
                                                quillsRef.current[field.key].setText();
                                            }}>
                                                <PlusCircleIcon className="size-4 align-middle" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {!isIntendedEmpty &&
                                    <div className="cursor-pointer" onClick={() => {
                                        quillsRef.current[field.key].setText(exampleText);
                                    }}>
                                        <ArrowDownOnSquareIcon className="size-4" />
                                    </div>
                                }
                            </div>

                            {!isIntendedEmpty ?
                                <BasicQuillEditor
                                    placeholder={placeholder}
                                    onChange={(delta) => {
                                        onChange(field.key, delta.ops);
                                    }}
                                    onQuillReady={(quill) => onQuillReady(field.key, quill)}
                                ></BasicQuillEditor>:
                                <div className="shadow-sm ring-1 ring-inset ring-gray-300 py-1.5 rounded-md"></div>
                            }
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default VariableWriteForm;