import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import lineLogo from 'assets/logo/line.svg';
import nonMemberLogo from 'assets/logo/non-member.png';
import { useTranslation } from 'utils/translate';
import { useModal } from 'components/Modal/Simple';
import AccountLoginForm from './AccountLoginForm';
import { useParams, useSearchParams } from 'react-router-dom';
import SpinnerMedium from 'components/Spinner/Medium';
import { CommonClient, commonClient, tryAssignDevAuthCookie } from 'apis/common';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Cookies, useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserType } from 'pages/Admin/User/types';
import { randomString } from 'utils/math';
import { getLineLoginRedirectUrl } from 'utils/auth';
import { tr } from 'utils/translate';

export const handleLoginMessage = (e: MessageEvent) => {
    console.log("[Login] message received", e.data);
    const { refreshToken, token } = e.data;
    if (_.isNil(refreshToken) || _.isNil(token)) {
        return false;
    }
    localStorage.setItem("refreshToken", refreshToken);
    tryAssignDevAuthCookie(token);
    toast.success(tr("라인 로그인이 완료되었습니다."), { toastId: "line-login-success" });
    return true;
}

const Login = () => {
    const { Modal: LoginModal, openModal: openLoginModal, closeModal: closeLoginModal } = useModal();
    const { loginType } = useParams();
    const [searchParams] = useSearchParams();
    // const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log("[PreviousPage] location", location.state);
        window.addEventListener('message', (e) => {
            const ok = handleLoginMessage(e);
            if (!ok) {
                return;
            }
            window.removeEventListener('message', () => { });
            navigate("/");
        });
    }, [])


    const onLineLogin = async () => {
        const STATE = randomString(10); // not used variable. just for line convention.
        const redirectUrl = getLineLoginRedirectUrl(STATE);
        const popupWindow = window.open(redirectUrl, 'newWindow', 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
    }

    return (
        <>
            <div className="flex justify-center min-h-screen h-fit w-full bg-gradient-to-bl from-pink-300 to-transparent bg-pink-100">
                <div className="w-full max-w-screen-lg px-3 pt-[4svh] pb-[4svh] md:mt-[15vh] md:mb-[15vh]">
                    {loginType === "line" ? <SpinnerMedium></SpinnerMedium> :
                        <div className="mx-auto max-w-3xl mt-3 mb-3 bg-white p-4 rounded-md shadow-xl">
                            <h1 className="text-2xl mb-2 font-bold tracking-tight text-slate-900">
                                {tr("로그인")}
                            </h1>
                            <div className="flex flex-col justify-between space-y-3 md:flex-row md:space-y-0 md:space-x-3">
                                <div className="border shadow rounded-md p-3 w-full text-center font-semibold hover:bg-gray-100">
                                    <div className="flex justify-center" onClick={onLineLogin}>
                                        <img src={lineLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                                        <div className="self-center">
                                            {tr("라인으로 로그인")}
                                        </div>
                                    </div>
                                </div>
                                <div className="border shadow rounded-md p-3 w-full text-center font-semibold hover:bg-gray-100">
                                    <div className="flex justify-center" onClick={() => { openLoginModal() }}>
                                        <img src={nonMemberLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                                        <div className="self-center">
                                            {tr("계정으로 로그인")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <LoginModal>
                <AccountLoginForm></AccountLoginForm>
            </LoginModal>
        </>
    )
}

export default Login;