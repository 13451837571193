import { VARIABLE_BG_COLOR } from "./QuillWithVariable";
import _ from 'lodash';
import { PrescriptionTemplateField, PrescriptionTemplateFieldType } from "./types";

export const INTENDED_EMPTY_TEXT = "    \n";

export const isEmptyOps = (ops) => {
    return _.isEmpty(ops) || (ops.length === 1 && ops[0].insert === "\n");
}

export const isIntendedEmptyOp = (op) => {
    return op.insert === INTENDED_EMPTY_TEXT;
}

export const assembleQuillContentsWithFields = (contents, fields: PrescriptionTemplateField[], isTranslated) => {
    console.log("[assembleQuillContentsWithFields] contents, fields, isTranslated, bgColor", contents, fields, isTranslated);
    const newContents = contents.ops.flatMap((op) => {
        if (op.attributes?.background === VARIABLE_BG_COLOR) {
            const foundField = fields.find((field) => field.key === op.insert);
            if (_.isNil(foundField)) {
                return op;
            } else {
                if (foundField.type === PrescriptionTemplateFieldType.SHORT) {
                    const insert = isTranslated ? foundField.translated_data : foundField.data;
                    if (_.isEmpty(insert)) {
                        return op;
                    }
                    return {
                        insert: isTranslated ? foundField.translated_data : foundField.data,
                    }
                } else if (foundField.type === PrescriptionTemplateFieldType.LONG) {
                    const ops = isTranslated ? foundField.translated_data : foundField.data;
                    if (isEmptyOps(ops)) {
                        return op;
                    }
                    return ops;
                }
            }
        }
        return op;
    }).filter((op) => !isIntendedEmptyOp(op));
    return newContents;
}

export const validateAllFieldsIncludeInContents = (contents, fields: PrescriptionTemplateField[]) => {
    console.log("[validateAllFieldsIncludeInContents] contents, fields", contents, fields);
    if (_.isEmpty(contents.ops)) {
        return fields;
    }
    const existVars = contents.ops.reduce((acc, op) => {
        if (op?.attributes?.background === VARIABLE_BG_COLOR) {
            acc.push(op.insert);
        }
        return acc;
    }, []);
    const notExistVars = fields.filter((field) => !existVars.includes(field.key));
    return notExistVars;
};
