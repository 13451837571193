import React from "react";
import { Column } from "components/Table/Simple";
import { formatDate, truncate } from "components/Table/Simple/presets";
import DetailViewer from "../Status/DetailViewer";

// intake_id	user_id	answer_sections	submission_id	created_at	updated_at
export const columnInfos: Column[] = [
    {
        field: "detail",
        label: "내용확인",
        visible: true,
        render: (value, field, row) => <DetailViewer value={value} row={row}></DetailViewer>,
        align: "center"
    },
    {
        field: "submission_id",
        label: "응답지번호",
        visible: true,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
        render: truncate
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "answer_sections",
        label: "답변섹션",
        visible: false,
    },
    {
        field: "created_at",
        label: "생성일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: true,
        render: formatDate
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
    {
        field: "lang",
        label: "언어",
        visible: false,
    }
];