import React, { useEffect, useState } from "react";
import SimpleTable, { RowInfo } from "components/Table/Simple";
import { memberClient } from "apis/member";
import _ from "lodash";
import { transposeOne, labelKeysWithColumnInfo } from "components/Table/Simple/helper";
import { columnInfos } from "pages/Admin/Customer/PurchaseInfo/config";
import { formatDate } from "components/Table/Simple/presets";
import { formatJpProductName, formatProductName } from "pages/Admin/Task/InputPurchaseInfo/Editor/helper";
import SpinnerMedium from "components/Spinner/Medium";
import Button from "components/Form/Button";
import { useTranslation } from "utils/translate";

const MemberPurchaseViewer = ({ externalData = null, closeModal = () => { } }) => {
    const { tr } = useTranslation();
    const [tableData, setTableData] = useState(null);
    useEffect(() => {
        if (_.isNil(externalData)) {
            return;
        }
        const submissionId = externalData?.submission_id;
        fetchPurchaseInfo(submissionId);
    }, [externalData])

    const fetchPurchaseInfo = async (submissionId) => {
        try {
            // GET /purchases/{id}
            const ret = await memberClient.get(`/public/purchases/${submissionId}/details`);
            console.log("[fetchPurchaseInfo] ret", ret);
            const transposed = transposeOne(ret.data, ["_id", "submission_id", "user_id", "clinic_id", "payment_method", "financial_fee", "financial_fee_type", "note", "memo", "aftercare_type_id", "earned_point", "used_point"]);
            const labeled = labelKeysWithColumnInfo(transposed, columnInfos);
            setTableData(labeled);
        } catch (e) {
            setTableData([])
        }
    }

    const detailTableColumnInfos = [
        {
            field: "label",
            label: tr("항목"),
            render: (value, field, row) => {
                return tr(value);
            },
            visible: true,
        },
        {
            field: "value",
            label: tr("내용"),
            visible: true,
            render: (value, field, row) => {
                if (row.key === "purchase_at") {
                    return formatDate(value);
                } else if (row.key === "items") {
                    return row.value.map((item) => <div>{formatJpProductName(item)}</div>);
                }
                return tr(value)
            }
        },
        {
            field: "key",
            label: "key",
            visible: false,
        }
    ]

    return (
        <div>
            <h1 className="font-semibold mb-1">{tr("구매정보")}</h1>
            {_.isNil(tableData) ? <SpinnerMedium /> :
                <SimpleTable data={tableData} columns={detailTableColumnInfos} isStickyFirstCol={true} />
            }
            <div className="flex justify-end">
                <div>
                    <Button onButtonClicked={closeModal}>
                        {tr("닫기")}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MemberPurchaseViewer;
