import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";

export const columnInfos: Column[] = [
    {
        field: "clinic_id",
        label: "의원ID",
        visible: false,
    },
    {
        field: "name",
        label: "의원명",
        visible: true,
        creatable: true,
        editable: true
    },
    {
        field: "name_jp",
        label: "일본어 의원명",
        visible: true,
        creatable: true,
        editable: true
    },
    {
        field: "created_at",
        label: "생성일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: true,
        render: formatDate
    },
    {
        field: "global_name",
        label: "글로벌 이름",
        visible: false
    },
    {
        field: "order",
        label: "순서",
        visible: false
    }
];