import React, { useState, useCallback, ReactNode } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { ChevronUpDownIcon, Bars2Icon } from "@heroicons/react/20/solid";
import { Item, DragListProps } from "../types";
import { classNames } from "components/uiUtils";
// Define the item type

// fake data generator
const getItems = (count: number): Item[] =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "lavenderblush" : "white",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? "aliceblue" : "transparent",
    // padding: grid,
    width: '100%'
});

const VerticalDragList = ({ itemsExternal, setItemsExternal, isGripTop = true }: DragListProps) => {
    const [internalItems, setInternalItems] = useState<Item[]>(getItems(10));

    // Determine whether to use external or internal state
    const items = itemsExternal || internalItems;
    const setItems = setItemsExternal || setInternalItems;

    const onDragEnd = useCallback((result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    }, [items, setItems]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(outterProvided, outterSnapshot) => (
                    <div
                        className="rounded-md"
                        {...outterProvided.droppableProps}
                        ref={outterProvided.innerRef}
                        style={getListStyle(outterSnapshot.isDraggingOver)}
                    >
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <>
                                        <div
                                            className={classNames("mb-2 mt-[5px] border border-solid border-gray-300 rounded-[9px] shadow-lg",
                                                isGripTop ? "flex flex-col justify-content-" : "flex"
                                            )}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            // {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div
                                                className={classNames("flex justify-center items-center bg-indigo-200 cursor-move",
                                                    isGripTop ? "w-full rounded-t-lg py-px" : "w-[15px] rounded-l-md")}
                                                {...provided.dragHandleProps}>
                                                {isGripTop ?
                                                    <Bars2Icon className="size-5 text-gray-500" /> :
                                                    <ChevronUpDownIcon className="size-5 text-gray-500" />
                                                }
                                            </div>
                                            <div className="w-full">
                                                {item.content}
                                            </div>
                                            {item.sub_items && !item.hideSubItems && (
                                                <div className="px-3 md:px-4">
                                                    <VerticalDragList
                                                        itemsExternal={item.sub_items}
                                                        setItemsExternal={(sub_items) => {
                                                            const newItems = items.map((i) => {
                                                                if (i.id === item.id) {
                                                                    return {
                                                                        ...i,
                                                                        sub_items
                                                                    };
                                                                }
                                                                return i;
                                                            });
                                                            setItems(newItems);
                                                        }} />
                                                </div>
                                            )}
                                        </div>

                                    </>
                                )}
                            </Draggable>
                        ))}
                        {outterProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default VerticalDragList;
