import { Column } from "components/Table/Simple";
import _ from 'lodash';
import hetToRgb from 'hex-rgb';

const rgbToRgbaString = (rgb, opacity) => {
    return `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${opacity})`;
}

export const convertClinicIdColumnSelectable = (columnInfos: Column[], clinicInfos: any[]): Column[] => {
    const newColumnInfos = columnInfos.map(info => {
        if (info.field === "clinic_id") {
            return {
                ...info,
                label: "의원",
                render: (value) => {
                    if(_.isNil(clinicInfos)){
                        return value;
                    }
                    const clinic = clinicInfos.find((elm) => elm.clinic_id === value);
                    return clinic?.name ?? value;
                },
                selectable: {
                    getOptions: () => {
                        return clinicInfos.map((elm) => ({ value: elm.clinic_id, name: elm.name }));
                    }
                }
            }
        } else {
            return info;
        }
    })
    return newColumnInfos;
}

export const createRowInfoWithClinic = (clinicInfos) => {
    return {
        rowStyle: (row) => {
            if(_.isNil(clinicInfos)){
                return {} as React.CSSProperties;
            }
            const clinicInfo = clinicInfos.find((elm) => elm.clinic_id === row.clinic_id);
            if (_.isNil(clinicInfo) || _.isNil(clinicInfo.clinic_color)) {
                return {} as React.CSSProperties;
            }
            const rgb = hetToRgb(clinicInfo.clinic_color);
            return {
                backgroundColor: rgbToRgbaString(rgb, 0.1),
            }
        }
    }
}
