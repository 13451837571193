import { Routes, Route } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";
import AdminRoute from "containers/SiteRoute/Admin";
import MemberRoute from "containers/SiteRoute/Member";
import AccountRoute from "containers/SiteRoute/Account";

const AdminSiteRoute = () => {
    return (
        <Routes>
            <Route path="/*" element={<MainRoute />}></Route>
            <Route path="/admin/*" element={<AdminRoute />}></Route>
            <Route path="/member/*" element={<MemberRoute />}></Route>
            <Route path="/account/*" element={<AccountRoute />}></Route>
        </Routes>
    )
}

export default AdminSiteRoute;