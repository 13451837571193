import React, { useEffect, useState } from "react";
import { useModal } from "components/Modal/Simple";
import { tryParseDt } from "components/uiUtils";
import { SimpleDatePicker } from "components/Form/Datepicker";
import Button from "components/Form/Button";

export const SimpleDateEditForm = ({ externalData = null, closeModal = () => { }, resolveRef = null }) => {
    const [dt, setDt] = useState(tryParseDt(externalData.initialDt));
    const [isProcessing, setIsProcessing] = useState(false);

    const onSubmit = () => {
        setIsProcessing(true);
        try {
            resolveRef.current(dt);
        } catch (e) {
            console.error("[SimpleDateEditForm] onSubmit", e);
        } finally {
            setIsProcessing(false);
        }
        closeModal();
    }

    return (
        <div className="flex flex-col space-y-2">
            <h1 className="text-lg">{externalData.message}</h1>
            <SimpleDatePicker selectedDt={dt} onChange={setDt}></SimpleDatePicker>
            <div className="flex justify-end">
                <div>
                    <Button onButtonClicked={onSubmit} disabled={isProcessing}>입력</Button>
                </div>
            </div>
        </div>
    )
}

export const useDateDialog = () => {
    const { Modal, openModalSync } = useModal();

    const openDialog = async (message, initialDt = new Date()): Promise<Date> => {
        return await openModalSync({ message, initialDt });
    }

    return {
        DialogProvider: () =>
            <Modal>
                <SimpleDateEditForm />
            </Modal>,
        openDialog
    }

}