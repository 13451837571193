import React, { useEffect, useMemo, useState } from 'react';
import Input, { InputFormat } from 'components/Form/Input';
import { SimpleDatePicker } from 'components/Form/Datepicker';
import StackedCard from 'components/Form/Radio/StackedCard';
import Textarea from 'components/Form/Textarea';
import _ from 'lodash';
import { Question, QuestionType } from 'containers/Questions/Question/types';
import { Option } from 'pages/Admin/Intake/Editor/ChoiceMaker';
import { SimpleTimePicker } from 'components/Form/Datepicker/Time';
import moment from 'moment-timezone';
import BasicArticle from 'components/Article/Basic';
import { useTranslation } from 'utils/translate';

export interface QuestionProps {
    question: Question;
    currentAnswer: string | string[] | Date;
    onAnswerChanged?: (quetion_id: string, text: string | string[] | Date) => void;
}

export interface GeneralInputProps {
    question: Question;
    onInputChanged: (text: string | string[] | Date) => void;
    currentValue: string | string[] | Date;
}

function getValidDate(currentValue: string | string[] | Date): Date {
    if (currentValue instanceof Date) {
        return currentValue;
    }

    try {
        if (typeof currentValue === 'string' && currentValue.trim() !== "") {
            const parsedDate = new Date(currentValue);
            if (!isNaN(parsedDate.getTime())) { // Check if parsedDate is a valid date
                return parsedDate;
            }
        }
    } catch (e) {
        console.error(e);
    }

    return null
}

function getValidTime(currentValue: string | string[] | Date): Date {
    if (currentValue instanceof Date) {
        return currentValue;
    }

    try {
        if (typeof currentValue === 'string' && currentValue.trim() !== "") {
            // parse h:mm A format
            const parsedDate = moment(currentValue, "h:mm A").toDate();
            if (!isNaN(parsedDate.getTime())) { // Check if parsedDate is a valid date
                return parsedDate;
            }
        }
    } catch (e) {
        console.error(e);
    }

    return null; // Default case for empty strings or invalid date
}

export const GeneralInput = ({ question, currentValue, onInputChanged }: GeneralInputProps) => {
    const { tr } = useTranslation();
    const items = useMemo(() => {
        if (question.options) {
            return question.options.map((option) => ({ name: option.text, id: option.option_id }))
        }
        return []
    }, [question.options])

    switch (question.type) {
        case QuestionType.SHORT_ANSWER:
            return (
                <Input value={String(currentValue ?? "")} onChange={onInputChanged} placeholder={question.placeholder ?? ""} format={InputFormat[question.format]}></Input>
            )
        case QuestionType.LONG_ANSWER:
            return (
                <Textarea value={String(currentValue ?? "")} onChange={onInputChanged}></Textarea>
            )
        case QuestionType.SINGLE_CHOICE:
            return (
                <StackedCard items={items} onItemChanged={onInputChanged} externalNames={[String(currentValue)]}></StackedCard>
            )
        case QuestionType.MULTIPLE_CHOICE:
            return (
                <StackedCard items={items} onItemChanged={onInputChanged} multiSelect={true} externalNames={currentValue instanceof Array ? currentValue : []}></StackedCard>
            )
        case QuestionType.DATE:
            return (
                <SimpleDatePicker
                    selectedDt={getValidDate(currentValue)}
                    onDtChanged={onInputChanged}
                    onClick={() => { }}
                    placeholderText={tr("날짜를 입력해 주세요")}
                ></SimpleDatePicker>
            )
        case QuestionType.TIME:
            return (
                <SimpleTimePicker
                    selectedDt={getValidTime(currentValue)}
                    onDtChanged={(dt: Date) => { onInputChanged(moment(dt).format("h:mm A")) }}
                    onClick={() => { }}
                    placeholderText={tr("시간을 입력해 주세요")}
                ></SimpleTimePicker>
            )
        default:
            return (
                <Input value={String(currentValue)} onChange={onInputChanged}></Input>
            )
    }
}

const QuestionContainer = ({ question, currentAnswer, onAnswerChanged, answers, questionNumber }) => {
    const renderConditionalQuestions = (conditionalQuestions, currentAnswer, parentNumber) => {
        return conditionalQuestions.map((cq, index) => {
            const conditionAnswer = question.options.find((option: Option) => option.option_id === cq.condition)?.text;
            console.log("[renderConditionalQuestions] conditionAnswer", conditionAnswer, "currentAnswer", currentAnswer)
            if (_.isNil(currentAnswer)) {
                return null;
            }
            if (currentAnswer === conditionAnswer || (currentAnswer instanceof Array && currentAnswer.includes(conditionAnswer))) {
                const cqNumber = `${parentNumber}-${index + 1}`;
                return (
                    <QuestionContainer
                        key={cq.question_id}
                        question={cq}
                        currentAnswer={answers[cq.question_id]}
                        onAnswerChanged={onAnswerChanged}
                        answers={answers}
                        questionNumber={cqNumber}
                    />
                );
            }
            return null;
        });
    };

    return (
        <div>
            <p className="mt-4 text-sm leading-6 text-gray-600">
                <div className="flex">
                    <div>
                        <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-700 me-1 text-nowrap">
                            {questionNumber}
                        </span>
                    </div>
                    <div className="w-full">
                        <BasicArticle>
                            {question.text}
                        </BasicArticle>
                    </div>
                </div>
            </p>
            <div className="mt-2">
                <GeneralInput
                    question={question}
                    currentValue={currentAnswer}
                    onInputChanged={(text) => { onAnswerChanged(question.question_id, text); }}
                />
            </div>
            {question.conditional_questions && renderConditionalQuestions(question.conditional_questions, currentAnswer, questionNumber)}
        </div>
    );
};

export default QuestionContainer;