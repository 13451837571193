import _ from 'lodash';
export const confirmDelete = (message = "") => {
    const txt = window.prompt(`${message}${`${_.isEmpty(message)? "": "\n"}`}정말로 삭제하시겠습니까? (삭제하려면 '삭제합니다'를 입력해주세요.)`);
    if (_.isNil(txt)) {
        window.alert("삭제가 취소되었습니다.");
        return false;
    }
    if (txt !== "삭제합니다") {
        window.alert("삭제문구가 일치하지 않습니다.");
        return false;
    }
    return true;
}
