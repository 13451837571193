import React, { useEffect, useState } from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import { adminClient } from "apis/admin";
import _ from "lodash";
import { flattenQuestions, parseSubmissionDetails } from "pages/Admin/Task/TranslateSubmission/Editor";
import SimpleTable from "components/Table/Simple";
import { columnInfos } from "pages/Admin/Task/TranslateSubmission/Editor/config";
import Card from "components/Card";
import Quill from "quill";
import { assembleQuillContentsWithFields } from "pages/Admin/Clinic/PrescriptionTemplate/Editor/helper";
import QuillMadeArticle from "components/Article/QuillMade";
import Button from "components/Form/Button";
import SpinnerMedium from "components/Spinner/Medium";
import Textarea from "components/Form/Textarea";
import { CustomerState } from "../config";

export const fetchAndCreateSubmissionDetailTableData = async (submissionId) => {
    try {
        const params = {
            lang: "jp"
        }
        const ret = await adminClient.get(`/submissions/${submissionId}/details`, { params });
        const { intake } = ret.data;
        if (_.isNil(intake)) return [];
        const tempTableDataBySection = [];
        intake.sections.forEach((section) => {
            const flatQ = flattenQuestions(section.questions);
            const tempTableData = parseSubmissionDetails(flatQ, ret.data);
            tempTableDataBySection.push({
                section_id: section.section_id,
                section_title: section.name,
                data: tempTableData
            });
        })
        return tempTableDataBySection;
    } catch (e) {
        console.log("[fetchAndCreateSubmissionDetailTableData] error", e)
    }
}

export const parseSubmissionDetailTableData = (details) => {
    const { intake } = details;
    if (_.isNil(intake)) return [];
    const tempTableDataBySection = [];
    intake.sections.forEach((section) => {
        const flatQ = flattenQuestions(section.questions);
        const tempTableData = parseSubmissionDetails(flatQ, details);
        tempTableDataBySection.push({
            section_id: section.section_id,
            section_title: section.name,
            data: tempTableData
        });
    })
    return tempTableDataBySection;
}

export const fetchAndCreatePrescriptionDetailHtmls = async (submissionId) => {
    const quill = new Quill(document.createElement("div"));
    try {
        const ret = await adminClient.get(`/prescriptions/${submissionId}/details`);
        console.log("[fetchAndCreatePrescriptionDetailHtml] ret", ret);
        const { contents, translated_contents, fields } = ret.data;
        const originContent = assembleQuillContentsWithFields(contents, fields, false);
        const translatedContent = assembleQuillContentsWithFields(translated_contents, fields, true);
        quill.setContents(originContent);
        const originHtml = quill.getSemanticHTML();
        quill.setContents(translatedContent);
        const translatedHtml = quill.getSemanticHTML();
        return {
            originHtml,
            translatedHtml
        }
    } catch (e) {
        console.log("[fetchAndCreatePrescriptionDetailHtml] error", e)
    }
}

export const DetailViewerContent = ({ submissionId, onClose = () => { } }) => {
    const [submissionDetailTableDataBySection, setSubmissionDetailTableDataBySection] = useState([]);
    const [comment, setComment] = useState("");
    const [originHtml, setOriginHtml] = useState("");
    const [translatedHtml, setTranslatedHtml] = useState("");

    useEffect(() => {
        if (_.isNil(submissionId)) {
            return;
        }
        updateSubmissionDetail();
    }, [submissionId])

    const updateSubmissionDetail = async () => {
        try {
            const ret = await adminClient.get(`/submissions/${submissionId}/details`, { params: { lang: "jp" } });
            console.log("!!! RET", ret);
            const tempTableDataBySection = parseSubmissionDetailTableData(ret.data);
            console.log("[updateSubmissionDetail] tempTableDataBySection", tempTableDataBySection);
            if (_.isNil(tempTableDataBySection)) {
                console.log("[updateSubmissionDetail] tempTableDataBySection is null");
                return;
            }
            const { submission, state } = ret.data;
            if (!_.isNil(submission?.comment)) {
                setComment(submission?.comment);
            }
            setSubmissionDetailTableDataBySection(tempTableDataBySection);
            if ([CustomerState.AWAITING_SUBMISSION_TRANSLATION, CustomerState.AWAITING_PRESCRIPTION, CustomerState.PURCHASE_FAILURE].includes(CustomerState[state])) {
                return;
            }
            // return;
            const htmls = await fetchAndCreatePrescriptionDetailHtmls(submissionId);
            if (_.isNil(htmls)) {
                return;
            }
            console.log("[updateSubmissionDetail] htmls", htmls);
            setOriginHtml(htmls.originHtml);
            if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION_TRANSLATION) {
                return;
            }
            setTranslatedHtml(htmls.translatedHtml);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="flex flex-col space-y-2">
            <div>
                <h1 className="font-semibold">
                    응답지
                </h1>
                {_.isEmpty(submissionDetailTableDataBySection) ? <SpinnerMedium /> :
                    <div>
                        <Card>
                            <div className="p-2">
                                {submissionDetailTableDataBySection.map((section) => (
                                    <div key={section.section_id}>
                                        <h2 className="text-sm font-semibold">{section.section_title}</h2>
                                        <SimpleTable data={section.data} columns={columnInfos}></SimpleTable>
                                    </div>
                                ))}
                                {!_.isEmpty(comment) &&
                                    <div>
                                        <h1 className="text-sm font-semibold">
                                            주석
                                        </h1>
                                        <div className="">
                                            <Textarea value={comment} disabled={true} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                }
            </div>
            {!_.isEmpty(originHtml) &&
                <div>
                    <h1 className="font-semibold">
                        처방전 원문
                    </h1>
                    <Card>
                        <div className="p-2">
                            <QuillMadeArticle htmlText={originHtml}></QuillMadeArticle>
                        </div>
                    </Card>
                </div>
            }
            {!_.isEmpty(translatedHtml) &&
                <div>
                    <h1 className="font-semibold">
                        처방전 번역본
                    </h1>
                    <Card>
                        <div className="p-2">
                            <QuillMadeArticle htmlText={translatedHtml}></QuillMadeArticle>
                        </div>
                    </Card>
                </div>
            }
            <div className="flex justify-end">
                <div hidden={_.isEmpty(submissionDetailTableDataBySection)}>
                    <Button onButtonClicked={() => { onClose() }}>
                        닫기
                    </Button>
                </div>
            </div>
        </div>
    )
}

const DetailViewer = ({ value, row }) => {
    const { Modal: DetailModal, openModal: openDetailModal, closeModal: closeDetailModal } = useModal();
    const [submissionDetailTableDataBySection, setSubmissionDetailTableDataBySection] = useState([]);
    const [originHtml, setOriginHtml] = useState("");
    const [translatedHtml, setTranslatedHtml] = useState("");
    const [submissionId, setSubmissionId] = useState(null);


    const onButtonClicked = async () => {
        console.log("onButtonClicked");
        // await updateSubmissionDetail(row.submission_id);
        setSubmissionId(row.submission_id);
        openDetailModal();
    }

    return (
        <>
            <button className="align-sub" onClick={onButtonClicked}>
                <DocumentTextIcon className="size-4 align-center p-0" />
            </button>
            <DetailModal>
                <DetailViewerContent submissionId={submissionId} onClose={() => { closeDetailModal() }}></DetailViewerContent>
            </DetailModal>
        </>
    )
}

export default DetailViewer;
