import React, { useEffect, useState } from "react";
import Card from "components/Card";
import CRUDTable from "components/Table/CRUD";
import _ from "lodash";
import { UserType } from "../types";
import { columnInfos } from "./config";
import { toast } from "react-toastify";
import { adminClient, AdminClient } from "apis/admin";
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from "pages/Admin/Clinic/helper";

import { RowInfo } from "components/Table/Simple";
import { postResetPassword } from "../Staff";
import PageTitle from "components/Title/Page";
import { confirmDelete } from "utils/browser";

const DoctorUserPage = () => {
    const [tableData, setTableData] = useState([]);
    const [clinicInfos, setClinicInfos] = useState([]);
    useEffect(() => {
        updateUserData();
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        });
    }, [])

    const updateUserData = async () => {
        try {
            const params = {
                user_type: UserType.DOCTOR,
                page: 1,
                limit: 100
            }
            const ret = await adminClient.get(`/users`, { params });
            console.log(ret);
            const tempTableData = ret.data.data;
            setTableData(tempTableData.map((user) => (_.omit(user, ["_id", 'password', "global_name"]))));
        } catch (e) {
            console.log("[DoctorUserPage] updateUserData Error", e)
        }
    }

    const onCreateRequested = async (data) => {
        console.log("[DoctorUserPage] onCreateRequested", data);
        try {
            if (String(data.user_id).startsWith("_")) {
                toast.error("사용자 아이디는 _ 로 시작할 수 없습니다.");
                return false;
            }
            data.user_type = UserType.DOCTOR;
            data.password = "0000";
            data.password_reset_required = true;
            const ret = await adminClient.post(`/users`, data);
            toast.success(`사용자 ${data.user_id}가 추가되었습니다.`);
            console.log("[DoctorUserPage] onCreateRequested Ret", ret);
            updateUserData();
        } catch (e) {
            console.error(e);
            return false;
        }
        return true;
    }

    const onEditRequested = async (data) => {
        console.log("[DoctorUserPage] onEditRequested", data);
        if (data.password_reset_required === true) {
            const ok = window.confirm("비밀번호를 초기화 하시겠습니까?");
            if(!ok) return;
            await postResetPassword(data.user_id);
        }
        try {
            const ret = await adminClient.put(`/users/${data.user_id}`, _.omit(data, ["updated_at", "created_at"]));
            toast.success(`사용자 ${data.user_id}의 정보가 수정되었습니다.`);
            console.log("[DoctorUserPage] onEditRequested Ret", ret);
            updateUserData();
        } catch (e) {
            console.error(e);
            return false;
        }
        return true;
    };

    const onDeleteRequested = async (data) => {
        console.log("[DoctorUserPage] onDeleteRequested", data);
        try {
            const ok = confirmDelete(`사용자 ${data.user_id} 삭제를 진행합니다.`);
            if (!ok) {
                return false;
            }
            const ret = await adminClient.delete(`/users/${data.user_id}`);
            toast.success(`사용자 ${data.user_id}가 삭제되었습니다.`);
            console.log("[DoctorUserPage] onDeleteRequested Ret", ret);
            updateUserData();
        } catch (e) {
            console.error(e);
            return false;
        }
        return true;
    }

    // replace clinic_id
    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfos, clinicInfos);
    const rowInfoWithClinic: RowInfo = createRowInfoWithClinic(clinicInfos);

    return (
        <Card>
            <div className="p-3">
                <PageTitle>의료진정보 관리</PageTitle>
                <CRUDTable data={tableData} columns={columnInfosWithClinic} rowInfo={rowInfoWithClinic} onEdit={onEditRequested} onCreate={onCreateRequested} onDelete={onDeleteRequested}></CRUDTable>
            </div>
        </Card>
    )
}

export default DoctorUserPage;
