import React, { useEffect, useState } from "react";
import Card from "components/Card";
import SimpleTable from "components/Table/Simple";
import { columnInfos } from "./config";
import _ from "lodash";
import CRUDTable from "components/Table/CRUD";
import { adminClient, AdminClient } from "apis/admin";
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from "pages/Admin/Clinic/helper";
import { createNewIntake } from "../Editor/helpers";
import { toast } from "react-toastify";
import PageTitle from "components/Title/Page";
import { useNavigate } from "react-router-dom";
import { GlobeAsiaAustraliaIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { confirmDelete } from "utils/browser";

const IntakeListPage = () => {
    const [tableData, setTableData] = useState([]);
    const [clinicInfos, setClinicInfos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        updateIntakesData();
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        });
    }, [])

    const updateIntakesData = async () => {
        try {
            const ret = await adminClient.get(`/intakes/distinct/latest`);
            console.log(ret);
            const tempTableData = ret.data;
            setTableData(tempTableData.map((elm) => (_.omit(elm, ["_id"])))
                .filter((elm) => elm.clinic_id !== "COMMON")
            );
        } catch (e) {

        }
    }

    const onCreate = async (data) => {
        const ok = window.confirm(`${data.title}을 추가하시겠습니까?`);
        if (!ok) return false;
        try {
            const newIntake = createNewIntake(data);
            const ret = await adminClient.post(`/intakes`, newIntake);
            toast.success("새로운 진료지가 추가되었습니다.");
            console.log("[onCreate]", ret);
            updateIntakesData();
            return true;
        } catch (e) {
            toast.error("새로운 진료지 추가에 실패했습니다.");
            console.error(e);
            return false;
        }
    }

    const onEdit = async (data) => {
        console.log("[onEdit]", data)
        const ok = window.confirm(`${data.title}을 수정하시겠습니까?`);
        if (!ok) return false;
        try {
            const intakeRet = await adminClient.get(`/intakes/${data.intake_id}`);
            const intakeData = {
                ..._.omit(data, ["intake_version"]),
                sections: intakeRet.data.sections
            }
            const ret = await adminClient.post(`/intakes`, intakeData);
            toast.success("진료지가 수정되었습니다");
            console.log("[onEdit]", ret);
            updateIntakesData();
            return true;
        } catch (e) {
            console.error(e);
            toast.error("진료지 수정에 실패했습니다.");
            return false;
        }
    }

    const onDelete = async (data) => {
        console.log("[onDelete]", data);
        const ok = confirmDelete(`${data.title} 삭제를 진행합니다.`);
        if (!ok) return false;
        try {
            const ret = await adminClient.delete(`/intakes/${data.intake_id}`);
            console.log("[onDelete]", ret);
            toast.success("진료지가 삭제되었습니다.");
            updateIntakesData();
            return true;
        } catch (e) {
            console.error(e);
            toast.error("진료지 삭제에 실패했습니다.");
            return false;
        }
    }

    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfos, clinicInfos);
    const rowInfoWithClinic = createRowInfoWithClinic(clinicInfos);

    const columnInfosWithActions = [
        {
            field: "edit",
            label: "수정",
            render: (value, field, row) => (
                <div className="flex justify-center cursor-pointer" onClick={()=>{navigate(`/admin/intake/editor/${row.intake_id}`)}}>
                    <PencilSquareIcon className="size-4 align-center"></PencilSquareIcon>
                </div>
            )
        },
        {
            field: "translate",
            label: "번역",
            render: (value, field, row) => (
                <div className="flex justify-center cursor-pointer" onClick={()=>{navigate(`/admin/intake/translate/${row.intake_id}`)}}>
                    <GlobeAsiaAustraliaIcon className="size-4 align-center"></GlobeAsiaAustraliaIcon>
                </div>
            )
        },
        ...columnInfosWithClinic
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>진료지 목록</PageTitle>
                <CRUDTable data={tableData} columns={columnInfosWithActions} rowInfo={rowInfoWithClinic} onCreate={onCreate} onEdit={onEdit} onDelete={onDelete}></CRUDTable>
            </div>
        </Card>
    )
}

export default IntakeListPage;