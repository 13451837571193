import React from "react";
import GeneralTaskList, { RangePickerOption } from "containers/Task/GeneralTaskList";
import { columnInfos } from "containers/Task/GeneralTaskList/config";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import Button from "components/Form/Button";
import { useNavigate } from "react-router-dom";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import IconButton from "components/Form/Button/Icon";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import PurchaseViewer from "pages/Admin/Customer/Status/PurchaseViewer";

const RANGE_PICKER_OPTIONS: RangePickerOption[] = [
    {
        label: "모든 날짜",
        field: null
    },
    {
        label: "구매대기시작일",
        field: "awaiting_at"
    }
]
const InputPurchaseInfo = () => {
    const navigate = useNavigate();
    const states: (keyof typeof CustomerState)[] = ["AWAITING_CUSTOMER_PAYMENT"]
    const { Modal: PurchaseViewerModal, openModal: openPurchaseViewerModal } = useModal();
    const columnInfosWithTask = [
        {
            field: "task",
            label: "작업",
            visible: true,
            render: (value, field, row) =>
                <IconButton
                    paddingClassName="px-2 py-1"
                    onButtonClicked={() => {
                        navigate(`/admin/task/input-purchase-info/editor/${row.submission_id}`)
                    }}
                >
                    <div className="text-xs">
                        구매정보 입력
                    </div>
                </IconButton>,
            align: "center"
        },
        ...columnInfos?.map((info) => {
            // if (info.field === "purchase_detail") {
            //     return {
            //         ...info,
            //         render: (value, field, row) => <CalculatorIcon className="size-4 cursor-pointer" onClick={() => { openPurchaseViewerModal(row) }}></CalculatorIcon>,
            //         visible: true,
            //         align: "center"
            //     }
            // }
            if ([
                "purchase_at",
                "shipping_address",
                "deliver_request_at",
                "shipping_company",
                "tracking_number",
                "tracking_shipping_link",
                "deliver_at",
                "delivered_at",
                "nudge"
            ].includes(info.field)) {
            return {
                ...info,
                visible: false
            }
        }
            return info;
        })
    ]

    return (
        <>
            <Card>
                <div className="p-3">
                    <PageTitle>구매정보 입력</PageTitle>
                    <GeneralTaskList states={states} columnInfos={columnInfosWithTask} rangePickerOptions={RANGE_PICKER_OPTIONS}></GeneralTaskList>
                </div>
            </Card>
            <PurchaseViewerModal>
                <PurchaseViewer/>
            </PurchaseViewerModal>
        </>
    )
}

export default InputPurchaseInfo;