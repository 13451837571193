import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "components/Card";
import CRUDTable from "components/Table/CRUD";
import _ from "lodash";
import { UserType } from "../types";
import { columnInfos } from "./config";
import { toast } from "react-toastify";
import { adminClient } from "apis/admin";
import PageTitle from "components/Title/Page";
import { confirmDelete } from "utils/browser";

export const postResetPassword = async (userId) => {
    try {
        const NEW_PASSWORD = "0000";
        const body = {
            user_id: userId,
            new_password: NEW_PASSWORD
        }
        const ret = await adminClient.post(`/users/reset-password`, body);
        console.log("[postResetPassword] ret", ret)
        toast.success(`비밀번호가 ${NEW_PASSWORD} 초기화 되었습니다.`);
        return true;
    } catch (e) {
        toast.error("비밀번호 초기화 실패");
        console.error(e);
        return false;
    }
}

const StaffUserPage = () => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        updateUserData();
    }, [])

    const updateUserData = async () => {

        try {
            const params = {
                user_type: UserType.STAFF,
                page: 1,
                limit: 100
            }
            const ret = await adminClient.get(`/users`, { params });
            console.log(ret);
            const tempTableData = ret.data.data;
            setTableData(tempTableData.map((user) => (_.omit(user, ["_id", 'password', "global_name"]))));
        } catch (e) {

        }
    }

    const onCreateRequested = async (data) => {
        console.log("[DoctorUserPage] onCreateRequested", data);
        try {
            if (String(data.user_id).startsWith("_")) {
                toast.error("사용자 아이디는 _ 로 시작할 수 없습니다.");
                return false;
            }
            data.user_type = UserType.STAFF;
            data.password = "0000";
            data.password_reset_required = true;
            const ret = await adminClient.post(`/users`, data);
            toast.success(`사용자 ${data.user_id}가 추가되었습니다.`);
            console.log("[DoctorUserPage] onCreateRequested Ret", ret);
            updateUserData();
        } catch (e) {
            toast.error(`사용자 ${data.user_id} 추가 실패`);
            console.error(e);
            return false;
        }
        return true;
    }

    const onEditRequested = async (data) => {
        console.log("[DoctorUserPage] onEditRequested", data);
        if (data.password_reset_required === true) {
            const ok = window.confirm("비밀번호를 초기화 하시겠습니까?");
            if (!ok) return;
            await postResetPassword(data.user_id);
        }
        try {
            const ret = await adminClient.put(`/users/${data.user_id}`, _.omit(data, ["updated_at", "created_at"]));
            toast.success(`사용자 ${data.user_id}가 수정되었습니다.`);
            console.log("[DoctorUserPage] onEditRequested Ret", ret);
            updateUserData();
        } catch (e) {
            toast.error(`사용자 ${data.user_id} 수정 실패`);
            console.error(e);
            return false;
        }
        return true;
    }

    const onDeleteRequested = async (data) => {
        console.log("[DoctorUserPage] onDeleteRequested", data);
        try {
            const ok = confirmDelete(`사용자 ${data.user_id} 삭제를 진행합니다.`);
            if (!ok) {
                return false;
            }
            const ret = await adminClient.delete(`/users/${data.user_id}`);
            toast.success(`사용자 ${data.user_id}가 삭제되었습니다.`);
            console.log("[DoctorUserPage] onDeleteRequested Ret", ret);
            updateUserData();
        } catch (e) {
            toast.error(`사용자 ${data.user_id} 삭제 실패`);
            console.error(e);
            return false;
        }
        return true;
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>직원정보 관리</PageTitle>
                <CRUDTable data={tableData} columns={columnInfos} onCreate={onCreateRequested} onEdit={onEditRequested} onDelete={onDeleteRequested}></CRUDTable>
            </div>
        </Card>
    )
}

export default StaffUserPage;