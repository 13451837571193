import React from "react";
import Button from "components/Form/Button";
import { useTranslation } from "utils/translate";
export default ({ title = "", description = "", onSubmit = () => { } }) => {
    const { tr } = useTranslation();

    return (
        <div className="mx-auto max-w-2xl mt-3 mb-3 p-4 bg-white">
            <div className="flex flex-col justify-between space-y-1 mb-4">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">{title}</h1>
                <p className="text-md text-gray-600">
                    {description}
                </p>
                <p className="text-md text-gray-600">
                    {tr("상담 시트를 기반으로 처방하기 때문에 확실히 정확하고 정직하게 답변해 주세요.")}
                </p>
            </div>
            <div className="flex justify-center">
                <Button onButtonClicked={onSubmit}>{tr("시작하기")}</Button>
            </div>
        </div>
    );
};
