import React, { useState, ForwardedRef, useEffect } from 'react';
import Card from 'components/Card';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import Input from 'components/Form/Input';
import { CustomDatePickerInput } from '..';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import InputUpperLabel from 'components/Form/Input/Label/InputUpper';
import { classNames } from 'components/uiUtils';

// const EXAMPLE_DTS = [new Date('1970-01-01'), new Date('1970-01-02')];

const YEARS = Array.from({ length: 80 }, (_, i) => new Date().getFullYear() - i);

export const renderYearHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <div className="flex justify-center items-center text-center">
        <button onClick={() => {
            changeYear(date.getFullYear() - 1)
        }} disabled={prevMonthButtonDisabled}>
            <ChevronLeftIcon className="h-8 w-8" />
        </button>
        <div className="text-base font-semibold px-1 w-fit">
            <select
                className="w-full p-0 border-none bg-transparent text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-sm"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
                style={{ backgroundImage: "none" }}
            >
                {YEARS.map((i) => (
                    <option key={i} value={i}>
                        {new Date(i, 6, 31).toLocaleString('default', { year: 'numeric' })}
                    </option>
                ))}
            </select>
        </div>
        <button onClick={() => {
            changeYear(date.getFullYear() + 1);
        }} disabled={nextMonthButtonDisabled}>
            <ChevronRightIcon className="h-8 w-8" />
        </button>
    </div>
);


export const SimpleDatePicker = ({ label = "", selectedDt = new Date(), onDtChanged = (dt: any) => { }, onClick = () => { }, ...otherProps },) => (
    <div className="flex rounded-md shadow-sm">
        {label &&
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm text-nowrap">
                {label}
            </span>
        }
        <div className={"w-full"} onClick={onClick}>
            <DatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={selectedDt}
                onChange={(date: Date) => { onDtChanged(date) }}
                showPopperArrow={false}
                className={classNames("w-full", "rounded-r-md", label || "rounded-l-md")}
                customInput={<CustomDatePickerInput />}
                renderCustomHeader={renderYearHeader}
                dropdownMode="select"
                dateFormatCalendar="YYYY-MM"
                {...otherProps}
            />
        </div>
    </div>
)

// from now to 2000-01-01 every month
const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

export default ({ upperLabel = "", onDtChanged = (dt: Date) => { }, ...otherProps }) => {
    const [selectedDt, setSelectedDt] = useState(TODAY);

    useEffect(() => {
    }, [])

    const handleFirstButtonClick = () => {
        const firstDate = new Date("2000-01-01");
        setSelectedDt(firstDate);
    }

    const handlePreviousButtonClick = () => {
        const prevDate = new Date(selectedDt);
        prevDate.setMonth(prevDate.getMonth() - 1);
        if (!prevDate) return;
        setSelectedDt(prevDate);
    }

    const handleNextButtonClick = () => {
        const nextDate = new Date(selectedDt);
        nextDate.setMonth(nextDate.getMonth() + 1);
        if (!nextDate) return;
        if(nextDate.getTime() > new Date().getTime()) return;
        setSelectedDt(nextDate);
    }

    const handleLastButtonClick = () => {
        const lastDate = new Date();
        lastDate.setDate(1)
        setSelectedDt(lastDate);
    }

    useEffect(() => {
        onDtChanged(selectedDt);
    }, [selectedDt])

    return (
        <div>
            <InputUpperLabel>
                {upperLabel}
            </InputUpperLabel>
            <div className="isolate inline-flex rounded-md shadow-sm z-20">
                <button
                    type="button"
                    className="relative -mr-px inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handleFirstButtonClick}
                >
                    <span className="sr-only">First</span>
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="relative -mr-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handlePreviousButtonClick}
                >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <DatePicker
                    dateFormat={"yyyy-MM"}
                    selected={selectedDt}
                    onChange={dt => setSelectedDt(dt)}
                    customInput={<CustomDatePickerInput />}
                    showPopperArrow={false}
                    showMonthYearPicker={true}
                    renderCustomHeader={renderYearHeader}
                    {...otherProps}
                ></DatePicker>
                <button
                    type="button"
                    className="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handleNextButtonClick}
                >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handleLastButtonClick}
                >
                    <span className="sr-only">Last</span>
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>

    )
}