import React from "react";
import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";
import MemberDetailViewer from "./MemberDetailViewer";

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "id",
        visible: false
    },
    {
        field: "details",
        label: "응답지",
        visible: true,
        render: (value, field, row) => <MemberDetailViewer value={value} row={row}></MemberDetailViewer>,
        align: "center"
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: false,
        // multiselect: true
    },
    {
        field: "submission_id",
        label: "응답지번호",
        align: "center",
        visible: false,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
        // multiselect: true
    },
    {
        field: "state",
        label: "상태",
        visible: true,
    },
    {
        field: "created_at",
        label: "응답지 제출일",
        visible: true,
        render: formatDate
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
]
