import React from "react"

const TitleBadge = ({ children }) => {
    return (
        <div className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 me-1 text-nowrap">
            {children}
        </div>
    )
}

export default TitleBadge;
