import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import Button from "components/Form/Button";

const ColorPickForm = ({ currentColor, onCancel = () => { }, onSave = (pickedColor) => { } }) => {
    const [pickedColor, setPickedColor] = useState<string>(currentColor ?? "#ffffff");

    useEffect(() => {
        console.log("[ColorPickForm] pickedColor", pickedColor)
        // onColorChange(pickedColor);
    }, [pickedColor])

    return (
        <div>
            <h1 className="text-lg font-semibold mb-1">색상설정</h1>
            <h1 className="text-sm font-semibold">현재 색상</h1>
            <div className="rounded-md m-2 ring-1 ring-gray-200 shadow-md" style={{
                backgroundColor: currentColor ?? "",
                height: "30px",
            }}></div>
            <h1 className="text-sm font-semibold">새 색상</h1>
            <div className="rounded-md m-2 ring-1 ring-gray-200 shadow-md" style={{
                backgroundColor: pickedColor,
                height: "30px",
            }}></div>
            <div className="p-3">
                <TwitterPicker color={pickedColor} onChange={(e) => { setPickedColor(e.hex); console.log(e.hex) }}></TwitterPicker>
            </div>
            <div className="flex justify-end space-x-2">
                <div>
                    <Button
                        onButtonClicked={onCancel}
                    >
                        취소
                    </Button>
                </div>
                <div>
                    <Button
                        onButtonClicked={() => { onSave(pickedColor) }}
                    >
                        저장
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ColorPickForm;