import React, { useState } from "react";
import Button from "components/Form/Button";
import Input from "components/Form/Input";
import IconButton from "components/Form/Button/Icon";
import { PlusIcon } from "@heroicons/react/20/solid";
import { TrashIcon, ArchiveBoxArrowDownIcon } from '@heroicons/react/24/outline';
import { randomString } from "utils/math";
import VerticalDragList from "components/Drag/VerticalList";

export type Option = {
    option_id: string;
    text: string;
};

export const generateNewOptionId = () => `opt${randomString()}`;

export const createNewOptionItem = (): Option => (
    { 
        text: "", 
        option_id: generateNewOptionId() 
    }
);

export interface OptionFormProps {
    item: Option;
    onInputChanged: (optionId: string, value: string) => void;
    onConditionalAddButtonClicked: (optionId: string) => void;
    onAddButtonClicked: (optionId: string) => void;
    onRemoveButtonClicked: (optionId: string) => void;
    removeButtonDisabled: boolean;
}
const OptionForm = ({
    item,
    onInputChanged = (optionId, value) => { },
    onConditionalAddButtonClicked = (optionId) => { },
    onAddButtonClicked = (optionId) => { },
    onRemoveButtonClicked = (optionId) => { },
    removeButtonDisabled = false
}: OptionFormProps) => {
    return (
        <>
            {/* {item.id} */}
            <div className="flex space-x-[4px] m-[4px]">
                <div className="w-full">
                    <Input
                        value={item.text}
                        onChange={(value) => onInputChanged(item.option_id, value)}
                        placeholder="선택지를 입력하세요"
                    />
                </div>
                <div className="">
                    <IconButton onButtonClicked={() => { onConditionalAddButtonClicked(item.option_id) }}>
                        <ArchiveBoxArrowDownIcon className="h-5 w-5 text-gray-500" />
                    </IconButton>
                </div>
                <div className="">
                    <IconButton onButtonClicked={() => { onAddButtonClicked(item.option_id) }}>
                        <PlusIcon className="h-5 w-5 text-gray-500" />
                    </IconButton>
                </div>
                <div className="">
                    <IconButton onButtonClicked={() => { onRemoveButtonClicked(item.option_id) }} disabled={removeButtonDisabled}>
                        <TrashIcon className="h-5 w-5 text-gray-500" />
                    </IconButton>
                </div>
            </div>
        </>
    )
}

const EXAMPLE_OPTION_ITEMS = [
    createNewOptionItem(),
    // createNewOptionItem(),
    // createNewOptionItem(),
]

export interface ChoiceMakerProps {
    questionId: string;
    listsExternal?: Option[];
    setListsExternal?: (lists: Option[]) => void;
    onConditionalAddButtonClicked?: (questionId: string, optionId: string, idx: number) => void;
    onOptionChanged?: (questionId: string, optionId: string, value: string) => void;
}

const ChoiceMaker = ({
    questionId,
    listsExternal = EXAMPLE_OPTION_ITEMS,
    setListsExternal = (lists) => { },
    onConditionalAddButtonClicked = (questionId, optionId, idx) => { },
    onOptionChanged = (questionId, optionId, value) => { }
}: ChoiceMakerProps) => {
    const [listsInternal, setListsInternal] = useState<Option[]>(EXAMPLE_OPTION_ITEMS);
    const lists = listsExternal || listsInternal;
    const setLists = listsExternal ? setListsExternal : setListsInternal;

    const onInputChanged = (optionId: string, value: string) => {
        const newItems = lists.map((list) => {
            if (list.option_id === optionId) {
                return { ...list, text: value }
            }
            return list;
        });
        setLists(newItems);
        onOptionChanged(questionId, optionId, value);
    }

    const onAddButtonClicked = (optionId: string) => {
        const newItem = createNewOptionItem();
        const newItems = lists.flatMap((list) => {
            if (list.option_id === optionId) {
                return [list, newItem];
            }
            return list;
        });
        setLists(newItems);
    }

    const onRemoveButtonClicked = (optionId: string) => {
        setLists(lists.filter((list) => list.option_id !== optionId));
    }

    const createItemWithContent = (item: Option, idx: number) => {
        return {
            id: item.option_id,
            content: (
                <OptionForm
                    item={item}
                    onInputChanged={onInputChanged}
                    onAddButtonClicked={onAddButtonClicked}
                    onRemoveButtonClicked={onRemoveButtonClicked}
                    onConditionalAddButtonClicked={(optionId) => onConditionalAddButtonClicked(questionId, optionId, idx)}
                    removeButtonDisabled={lists.length === 1}
                />
            )
        };
    };

    const onItemsChanged = (itemsWitContents) => {
        const newItems = itemsWitContents.map((itemWithContent) => {
            return lists.find((list) => list.option_id === itemWithContent.id);
        });
        setLists(newItems);
    }

    return (
        // <div className="w-full mb-3 border border-gray-200 rounded-md p-3">
        <VerticalDragList
            itemsExternal={lists.map(createItemWithContent)}
            setItemsExternal={onItemsChanged}
            isGripTop={false}
        />
        // </div>
    );
};

export default ChoiceMaker;