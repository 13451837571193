import moment from "moment-timezone";
export const delay = async (ms = 1000) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const tryParseDt = (dtStr: string) => {
    console.log("[tryParseDt] dtStr", dtStr);
    try {
        const dt = moment(dtStr).toDate();
        if (dt.toString() === "Invalid Date") {
            throw new Error("Invalid Date");
        }
        console.log("[tryParseDt] dt", dt);
        return dt;
    } catch (e) {
        return new Date();
    }
}

export const createDateArray = (start, end) => {
    const dateArray = [];
    let currentDate = end;

    dateArray.push(new Date(currentDate))
    while (currentDate >= start) {
        currentDate.setDate(currentDate.getDate() - 1); // 하루를 뺌
        dateArray.push(new Date(currentDate)); // 현재 날짜를 배열에 추가
    }

    return dateArray;
}