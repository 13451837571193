import React, { useEffect, useState } from "react";
import { memberClient } from "apis/member";
import PagingTable from "components/Table/Paging";
import Card from "components/Card";
import { columnInfos } from "./config";
import { useTranslation } from "utils/translate";
import { NewspaperIcon } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column } from "components/Table/Simple";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import { commonClient } from "apis/common";
import { classNames } from "components/uiUtils";
import PageTitle from "components/Title/Page";
import _ from "lodash";

export const MemberSubmission = () => {
    const navigate = useNavigate();
    const { tr, addTrs } = useTranslation();
    const [searchParams] = useSearchParams();
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        console.log("MemberSubmission");
        fetchIntakeTitkeTrs();
    }, []);

    const fetchTrackings = async (page, limit) => {
        try {
            const params = {
                page,
                limit
            }
            const ret = await memberClient.get("/public/trackings", { params });
            console.log(ret.data);
            // setTableData(ret.data.data);
            if (_.isNil(ret?.data?.data)) {
                return { data: [], page: 1, total: 0 }
            };
            const trackings = ret.data.data.map((elm) => _.pick(elm, ["submission_id", "intake_title", "state", "created_at"]));
            // const trackings = ret.data.data;
            setTableData(trackings);
            return {
                data: trackings,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchIntakeTitkeTrs = async () => {
        try {
            const params = {
                lang: searchParams.get("lang") ?? "jp"
            }
            const ret = await commonClient.get("/translations/intakes/title", { params });
            addTrs(ret.data);
        } catch (e) {

        }
    }

    const formatPublicStatus = (value) => {
        const state = value as CustomerState;
        if (CustomerState[state] === CustomerState.AWAITING_SUBMISSION_TRANSLATION) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION_TRANSLATION) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_NOTI) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_PAYMENT) return tr("구매대기중");
        if (CustomerState[state] === CustomerState.PAYMENT_COMPLETED) return tr("배송대기중");
        if (CustomerState[state] === CustomerState.SHIPPING) return tr("배송중");
        if (CustomerState[state] === CustomerState.DELIVERED) return tr("배송완료");
        if (CustomerState[state] === CustomerState.PURCHASE_FAILURE) return tr("구매진행중단");
        return -1;
    }

    const columnInfosWithPrescriptionButton: Column[] = [
        {
            field: "prescription",
            label: "처방전",
            visible: true,
            render: (value, field, row) => {
                const state = row.state;
                const isNotPrescriptionState = [CustomerState.AWAITING_SUBMISSION_TRANSLATION, CustomerState.AWAITING_PRESCRIPTION, CustomerState.AWAITING_PRESCRIPTION_TRANSLATION, CustomerState.AWAITING_CUSTOMER_NOTI, CustomerState.PURCHASE_FAILURE].includes(CustomerState[state]);
                // if (isNotPrescriptionState) return "";
                return (
                    <button
                        className={classNames("align-sub group",
                            isNotPrescriptionState ? "text-gray-300" : ""
                        )}
                        onClick={() => {
                            navigate(`/member/prescription/${row.submission_id}`);
                        }}
                        disabled={isNotPrescriptionState}
                    >
                        <div className="relative group">
                            {isNotPrescriptionState &&
                                <div className="invisible group-hover:visible">
                                    <span className='absolute -top-4d left-4 p-1 hover:visible text-black bg-white border'>
                                        {tr("처방전이 아직 작성되지 않았습니다.")}
                                    </span>
                                </div>
                            }
                            <NewspaperIcon className="size-4 align-center p-0" />
                        </div>
                    </button>
                )
            },
            align: "center"
        },
        ...columnInfos
    ]

    const translatedColumnInfos = columnInfosWithPrescriptionButton.map(info => {
        if (info.field === "state") {
            return {
                ...info,
                label: tr(info.label),
                render: formatPublicStatus
            }
        }
        if (info.field === "intake_title") {
            return {
                ...info,
                label: tr(info.label),
                render: (value) => {
                    return tr(value);
                }
            }
        }
        return {
            ...info,
            label: tr(info.label)
        }
    })

    return (
        <div>
            <Card>
                <div className="p-3">
                    <PageTitle>{tr("제출목록")}</PageTitle>
                    <PagingTable columns={translatedColumnInfos} fetchData={fetchTrackings} />
                </div>
            </Card>
            {/* WIP */}
            {/* <Card>
                <div className="p-3">
                    <PageTitle>{tr("제출목록")}</PageTitle>
                    <div className="mt-2">
                        {_.isNil(tableData) ? <SpinnerMedium /> :
                            <div className="flex flex-col space-y-6">
                                {tableData.map((tracking) => {
                                    return (
                                        <PurchaseStatusCard tracking={tracking} />
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </Card> */}
        </div>
    )
}

export default MemberSubmission;