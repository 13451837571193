/*
    PAYPAL = 'PAYPAL',
    PAYPAL_EKWANGDONG = 'PAYPAL_EKWANGDONG',
    PAYPAL_MIVIEW = 'PAYPAL_MIVIEW',
    CASH = 'CASH',
    NP = 'NP',
    ACCOUNT_LAYEON = 'ACCOUNT_LAYEON',
    ACCOUNT_DADOC = 'ACCOUNT_DADOC',
    ACCOUNT_EKWANGDONG = 'ACCOUNT_EKWANGDONG',
    ACCOUNT_MELINE = 'ACCOUNT_MELINE',
    ACCOUNT_OLIVE = 'ACCOUNT_OLIVE',
    ACCOUNT_CHAMKH = 'ACCOUNT_CHAMKH'
*/

export const PAYMENT_METHOD_ITEMS = [
    { value: "PAYPAL", name: "페이팔" },
    { value: "PAYPAL_EKWANGDONG", name: "페이팔(광동)" },
    { value: "PAYPAL_MIVIEW", name: "페이팔(미뷰)" },
    { value: "CASH", name: "현금" },
    { value: "NP", name: "은행(N&P)" },
    { value: "ACCOUNT_LAYEON", name: "은행(라연계좌)" },
    { value: "ACCOUNT_DADOC", name: "은행(다이어트닥터계좌)" },
    { value: "ACCOUNT_EKWANGDONG", name: "은행(광동계좌)" },
    { value: "ACCOUNT_MELINE", name: "은행(미라인계좌)" },
    { value: "ACCOUNT_OLIVE", name: "은행(올리브계좌)" },
    { value: "ACCOUNT_CHAMKH", name: "은행(참경희계좌)" },
    { value: "ETC", name: "기타" },
];

// PAYPAL
export const FINANCIAL_INSTITUTE_ITEMS = [
    { value: "PAYPAL", name: "페이팔" },
    { value: "ETC", name: "기타" },
]

export const FINANCIAL_FEE_TYPE_ITEMS = [
    { value: "ACCOUNT", name: "은행" },
    { value: "PAYPAL", name: "페이팔" },
    { value: "ETC", name: "기타" },
]

