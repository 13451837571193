import React from "react";
import { Routes, Route } from "react-router-dom";
import MyAccountPage from "pages/Account/MyAccount";

const AccountRoute = () => {
    return (
        <Routes>
            <Route path="/my-account" element={<MyAccountPage />}></Route>
        </Routes>
    )
}

export default AccountRoute;