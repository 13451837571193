import { set } from 'lodash';
import React, { useEffect, useState } from 'react'

const Checkbox = ({
    initialChecked,
    data = {},
    onChange = (checked, data) => { },
    onInitialized = (checked, data) => { },
    confirmChange = (checked, data) => { return true },
}) => {
    const [checked, setChecked] = useState(initialChecked);

    useEffect(() => {
        // console.log("[Checkbox] useEffect[data]", data)
        onInitialized(initialChecked, data);
        setChecked(initialChecked);
    }, [data])

    // useEffect(() => {
        // onChange(checked, data);
        // console.log("[Checkbox] useEffect[initialChecked]", initialChecked)
        // setChecked(initialChecked);
    // }, [initialChecked])

    const handleClick = (e) => {
        console.log("[onchange]", checked);
        const ok = confirmChange(checked, data);
        if (!ok) {
            return;
        }
        const newChecked = !checked;
        setChecked(newChecked);
        onChange(newChecked, data);
    }

    return (
        <div className="flex justify-center items-center">
            <input
                id="candidates"
                aria-describedby="candidates-description"
                name="candidates"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                checked={checked}
                onClick={handleClick}
                readOnly={true}
            ></input>
        </div>
    )
}

export default Checkbox;
