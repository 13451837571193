import React, { useState } from "react";
import Input from "..";

const ToggleSearchInput = ({ upperLabel = "", upButtonLabel = "UP", downButtonLabel = "DOWN", placeholder = "", initialValue = "", initialIsUp = true, onToggle = (isUp, value) => { } }) => {
    const [isUp, setIsUp] = useState(initialIsUp);
    const [value, setValue] = useState(initialValue);

    return (
        <Input
            value={value}
            upperLabel={upperLabel}
            buttonLabel={isUp ? upButtonLabel : downButtonLabel}
            onButtonClicked={() => { setIsUp(!isUp); onToggle(!isUp, value) }}
            withButton={true}
            disabled={!isUp}
            onChange={(value) => setValue(value)}
            placeholder={placeholder}
        ></Input>
    )
}

export default ToggleSearchInput;
