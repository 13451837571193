import React, { useEffect, useState } from "react";
import _ from "lodash";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { classNames, getSha10length } from "components/uiUtils";
import InputUpperLabel from "components/Form/Input/Label/InputUpper";
import "../style.scss";
import { CustomDatePickerInput, renderCustomHeader } from "..";
import { useLocation } from "react-router-dom";

const now = new Date();
const ONE_WEEKS_AGO = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
const ONE_WEEKS_AFTER = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);

export const RangeDatePicker = ({ label = "", upperLabel = "", initialRange = [ONE_WEEKS_AGO, ONE_WEEKS_AFTER], onRangeChanged = (dates) => { }, onClick = () => { }, options = null, onOptionChanged = (value) => { }, saveSelectedDts = false, saveOption = false, hideRange = false, ...otherProps },) => {
    const location = useLocation();
    const [uid] = useState(getSha10length(`${location.pathname}${label}${upperLabel}`));
    const [dateRange, setDateRange] = useState(() => {
        if (saveSelectedDts) {
            const savedRange = JSON.parse(localStorage.getItem(uid));
            if (_.isNil(savedRange)) {
                return initialRange;
            }
            try {
                const savedDts = [new Date(savedRange[0]), new Date(savedRange[1])];
                return savedDts;
            } catch (e) {
                console.error("[RangeDatePicker] savedRange error", savedRange);
            }
        }
        return initialRange;
    });
    const [selectedOption, setSelectedOption] = useState(() => {
        if (saveOption) {
            const savedOption = localStorage.getItem(`${uid}o`);
            if (!_.isNil(savedOption)) {
                return savedOption;
            }
        }
        if (_.isNil(options)) {
            return "";
        }
        if (options.length > 0) {
            return options[0];
        }
        return "";
    });

    useEffect(() => {
        onRangeChanged(dateRange);
        onOptionChanged(selectedOption);
    }, [])

    const onRangeChange = (dates) => {
        setDateRange(dates);
        onRangeChanged(dates);
        if (saveSelectedDts) {
            localStorage.setItem(uid, JSON.stringify(dates));
        }
    }

    const onOptionChange = (value) => {
        setSelectedOption(value);
        onOptionChanged(value);
        if (saveOption) {
            localStorage.setItem(`${uid}o`, value);
        }
    }

    return (
        <> {upperLabel && <InputUpperLabel>{upperLabel}</InputUpperLabel>}
            <div className="flex rounded-md shadow-sm">
                {label &&
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm text-nowrap">
                        {label}
                    </span>
                }
                {options &&
                    <select
                        id="country"
                        name="country"
                        autoComplete="country"
                        className={classNames("relative bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-inset border-0",
                            "rounded-l-md", hideRange && "rounded-r-md"
                        )}
                        onChange={(e) => { onOptionChange(e.target.value) }}
                        value={selectedOption}
                    >
                        {options.map((option, i) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                }
                <div className={"w-full"} onClick={onClick} hidden={hideRange}>
                    <DatePicker
                        selectsRange={true}
                        dateFormat={"yyyy-MM-dd"}
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        onChange={onRangeChange}
                        showPopperArrow={false}
                        className={classNames("w-full", "rounded-r-md", (label || options) || "rounded-l-md", "-ml-px")}
                        customInput={<CustomDatePickerInput />}
                        renderCustomHeader={renderCustomHeader}
                        dropdownMode="select"
                        dateFormatCalendar="YYYY-MM"
                        // {...!_.isEmpty(dts) && { includeDates: dts }}
                        {...otherProps}
                    />
                </div>
            </div>
        </>

    )
}

export default RangeDatePicker;