import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import HomePage from "pages/Home";

const MainRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/home" />}></Route>
            <Route path="/home" element={<HomePage/>}></Route>
        </Routes>
    )
}

export default MainRoute;