import { formatDate, renderQuarterWrap, renderSmallWrap } from "components/Table/Simple/presets"


/*
phone_number
birthdate
gender
nation
address
source
point
note
password_reset_required
created_at
updated_at
*/
export const basicUserColumnInfos = [
    {
        field: "user_id",
        label: "아이디",
        visible: false,
    },
    {
        field: "password",
        label: "비밀번호",
        visible: false,
    },
    {
        field: "user_type",
        label: "유저타입",
        visible: false,
    },
    {
        field: "name",
        label: "이름",
        visible: true,
    },
    {
        field: "gender",
        label: "성별",
        visible: true,
    },
    {
        field: "nation",
        label: "국적",
        visible: true,
    },
    {
        field: "birthdate",
        label: "생년월일",
        visible: true,
        render: formatDate
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: false,
    },
    {
        field: "address",
        label: "회원주소",
        visible: false,
    },
    {
        field: "source",
        label: "소스",
        visible: false,
    },
    {
        field: "point",
        label: "포인트",
        visible: false,
    },
    {
        field: "note",
        label: "비고",
        visible: false,
    },
    {
        field: "password_reset_required",
        label: "비밀번호재설정필요",
        visible: false,
    },
    {
        field: "created_at",
        label: "가입일",
        visible: false,
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: false,
    },
    {
        field: "line_access_code",
        label: "라인연동코드",
        visible: false,
    },
    {
        field: "line_email",
        label: "라인이메일",
        visible: false,
    },
    {
        field: "line_state",
        label: "라인상태",
        visible: false,
    },
    {
        field: "line_user_id",
        label: "라인유저ID",
        visible: false,
    },
    {
        field: "refresh_token",
        label: "리프레시토큰",
        visible: false,
    },
    {
        field: "clinic_name",
        label: "의원명",
        visible: false,
    },
    {
        field: "line_user_name",
        label: "라인유저명",
        visible: false,
    }
]

export const additionalUserColumnInfos = [
    {
        field: "user_id",
        label: "아이디",
        visible: false,
    },
    {
        field: "password",
        label: "비밀번호",
        visible: false,
    },
    {
        field: "user_type",
        label: "유저타입",
        visible: false,
    },
    {
        field: "name",
        label: "이름",
        visible: false,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: false,
    },
    {
        field: "birthdate",
        label: "생년월일",
        visible: false,
        render: formatDate
    },
    {
        field: "gender",
        label: "성별",
        visible: false,
    },
    {
        field: "nation",
        label: "국적",
        visible: false,
    },
    {
        field: "address",
        label: "회원주소",
        visible: true,
    },
    {
        field: "note",
        label: "비고",
        visible: true,
    },
    {
        field: "source",
        label: "소스",
        visible: false,
    },
    {
        field: "point",
        label: "포인트",
        visible: true,
    },
    {
        field: "password_reset_required",
        label: "비밀번호재설정필요",
        visible: false,
    },
    {
        field: "created_at",
        label: "가입일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: false,
    },
    {
        field: "line_access_code",
        label: "라인연동코드",
        visible: false,
    },
    {
        field: "line_email",
        label: "라인이메일",
        visible: false,
    },
    {
        field: "line_state",
        label: "라인상태",
        visible: false,
    },
    {
        field: "line_user_id",
        label: "라인유저ID",
        visible: false,
    },
    {
        field: "refresh_token",
        label: "리프레시토큰",
        visible: false,
    },
    {
        field: "clinic_name",
        label: "의원명",
        visible: false,
    },
    {
        field: "line_user_name",
        label: "라인유저명",
        visible: false,
    }
]

export const submissionColumnInfos = [
    {
        field: "question_id",
        label: "질문 ID",
        visible: false,
    },
    {
        field: "question_idx",
        label: "질문번호",
        visible: true,
    },
    {
        field: "question",
        label: "질문",
        visible: true,
        render: renderSmallWrap
    },
    {
        field: "question_type",
        label: "질문유형",
        visible: false,
    },
    {
        field: "question_translated",
        label: "번역된 질문",
        visible: false,
    },
    {
        field: "answer",
        label: "답변",
        visible: false,
    },
    {
        field: "answer_translated",
        label: "번역된 답변",
        render: renderSmallWrap,
        visible: true,
    },
]