import React, { useState } from 'react';
import ChoiceMaker from '../ChoiceMaker';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { randomString } from 'utils/math';
import { AnswerFormat, QuestionType } from 'containers/Questions/Question/types';
import { QuestionItem } from '..';
import _ from 'lodash';
import Textarea from 'components/Form/Textarea';

export const QUESTION_TYPE_SELECT_ITEMS = [
    {
        name: "짧은 답변",
        data: QuestionType.SHORT_ANSWER
    },
    {
        name: "긴 답변",
        data: QuestionType.LONG_ANSWER
    },
    {
        name: "객관식 답변",
        data: QuestionType.SINGLE_CHOICE
    },
    {
        name: "다중선택 답변",
        data: QuestionType.MULTIPLE_CHOICE
    },
    {
        name: "날짜",
        data: QuestionType.DATE
    },
    {
        name: "시간",
        data: QuestionType.TIME
    }
]

export const ANSWER_FORMAT_SELECT_ITEMS = [
    {
        name: "기본",
        data: AnswerFormat.NONE
    },
    {
        name: "숫자",
        data: AnswerFormat.NUMBER
    },
    {
        name: "아이디",
        data: AnswerFormat.ID
    },
    {
        name: "전화번호",
        data: AnswerFormat.PHONE
    },
    {
        name: "이메일",
        data: AnswerFormat.EMAIL
    }
]

interface QuestionMakerProps {
    orderIdx?: string;
    item?: QuestionItem;
    parentItem?: QuestionItem;
    onQuestionTextChanged: (questionId: string, text: string) => void;
    onConditionalAddButtonClicked: (questionId: string, optionId: string, idx: number) => void;
    onOptionChanged: (questionId: string, optionId: string, value: string) => void;
    selectedTypeExternal: { name: string, data: QuestionType };
    setSelectedTypeExternal?: (questionId: string, type: QuestionType) => void;
    selectedFormatExternal?: { name: string, data: AnswerFormat };
    setSelectedFormatExternal?: (questionId: string, format: AnswerFormat) => void;
    setOptions?: (questionId: string, options: any[]) => void;
}

const QuestionMaker = ({
    orderIdx = "1",
    item,
    parentItem,
    onQuestionTextChanged,
    onConditionalAddButtonClicked,
    onOptionChanged,
    selectedTypeExternal,
    setSelectedTypeExternal,
    selectedFormatExternal,
    setSelectedFormatExternal,
    setOptions
}: QuestionMakerProps) => {
    const [selectedTypeInternal, setSelectedTypeInternal] = useState(QUESTION_TYPE_SELECT_ITEMS[0]);
    const [selectedFormatInternal, setSelectedFormatInternal] = useState(ANSWER_FORMAT_SELECT_ITEMS[0]);

    const selectedType = selectedTypeExternal || selectedTypeInternal;
    const selectedFormat = selectedFormatExternal || selectedFormatInternal;

    const isSubQuestion = !(item.data.condition === "" || _.isNil(item.data.condition));

    const conditionText = parentItem?.data.options.find((option) => option.option_id === item.data.condition)?.text || "";
    const parentOrderIdx = orderIdx.split("-").slice(0, -1).join("-");

    return (
        <div>
            {/*
            <div className="mb-3">
                I'm {isSubQuestion ? "sub" : "main"} question {orderIdx} {item.id} {isSubQuestion ? `depending on ${item.data.condition}` : ""}
            </div> */}
            <div className="flex items-center w-full justify-between mb-2 space-x-2">
                <div className="">
                    <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 me-1 text-nowrap">
                        {`${orderIdx} 번 질문`}
                    </span>
                </div>
                {isSubQuestion &&
                    <div className="text-xs text-gray-900 px-2 py-1 rounded-xl items-center bg-yellow-200 bg-opacity-80">
                        <div>
                            {`${parentOrderIdx} 번 질문 "${parentItem?.data?.questionText}" 에 대한 답변 "${conditionText}" 에 따른 추가 질문입니다`}
                        </div>
                    </div>
                }
            </div>
            <h1 className="font-semibold">질문</h1>
            <div className="mb-3 mt-1">
                <Textarea value={item.data.questionText}
                    onChange={(text) => { onQuestionTextChanged(item.question_id, text) }}
                    placeholder='질문을 입력하세요'
                />
            </div>
            <h1 className="font-semibold">질문 유형</h1>
            <div className="mb-3 mt-1">
                <Select
                    items={QUESTION_TYPE_SELECT_ITEMS}
                    selectedItem={selectedType}
                    onChange={(typeItem: { name: string, data: QuestionType }) => { selectedTypeExternal ? setSelectedTypeExternal(item.question_id, typeItem.data) : setSelectedTypeInternal(typeItem) }}
                />
            </div>
            {selectedType.data === QuestionType.SHORT_ANSWER && <>
                <h1 className="font-semibold">답변 형식</h1>
                <div className="mb-3 mt-1">
                    <Select
                        items={ANSWER_FORMAT_SELECT_ITEMS}
                        selectedItem={selectedFormat}
                        onChange={(formatItem: { name: string, data: AnswerFormat }) => { console.log("!@#!@#!@#", selectedFormatExternal); selectedFormatExternal ? setSelectedFormatExternal(item.question_id, formatItem.data) : setSelectedFormatInternal(formatItem) }}
                    />
                </div>
            </>}
            {(selectedType.data === QuestionType.SINGLE_CHOICE || selectedType.data === QuestionType.MULTIPLE_CHOICE) && <>
                <h1 className="font-semibold">선택지</h1>
                <div className="mb-3 mt-1">
                    <ChoiceMaker
                        questionId={item.question_id}
                        onConditionalAddButtonClicked={onConditionalAddButtonClicked}
                        onOptionChanged={onOptionChanged}
                        listsExternal={item.data.options}
                        setListsExternal={(options) => setOptions(item.question_id, options)}
                    ></ChoiceMaker>
                </div>
            </>}
        </div>
    )
}

export default QuestionMaker;