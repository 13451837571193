import React, { useEffect, useState } from 'react';
import { AdminClient, adminClient } from 'apis/admin';
import Card from 'components/Card';
import _, { set } from 'lodash';
import SimpleTable from 'components/Table/Simple';
import { statColumnInfos } from './config';
import PageTitle from 'components/Title/Page';
import MonthWithStep from 'components/Form/Datepicker/MonthWithStep';
import Select, { getUidOfItems } from 'components/Form/Select';
import { createDateArray } from 'utils/time';
import moment from 'moment-timezone';
import { randomString } from 'utils/math';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import numeral from 'numeral';
import './calendar.scss';

export const TERM_ITEMS = [
    { name: "전체", value: "ALL" },
    { name: "월", value: "MONTH" },
    { name: "일", value: "DAY" },
]

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0); // 시간을 00:00:00으로 설정

// 종료 날짜 (2024-01-01)
const START = new Date("2024-01-01");

export const DTS = createDateArray(START, TODAY);

const PurchaseStatViewer = () => {
    const [tableData, setTableData] = useState(null);
    const [clinicInfos, setClinicInfos] = useState(null);
    const [clinicItems, setClinicItems] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [summaryTableData, setSummaryTableData] = useState(null);

    useEffect(() => {
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
            const tempClinicItems = infos.map((info) => {
                return {
                    name: info.name,
                    data: info.clinic_id
                }
            })
            console.log("[PurchaseStatViewer] tempClinicItems", tempClinicItems);
            setClinicItems(tempClinicItems);
            setSelectedClinicItem(tempClinicItems[0]);
        });
    }, [])

    useEffect(() => {
        if (_.isNil(selectedClinicItem)) {
            return;
        }
        if (_.isNil(selectedMonth)) {
            return;
        }
        fetchData();
    }, [selectedClinicItem, selectedMonth])

    const fetchData = async () => {
        try {
            const clinic_id = selectedClinicItem.data;
            const selectedDt = new Date(selectedMonth);
            console.log("[fetchData] clinic_id, selectedDt", clinic_id, selectedDt);
            const params = {
                clinic_id,
                startDate: moment(selectedDt).startOf('month').format("YYYY-MM-DD"),
                endDate: moment(selectedDt).endOf('month').format("YYYY-MM-DD")
            }
            const ret = await adminClient.get('/purchases/statistics/monthly', { params });
            console.log("[fetchData] ret", ret);
            setTableData(ret.data);
            let total: any = {};
            ret.data.forEach((data) => {
                total = {
                    daily_total_purchase: (total.daily_total_purchase ?? 0) + data.daily_total_purchase,
                    daily_total_refund: (total.daily_total_refund ?? 0) + data.daily_total_refund,
                    daily_total_used_point: (total.daily_total_used_point ?? 0) + data.daily_total_used_point,
                    daily_total_earned_point: (total.daily_total_earned_point ?? 0) + data.daily_total_earned_point,
                }
            })
            console.log("!!! TOTAL", total);
            setSummaryTableData([total]);
        } catch (e) {
            console.error("[PurchaseStatViewer] e", e);
        }
    }

    // if (_.isNil(tableData)) {
    //     return <SpinnerMedium />
    // }
    console.log("[PurchaseStatViewer] tableData", tableData);

    return (
        <div>
            <PageTitle>매출 통계</PageTitle>
            <div className="flex space-x-2 pb-2 overflow-auto">
                <div>
                    <Select items={clinicItems} selectedItem={selectedClinicItem} onChange={setSelectedClinicItem} />
                </div>
                <div className="z-40">
                    <MonthWithStep onDtChanged={setSelectedMonth} />
                </div>
            </div>
            <h1 className="text-sm font-semibold">
                월 합계
            </h1>
            <SimpleTable data={summaryTableData} columns={statColumnInfos.filter((info) => ["daily_total_purchase", "daily_total_refund", "daily_total_used_point", "daily_total_earned_point"].includes(info.field))} />
            <h1 className="text-sm font-semibold">
                구매금액 현황
            </h1>
            <Calendar
                activeStartDate={new Date(selectedMonth)}
                showNavigation={false}
                calendarType="gregory"
                tileContent={({ date, view }) => {
                    console.log("date", date, "view", view);
                    if(_.isNil(tableData)) {
                        return null;
                    }
                    const found = tableData.find((data) => {
                        return moment(data.purchase_at).isSame(date, 'day');
                    });
                    console.log("found", found);
                    return (
                        <div>
                            <div className="text-[9px] sm:text-sm">
                                {numeral(found?.daily_total_purchase).format("0,0") ?? 0}
                            </div>
                        </div>
                    )
                }}
            />
            <h1 className="text-sm font-semibold mt-2">
                일별 상세 데이터
            </h1>
            <SimpleTable data={tableData} columns={statColumnInfos} />
        </div>
    )
}

const PurchaseStatisticPage = () => {
    const navigate = useNavigate();
    const [selectedTermItem, setSelectedTermItem] = useState(null);
    const [filterParams, setFilterParams] = useState(null);
    const [selectedDt, setSelectedDt] = useState(null);
    const [clinicInfos, setClinicInfos] = useState([]);
    const [productInfos, setProductInfos] = useState([]);
    const [clinicItems, setClinicItems] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [refreshKey, setRefreshKey] = useState(randomString());

    useEffect(() => {
        const uid = getUidOfItems(TERM_ITEMS);
        const initialTermItem = localStorage.getItem(uid);
        if (_.isNil(initialTermItem)) {
            setSelectedTermItem(TERM_ITEMS[0])
        }
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        });
        fetchProductInfos();
    }, [])

    useEffect(() => {
        if (_.isEmpty(clinicInfos)) {
            return;
        }
        const items = clinicInfos.map((info) => {
            return {
                name: info.name,
                value: info.clinic_id
            }
        })
        const itesmWithAll = [{ name: "모든 의원", value: null }, null, ...items];
        setClinicItems(itesmWithAll);
        const uid = getUidOfItems(itesmWithAll);
        const initialClinicItem = localStorage.getItem(uid);
        if (_.isNil(initialClinicItem)) {
            setSelectedClinicItem(itesmWithAll[0]);
        }
    }, [clinicInfos])

    useEffect(() => {
        console.log("[useEffect] selectedTermItem, selectedDt, selectedClinicItem", selectedTermItem, selectedDt, selectedClinicItem)
        if (_.isNil(selectedTermItem)) {
            return;
        }
        if (_.isNil(selectedClinicItem)) {
            return;
        }
        let startDt = null;
        let endDt = null;
        if (selectedTermItem.value === "DAY") {
            if (_.isNil(selectedDt)) {
                return;
            }
            startDt = new Date(selectedDt);
            endDt = new Date(selectedDt);
        }
        if (selectedTermItem.value === "MONTH") {
            if (_.isNil(selectedDt)) {
                return;
            }
            startDt = new Date(selectedDt);
            startDt.setDate(1);
            endDt = new Date(startDt);
            endDt.setMonth(endDt.getMonth() + 1);
            endDt.setDate(endDt.getDate() - 1);
        }
        setFilterParams({
            ...(!_.isNil(startDt) && { startDate: moment(startDt).format("YYYY-MM-DD") }),
            ...(!_.isNil(endDt) && { endDate: moment(endDt).format("YYYY-MM-DD") }),
            ...(!_.isNil(selectedClinicItem.value) && { clinic_id: selectedClinicItem.value }),
            ...(!_.isNil(searchText) && { search_text: searchText })
        });
    }, [selectedTermItem, selectedDt, selectedClinicItem, searchText])

    useEffect(() => {
        if (_.isNil(filterParams)) {
            return;
        }
        // updateTableData();
        setRefreshKey(randomString());
    }, [filterParams])

    const fetchTableData = async (page, limit) => {
        try {
            const params = {
                page,
                limit,
                ...filterParams
            }
            const ret = await adminClient.get(`/purchases`, { params });
            console.log("[fetchTableData] ret", ret);
            if (_.isNil(ret?.data?.data)) {
                return { data: [], page: 1, total: 0 }
            }
            const tempTableData = ret.data.data;
            console.log("[fetchTableData] tempTableData", tempTableData);
            return {
                data: tempTableData,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error("[fetchTableData] e", e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const fetchProductInfos = async () => {
        try {
            const params = {
                page: 1,
                limit: 100000
            }
            const ret = await adminClient.get(`/products`, { params });
            console.log("[fetchProductInfos] ret", ret);
            if (_.isNil(ret?.data?.data)) return console.error("No data");
            const tempProductInfos = ret.data.data;
            console.log("[fetchProductInfos] tempProductInfos", tempProductInfos);
            setProductInfos(tempProductInfos);
        } catch (e) {

        }
    }

    const onDtChanged = (dt) => {
        console.log("[onDtChanged] term, dt", selectedTermItem.value, dt);
        setSelectedDt(dt);
    }
    return (
        <Card>
            <div className="p-3">
                {/* <PageTitle>구매 통계</PageTitle> */}

                <PurchaseStatViewer />
            </div>
        </Card>

    )
}

export default PurchaseStatisticPage;