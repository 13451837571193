import React from "react";
import SimpleTable, { Column } from "components/Table/Simple";
import { formatMoney } from "components/Table/Simple/presets";
import { INTAKE_TYPE_SELECT_ITEMS } from "containers/Intake/Form/Edit";

const detailColumnInfo: Column[] = [
    {
        field: "treatment",
        label: "진료유형",
        visible: true,
    },
    {
        field: "total_purchase_price",
        label: "구매금액",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "total_refund_amount",
        label: "환불금액",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "total_used_point",
        label: "사용포인트",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "total_earned_point",
        label: "적립포인트",
        visible: true,
        align: "right",
        render: formatMoney,
    },
]
export const statColumnInfos: Column[] = [
    {
        field: "clinic_id",
        label: "의원ID",
        visible: false,
    },
    {
        field: "purchase_at",
        label: "구매일",
        visible: true,
    },
    {
        field: "treatment_type",
        label: "진료유형",
        visible: false,
        render: (value) => {
            return INTAKE_TYPE_SELECT_ITEMS.find((item) => item.data === value)?.name;
        }
    },
    {
        field: "daily_total_purchase",
        label: "총 구매금액",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "daily_total_refund",
        label: "총 환불금액",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "daily_total_used_point",
        label: "총 사용포인트",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "daily_total_earned_point",
        label: "총 적립포인트",
        visible: true,
        align: "right",
        render: formatMoney,
    },
    {
        field: "treatment_summary",
        label: "세부내역",
        visible: true,
        render: (value, field, row) => {
            let tableData = [];
            const parsed = JSON.parse(value);
            Object.keys(parsed).sort().forEach((key) => {
                const treatment = INTAKE_TYPE_SELECT_ITEMS.find((item) => item.data === key)?.name;
                tableData.push({
                    treatment,
                    ...parsed[key]
                });
            })
            return (
                <div className="z-0 relative">
                    <SimpleTable data={tableData} columns={detailColumnInfo}></SimpleTable>
                </div>
            )
        }
    }
]