import React, { useEffect, useState } from 'react';
import SpinnerMedium from 'components/Spinner/Medium';
import { useParams, useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import QuillMadeArticle from 'components/Article/QuillMade';
import { fetchAndCreatePrescriptionDetailHtmls } from '../Submission/MemberDetailViewer';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Button from 'components/Form/Button';
import { useTranslation } from 'utils/translate';
import PageTitle from 'components/Title/Page';

const MemberPrescription = () => {
    const { submission_id } = useParams();
    const navigate = useNavigate();
    const [translatedHtml, setTranslatedHtml] = useState(null);
    const { tr } = useTranslation();

    console.log(submission_id)

    useEffect(() => {
        fetchPrescription();
    }, [submission_id])

    const fetchPrescription = async () => {
        const htmls = await fetchAndCreatePrescriptionDetailHtmls(submission_id);
        if (_.isNil(htmls)) {
            toast.error("처방전을 찾을 수 없습니다");
            navigate("/member/submission");
            return;
        }
        setTranslatedHtml(htmls?.translatedHtml);
    }

    if (_.isNil(submission_id) || _.isNil(translatedHtml)) {
        return <SpinnerMedium />
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>{tr("처방전")}</PageTitle>
                <Card paddingClassNames='p-1'>
                    <div className="p-2">
                        <QuillMadeArticle htmlText={translatedHtml}></QuillMadeArticle>
                    </div>
                </Card>
                <div className='flex justify-end mt-1'>
                    <div>
                        <Button onButtonClicked={() => { navigate("/member/submission") }}>{tr("제출 목록 보기")}</Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default MemberPrescription;