import React, { useEffect, useState } from "react";
import { memberClient } from "apis/member";
import PagingTable from "components/Table/Paging";
import Card from "components/Card";
import { useTranslation } from "utils/translate";
import { CalculatorIcon, NewspaperIcon } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column } from "components/Table/Simple";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import { commonClient } from "apis/common";
import { classNames } from "components/uiUtils";
import PageTitle from "components/Title/Page";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import { columnInfos } from "./config";
import { useModal } from "components/Modal/Simple";
import MemberPurchaseViewer from "./MemberPurchaseViewer";

// key of
const STATES = ["PAYMENT_COMPLETED", "SHIPPING", "DELIVERED"];

export const MemberPurchase = () => {
    const navigate = useNavigate();
    const { tr, addTrs } = useTranslation();
    const [searchParams] = useSearchParams();
    const { Modal: PurchaseViewerModal, openModal: openPurchaseViewerModal, closeModal: closePurchaseViewerModal } = useModal();

    useEffect(() => {
        fetchIntakeTitkeTrs();
        fetchPurchaseInfo();
    }, []);

    const fetchTrackings = async (page, limit) => {
        try {
            const params = {
                page,
                limit,
                states: STATES,
            }
            const ret = await memberClient.get("/public/trackings", { params });
            console.log(ret.data);
            // setTableData(ret.data.data);
            if (_.isNil(ret?.data?.data)) {
                return { data: [], page: 1, total: 0 }
            };
            const trackings = ret.data.data.map((elm) => _.pick(elm, ["submission_id", "intake_title", "state", "created_at", "purchase_at", "shipping_address", "deliver_request_at", "shipping_company", "tracking_number", "deliver_at", "delivered_at"]));
            // const trackings = ret.data.data;
            return {
                data: trackings,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchIntakeTitkeTrs = async () => {
        try {
            const params = {
                lang: searchParams.get("lang") ?? "jp"
            }
            const ret = await commonClient.get("/translations/intakes/title", { params });
            addTrs(ret.data);
        } catch (e) {

        }
    }

    const fetchPurchaseInfo = async () => {
        try {
            const ret = await memberClient.get("/public/purchases");
            console.log("[MemberPurchase] fetchPurchaseInfo", ret.data);

        } catch (e) {
            console.error(e);
        }
    }

    const formatPublicStatus = (value) => {
        const state = value as CustomerState;
        if (CustomerState[state] === CustomerState.AWAITING_SUBMISSION_TRANSLATION) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION_TRANSLATION) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_NOTI) return tr("처방전작성대기중");
        if (CustomerState[state] === CustomerState.AWAITING_CUSTOMER_PAYMENT) return tr("구매대기중");
        if (CustomerState[state] === CustomerState.PAYMENT_COMPLETED) return tr("배송대기중");
        if (CustomerState[state] === CustomerState.SHIPPING) return tr("배송중");
        if (CustomerState[state] === CustomerState.DELIVERED) return tr("배송완료");
        if (CustomerState[state] === CustomerState.PURCHASE_FAILURE) return tr("구매진행중단");
        return -1;
    }

    const columnInfosWithPrescriptionButton: Column[] = [
        {
            field: "purchase",
            label: "구매내역",
            visible: true,
            render: (value, field, row) => {
                return (
                    <button onClick={() => { openPurchaseViewerModal(row) }}>
                        <div className="relative group">
                            <CalculatorIcon className="size-4 align-center p-0" />
                        </div>
                    </button>
                )
            },
            align: "center"
        },
        ...columnInfos
    ]

    const translatedColumnInfos = columnInfosWithPrescriptionButton.map(info => {
        if (info.field === "state") {
            return {
                ...info,
                label: tr(info.label),
                render: formatPublicStatus
            }
        }
        if (info.field === "intake_title") {
            return {
                ...info,
                label: tr(info.label),
                render: (value) => {
                    return tr(value);
                }
            }
        }
        return {
            ...info,
            label: tr(info.label)
        }
    })

    return (
        <div>
            <Card>
                <div className="p-3">
                    <PageTitle>{tr("구매내역")}</PageTitle>
                    <PagingTable columns={translatedColumnInfos} fetchData={fetchTrackings} />
                </div>
            </Card>
            <PurchaseViewerModal>
                <MemberPurchaseViewer></MemberPurchaseViewer>
            </PurchaseViewerModal>
        </div>
    )
}

export default MemberPurchase;
