import { Column } from "components/Table/Simple";
import { renderHalfWrap, renderMediumWrap, renderQuarterWrap, renderSmallWrap } from "components/Table/Simple/presets";

export const columnInfos: Column[] = [
    {
        field: "idx",
        label: "구분",
        visible: true,
    },
    {
        field: "origin",
        label: "원문",
        visible: true,
        render: renderSmallWrap
    },
    {
        field: "translated",
        label: "번역문",
        visible: true,
        render: renderMediumWrap
    },
];