import React from 'react';

export default ({ length = 10, currentIndex = 0 }) => {
    return (
        <div>
            <h4 className="sr-only">Status</h4>
            <p className="text-sm font-medium text-gray-900">{`Step ${currentIndex + 1} of ${length}`}</p>
            <div className="mt-2" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                    <div className="h-2 rounded-full bg-indigo-600" style={{ width: `${(currentIndex + 1) / length * 100}%`, transition: "width 0.3s ease-in-out" }} />
                </div>
            </div>
        </div>
    )
}