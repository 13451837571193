import { Intake, QuestionItem } from ".";
import { IntakeInfo } from "containers/Intake/Form/Edit";
import { QuestionType, AnswerFormat } from "containers/Questions/Question/types";
import { createNewOptionItem } from "./ChoiceMaker";
import { randomString } from "utils/math";
import { QUESTION_TYPE_SELECT_ITEMS, ANSWER_FORMAT_SELECT_ITEMS } from "./QuestionMaker";
import { Question } from "containers/Questions/Question/types";
import { SectionItem } from "./SectionManager/types";

import _ from "lodash";

const isChoosableQuestion = (type: QuestionType) => [QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE].includes(type);

export const filterQuestions = (questions: QuestionItem[], validOptionIds: string[]): QuestionItem[] => {
    return questions
        .filter(question => !question.data.condition || validOptionIds.includes(question.data.condition))
        .map(question => ({
            ...question,
            sub_items: question.sub_items ? filterQuestions(question.sub_items, question.data?.options?.map(option => option.option_id)) : undefined
        }))
        .filter(question => question.sub_items === undefined || question.sub_items?.length > 0 || !question.data.condition || validOptionIds.includes(question.data.condition));
}

export const deleteSubItems = (questions: QuestionItem[], questionId: string): QuestionItem[] => {
    return questions.map(question => {
        if (question.question_id === questionId) {
            // 해당 질문의 sub_items를 빈 배열로 설정
            return {
                ...question,
                sub_items: []
            };
        } else if (question.sub_items) {
            // 하위 질문에서 재귀적으로 sub_items 삭제
            return {
                ...question,
                sub_items: deleteSubItems(question.sub_items, questionId)
            };
        }
        return question;
    });
}

export const generateNewQuestionId = () => `qu${randomString()}`;

export const createNewQuestionItem = (type: QuestionType, condition?: string): QuestionItem => {
    return {
        question_id: generateNewQuestionId(),
        data: {
            questionText: "",
            type,
            ...([QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE].includes(type) && { options: [createNewOptionItem()] }),
            ...(condition && { condition }),
        }
    }
}

export const findParentQuestionItem = (questions: QuestionItem[], targetItem: QuestionItem): QuestionItem | undefined => {
    for (const question of questions) {
        // 현재 질문의 하위 질문에서 대상 아이템을 찾기
        if (question.sub_items) {
            for (const subItem of question.sub_items) {
                if (subItem.question_id === targetItem.question_id) {
                    return question;
                }
            }

            // 하위 질문에서 재귀적으로 부모를 찾기
            const parentQuestion = findParentQuestionItem(question.sub_items, targetItem);
            if (parentQuestion) {
                return parentQuestion;
            }
        }
    }

    // 부모를 찾지 못한 경우
    return undefined;
}

export const addSubQuestion = (questions: QuestionItem[], parentQuestionId: string, newItem: QuestionItem) => {
    return questions.map((question) => {
        if (question.question_id === parentQuestionId) {
            return {
                ...question,
                sub_items: question.sub_items ? [...question.sub_items, newItem] : [newItem]
            };
        }
        if (question.sub_items) {
            return {
                ...question,
                sub_items: addSubQuestion(question.sub_items, parentQuestionId, newItem)
            };
        }
        return question;
    });
};

export const findQuestionTypeItem = (type: QuestionType) => {
    if (_.isNil(type)) {
        return QUESTION_TYPE_SELECT_ITEMS[0];
    }
    return QUESTION_TYPE_SELECT_ITEMS.find((item) => item.data === type);
};

export const findAnswerFormatItem = (format: AnswerFormat) => {
    if (_.isNil(format)) {
        return ANSWER_FORMAT_SELECT_ITEMS[0];
    }
    return ANSWER_FORMAT_SELECT_ITEMS.find((item) => item.data === format);
}

export const updateRecursive = (field: string) => (questions: QuestionItem[], id, value) => {
    const updateQuestions = (qs: QuestionItem[]) => {
        return qs.map((q) => {
            if (q.question_id === id) {
                return {
                    ...q,
                    data: {
                        ...q.data,
                        [field]: value
                    }
                };
            }
            if (q.sub_items) {
                return {
                    ...q,
                    sub_items: updateQuestions(q.sub_items)
                };
            }
            return q;
        });
    };
    return updateQuestions(questions);
}

export const addQuestion = (questions: QuestionItem[], selectedId, newItem) => {
    if (_.isNil(selectedId) || selectedId === "") {
        return [...questions, newItem];
    }
    return questions.flatMap((question) => {
        if (question.question_id === selectedId) {
            if (question.data.condition) { // if selected question has condition === sub question
                newItem.data.condition = question.data.condition;
            }
            return [question, newItem];
        }
        if (question.sub_items) {
            return {
                ...question,
                sub_items: addQuestion(question.sub_items, selectedId, newItem)
            };
        }
        return question;
    });
};

export const removeQuestion = (questions: QuestionItem[], selectedId) => {
    return questions.flatMap((question) => {
        if (question.question_id === selectedId) {
            return [];
        }
        if (question.sub_items) {
            return {
                ...question,
                sub_items: removeQuestion(question.sub_items, selectedId)
            };
        }
        return question;
    });
};

export const convertQuestionsItemsToDbSchema = (questionItems: QuestionItem[]): Question[] => {
    return questionItems.map(questionItem => {
        const { question_id, data: questionData, sub_items } = questionItem;
        const { questionText, type, options, condition } = questionData;
        const parsedQuestion: Question = {
            question_id,
            text: questionText,
            type: type,
            ...(isChoosableQuestion(type) && options && { options }),
            ...condition && { condition },
            ...(sub_items && !_.isEmpty(sub_items) && { conditional_questions: convertQuestionsItemsToDbSchema(sub_items) })
        };
        return parsedQuestion;
    });
}

export const convertQuestionsDbSchemaToItems = (questions: Question[]): QuestionItem[] => {
    if (_.isNil(questions)) {
        return [];
    }
    return questions.map(question => {
        const { question_id, text, type, options, condition, conditional_questions } = question;

        const questionItem: QuestionItem = {
            question_id,
            data: {
                questionText: text,
                type,
                options,
                condition,
            },
            sub_items: convertQuestionsDbSchemaToItems(conditional_questions)
        };

        return questionItem;
    });
}

export const generateNewSectionId = () => `sc${randomString()}`

export const createNewSectionItem = (): SectionItem => {
    return (
        {
            section_id: generateNewSectionId(),
            name: ""
        }
    )
}

export const generateNewIntakeId = () => `in${randomString()}`

export const createNewIntake = (intakeInfo: IntakeInfo): Intake => {
    return (
        {
            intake_id: generateNewIntakeId(),
            clinic_id: intakeInfo.clinic_id,
            treatment_type: intakeInfo.treatment_type,
            title: intakeInfo.title,
            description: intakeInfo.description,
            sections: [
                {
                    section_id: generateNewSectionId(),
                    name: "새 섹션",
                    questions: [
                        {
                            question_id: generateNewQuestionId(),
                            text: "새 질문",
                            type: QuestionType.SHORT_ANSWER
                        }
                    ]
                }
            ]
        }

    )
}
