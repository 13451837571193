import { renderQuarterWrap } from "components/Table/Simple/presets"
import { tr } from "utils/translate"
import React from "react"
/*
question_id
question_idx
question
question_translated
answer
answer_translated
*/
export const columnInfos = [
    {
        field: "question_id",
        label: "질문 ID",
        visible: false,
    },
    {
        field: "question_idx",
        label: tr("질문번호"),
        visible: true,
    },
    {
        field: "question",
        label: "질문",
        visible: false,
        render: renderQuarterWrap
    },
    {
        field: "question_type",
        label: "질문유형",
        visible: false,
    },
    {
        field: "question_translated",
        label: "질문",
        visible: true,
        render: renderQuarterWrap
    },
    {
        field: "answer",
        label: "답변",
        visible: true,
    },
    {
        field: "answer_translated",
        label: "번역된 답변",
        visible: false,
    },
]