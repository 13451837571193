import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'

const EXAMPLE_STEPS = [
    { id: '1', name: '회원가입' },
    { id: '2', name: '확인' },
]

export default ({ steps = EXAMPLE_STEPS, currentStepId = "1", onClick = (stepId) => { } }) => {
    const currentStepIndex = steps.findIndex((step) => step.id === currentStepId)
    return (
        <div className="w-full">
            <ol role="list" className="divide-y-[0.5px] divide-gray-300 rounded-md md:flex md:divide-y-0">
                {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative md:flex md:flex-1" onClick={() => { onClick(step.id) }}>
                        {stepIdx < currentStepIndex ? (
                            <a className="group flex w-full items-center">
                                <span className="flex items-center px-4 py-1.5 md:py-3 text-sm font-medium">
                                    <span className="flex h-6 w-6 md:h-7 md:w-7 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                        <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                </span>
                            </a>
                        ) : stepIdx === currentStepIndex ? (
                            <a className="flex items-center px-4 py-1.5 md:py-3 text-sm font-medium" aria-current="step">
                                <span className="flex h-6 w-6 md:h-7 md:w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                    <span className="text-indigo-600">{stepIdx + 1}</span>
                                </span>
                                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                            </a>
                        ) : (
                            <a className="group flex items-center">
                                <span className="flex items-center px-4 py-1.5 md:py-3 text-sm font-medium">
                                    <span className="flex h-6 w-6 md:h-7 md:w-7 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                        <span className="text-gray-500 group-hover:text-gray-900">{stepIdx + 1}</span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                                </span>
                            </a>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                    <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </div>
    )
}
