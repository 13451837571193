import React from "react"
import SideNav from "components/SideNav"
import { useTranslation } from "utils/translate"

const menus = [
    {
        title: "진료상황",
        subMenus: [
            {
                title: "제출목록",
                to: "/member/submission"
            },
            {
                title: "구매내역",
                to: "/member/purchase"
            },
        ],
    },
    // {
    //     title: "사용자",
    //     subMenus: [
    //         {
    //             title: "회원정보 관리",
    //             to: "/member/profile"
    //         },
    //     ],
    // },
    {
        title: "계정",
        subMenus: [
            {
                title: "내 계정",
                to: "/member/my-page"
            },
            {
                title: "포인트 내역",
                to: "/member/point-history"
            }
        ],
    }
]

export default () => {
    const { tr } = useTranslation()
    const translatedMenus = menus.map(menu => {
        return {
            ...menu,
            title: tr(menu.title),
            subMenus: menu.subMenus.map(subMenu => {
                return {
                    ...subMenu,
                    title: tr(subMenu.title)
                }
            })
        }
    })
    return (
        <SideNav menus={translatedMenus}></SideNav>
    )
}