import _ from "lodash";

export const formatProductName = (product) => {
    // const type = product?.type;
    // const typeLabel = type === "MONTH" ? `${product?.type_option}개월` : product?.type_option
    // const stageLabel = product?.stage ? `${product?.stage}단계` : "";
    // const label = `${product?.name} ${stageLabel} ${typeLabel}`;
    return product?.name ?? "";
}

export const formatJpProductName = (product) => {
    // const type = product?.type;
    // const typeLabel = type === "MONTH" ? `${product?.type_option}ヶ月` : product?.type_option
    // const stageLabel = product?.stage ? `ステップ${product?.stage}` : "";
    // const label = `${product?.name_jp} ${stageLabel} ${typeLabel}`;
    return product?.name_jp ?? "";
}
