import React, { useEffect, useState } from "react"
import SideNav from "components/SideNav"
import { getUserTypeFromCookie } from "utils/cookie"
import { UserType } from "pages/Admin/User/types"
const menus = [
    // {
    //     title: "Main",
    //     subMenus: [
    //         {
    //             title: "Home",
    //             to: "/main/home",
    //         },
    //         {
    //             title: "Form Maker",
    //             to: "/main/form_maker",
    //         },
    //     ],
    // },
    // {
    //     title: "홈",
    //     to: "/home"
    // },
    {
        title: "고객관리",
        to: "/admin/customer",
        subMenus: [
            {
                title: "진료상황 관리",
                to: "/admin/customer/status"
            },
            {
                title: "미결제 연락 관리",
                to: "/admin/customer/unpaid", // 이거
            },
            {
                title: "복용 후 관리",
                to: "/admin/customer/aftercare",
            },
            {
                title: "구매정보 확인",
                to: "/admin/customer/purchase-info",
            },
            {
                title: "포인트 내역 확인",
                to: "/admin/customer/point-history",
            },
            {
                title: "피드백 확인",
                to: "/admin/customer/feedback",
            },
            {
                title: "구매진행 중단 목록",
                to: "/admin/customer/purchase-failure",
            },
            {
                title: "매출 통계",
                to: "/admin/customer/purchase-statistic",
            },
        ],
    },
    {
        title: "작업",
        to: "/admin/task",
        subMenus: [
            {
                title: "응답지 번역",
                to: "/admin/task/translate-submission"
            },
            {
                title: "처방전 작성 대기",
                to: "/admin/task/awaiting-prescription"
            },
            {
                title: "처방전 번역",
                to: "/admin/task/translate-prescription"
            },
            {
                title: "처방전 발송",
                to: "/admin/task/send-prescription"
            },
            {
                title: "구매정보 입력",
                to: "/admin/task/input-purchase-info"
            },
            {
                title: "배송정보 입력",
                to: "/admin/task/input-delivery-info"
            },
            {
                title: "배송완료 처리",
                to: "/admin/task/complete-shipping"
            },
        ],
    },
    {
        title: "의원",
        to: "/admin/clinic",
        subMenus: [
            {
                title: "의원정보 관리",
                to: "/admin/clinic/list"
            },
            {
                title: "제품정보 관리",
                to: "/admin/clinic/product"
            },
            {
                title: "처방전 템플릿 관리",
                to: "/admin/clinic/prescription-template"
            },
            {
                title: "복용 후 연락 유형 관리",
                to: "/admin/clinic/aftercare-type"
            },
        ],
    },
    {
        title: "진료지",
        to: "/admin/intake",
        subMenus: [
            {
                title: "진료지 목록",
                to: "/admin/intake/list"
            },
            {
                title: "진료지 수정",
                to: "/admin/intake/editor"
            },
            {
                title: "진료지 번역",
                to: "/admin/intake/translate"
            },
        ],
    },
    {
        title: "사용자",
        to: "/admin/user",
        subMenus: [
            {
                title: "회원정보 관리",
                to: "/admin/user/member"
            },
            {
                title: "의료진정보 관리",
                to: "/admin/user/doctor"
            },
            {
                title: "직원정보 관리",
                to: "/admin/user/staff"
            },
        ],
    },
    // {
    //     title: "의료진용 화면",
    //     to: "/doctor",
    //     subMenus: [
    //         {
    //             title: "처방전 작성",
    //             to: "/doctor/prescription"
    //         },
    //         {
    //             title: "진료 상태",
    //             to: "/doctor/submission"
    //         },
    //     ],
    // },
    {
        title: "계정",
        to: "/account",
        subMenus: [
            {
                title: "내 계정",
                to: "/account/my-account"
            },
        ],
    }
    // {
    //     title: "회원용 화면",
    //     subMenus: [
    //         {
    //             title: "응답지 목록",
    //             to: "/submission"
    //         },
    //         {
    //             title: "회원정보",
    //             to: "/profile"
    //         },
    //     ],
    // }

]

const masterMenus = [
    {
        title: "관리자",
        to: "/admin/master",
        subMenus: [
            {
                title: "활동기록",
                to: "/admin/master/action"
            },
        ]
    }
]

const MASTERS = ["admin", "pigmas"];

export default () => {
    const [userType, setUserType] = useState(null);
    useEffect(() => {
        const type = getUserTypeFromCookie();
        setUserType(type);
    }, []);

    return (
        <SideNav menus={userType === UserType.MASTER ? [...menus, ...masterMenus] : menus}></SideNav>
    )
}