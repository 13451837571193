
import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminTaskTranslateSubmissionPage from "pages/Admin/Task/TranslateSubmission";
import TranslateSubmissionEditorPage from "pages/Admin/Task/TranslateSubmission/Editor";

import AdminTaskAwaitingPrescriptionPage from "pages/Admin/Task/AwaitingPrescription";

import AdminTaskTranslatePrescriptionPage from "pages/Admin/Task/TranslatePrescription";
import TranslatePrescriptionEditorPage from "pages/Admin/Task/TranslatePrescription/Editor";

import AdminTaskSendPrescriptionPage from "pages/Admin/Task/SendPrescription";

import AdminTaskInputPurchaseInfoPage from "pages/Admin/Task/InputPurchaseInfo";
import AdminTaskInputPurchaseInfoEditorPage from "pages/Admin/Task/InputPurchaseInfo/Editor";

import AdminTaskInputInputDeliveryInfo from "pages/Admin/Task/InputDeliveryInfo";

import AdminTaskCompleteShippingPage from "pages/Admin/Task/CompleteShipping";

const AdminTaskRoute = () => (
    <Routes>
        <Route path="/translate-submission" element={<AdminTaskTranslateSubmissionPage />}></Route>
        <Route path="/translate-submission/editor" element={<TranslateSubmissionEditorPage />}>
            <Route path=":submissionId" element={<TranslateSubmissionEditorPage />}></Route>
        </Route>

        <Route path="/awaiting-prescription" element={<AdminTaskAwaitingPrescriptionPage />}></Route>

        <Route path="/translate-prescription" element={<AdminTaskTranslatePrescriptionPage />}></Route>
        <Route path="/translate-prescription/editor" element={<TranslatePrescriptionEditorPage />}>
            <Route path=":submissionId" element={<TranslatePrescriptionEditorPage />}></Route>
        </Route>

        <Route path="/send-prescription" element={<AdminTaskSendPrescriptionPage />}></Route>
        <Route path="/input-purchase-info" element={<AdminTaskInputPurchaseInfoPage />}></Route>
        <Route path="/input-purchase-info/editor" element={<AdminTaskInputPurchaseInfoEditorPage />}>
            <Route path=":submissionId" element={<AdminTaskInputPurchaseInfoEditorPage />}></Route>
        </Route>
        <Route path="/input-delivery-info" element={<AdminTaskInputInputDeliveryInfo />}></Route>

        <Route path="/complete-shipping" element={<AdminTaskCompleteShippingPage />}></Route>
    </Routes>
)

export default AdminTaskRoute;