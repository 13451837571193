import React, { useState, useEffect } from "react"
import Input, { InputFormat } from "components/Form/Input"
import Button from "components/Form/Button"
import _ from "lodash"
import { toast } from "react-toastify"
import axios from "axios"
import config from "config"
import { useTranslation } from "utils/translate"
import { commonClient } from "apis/common"

export default ({ onSubmit = (authInfo) => { } }) => {
    const { tr } = useTranslation();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [isIdConfirmed, setIsIdConfirmed] = useState(false);
    const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
    const [isRePasswordConfirmed, setIsRePasswordConfirmed] = useState(false);

    const onIdButtonClicked = async () => {
        if (isIdConfirmed) { //수정
            setIsIdConfirmed(false);
            setIsPasswordConfirmed(false);
            setIsRePasswordConfirmed(false);
            setId("");
            setPassword("");
            setRePassword("");
        } else { // 입력
            if(id.length < 3) {
                toast.warn(tr("아이디는 3자 이상이어야 합니다."));
                return;
            }
            const params = {
                id: id
            }
            const ret = await commonClient.get(`/users/exist`, { params });
            console.log("RET", ret);
            if(ret.data.exist) {
                toast.warn(tr("이미 존재하는 아이디입니다."));
                return;
            }
            setIsIdConfirmed(true);
        }
    }

    const onPasswordButtonClicked = async () => {
        if (isPasswordConfirmed) { //수정
            setIsPasswordConfirmed(false);
            setIsRePasswordConfirmed(false);
            setPassword("");
            setRePassword("");
        } else { //입력
            if(password.length < 4) {
                toast.warn(tr("비밀번호는 4자 이상이어야 합니다."));
                return;
            }
            setIsPasswordConfirmed(true);
        }
    }

    const onRePasswordButtonClicked = async () => {
        if (isRePasswordConfirmed) { //수정
            setIsRePasswordConfirmed(false);
            setRePassword("");
        } else { //입력
            if(password !== rePassword) {
                toast.warn(tr("비밀번호가 일치하지 않습니다."));
                return;
            }
            setIsRePasswordConfirmed(true);
        }
    }

    return (
        <div className="">
            <div className="flex flex-col space-y-3 w-full">
                <div className="flex justify-between w-full">
                    <div className="w-full me-1">
                        <Input label={tr("아이디")} value={id} onChange={setId} disabled={isIdConfirmed} format={InputFormat.ID}></Input>
                    </div>
                    <div className="w-28">
                        <Button disabled={_.isEmpty(id)} onButtonClicked={onIdButtonClicked}>{
                            isIdConfirmed ? tr("수정") : tr("입력")
                        }</Button>
                    </div>
                </div>
                {isIdConfirmed &&
                    <>
                        {/* <div className="text-sm text-green-600">
                        사용 가능한 아이디입니다.
                    </div> */}
                        <div className="flex justify-between w-full">
                            <div className="w-full me-1">
                                <Input label={tr("비밀번호")} value={password} onChange={setPassword} disabled={isPasswordConfirmed} type={"password"}></Input>
                            </div>
                            <div className="w-28">
                                <Button onButtonClicked={onPasswordButtonClicked}>{
                                    isPasswordConfirmed ? tr("수정") : tr("입력")
                                }</Button>
                            </div>
                        </div>
                    </>
                }
                {isIdConfirmed && isPasswordConfirmed &&
                    <>
                        <div className="flex justify-between w-full">
                            <div className="w-full me-1">
                                <Input label={tr("비밀번호 확인")} value={rePassword} onChange={setRePassword} disabled={isRePasswordConfirmed} type={"password"}></Input>
                            </div>
                            <div className="w-28">
                                <Button onButtonClicked={onRePasswordButtonClicked}>{
                                    isRePasswordConfirmed ? tr("수정") : tr("입력")
                                }</Button>
                            </div>
                        </div>
                    </>
                }
                {isRePasswordConfirmed &&
                    <div className="mb-2">
                        <Button onButtonClicked={() => { onSubmit({ id, password }) }}>{tr("제출")}</Button>
                    </div>
                }
            </div>

        </div>
    )
}