import React from "react";
import { formatDate, formatDateTime } from "components/Table/Simple/presets";
import { formatStatus } from "pages/Admin/Customer/Status/config";
import DoctorDetailViewer from "./DoctorDetailViewer";
import { Column } from "components/Table/Simple";
import { formatShippingCompanyName, formatShippingLink } from "pages/Admin/Task/InputDeliveryInfo/helper";

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "id",
        visible: false
    },
    {
        field: "clinic_user_id",
        label: "고객번호",
        visible: true,
    },
    {
        field: "user_name",
        label: "고객명",
        visible: true,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "clinic_detail",
        label: "진료내용",
        visible: true,
        render: (value, field, row) => <DoctorDetailViewer value={value} row={row}></DoctorDetailViewer>,
        align: "center"
    },
    {
        field: "purchase_detail",
        label: "판매정보",
        visible: false,
        align: "center"
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
    },
    {
        field: "submission_id",
        label: "응답지번호",
        visible: true,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
    },
    {
        field: "state",
        label: "상태",
        visible: true,
        render: formatStatus
    },
    {
        field: "created_at",
        label: "응답지 제출일",
        visible: true,
        render: formatDate
    },
    {
        field: "updated_at",
        label: "마지막 수정시간",
        visible: true,
        render: formatDateTime
    },
    {
        field: "awaiting_at",
        label: "구매대기시작일",
        visible: false,
        render: formatDate
    },
    {
        field: "address",
        label: "회원주소",
        visible: false,
    },
    {
        field: "date1",
        label: "1차 연락일",
        visible: false,
    },
    {
        field: "date2",
        label: "2차 연락일",
        visible: false,
    },
    {
        field: "date3",
        label: "3차 연락일",
        visible: false,
    },
    {
        field: "noti1",
        label: "1차 연락여부",
        visible: false,
    },
    {
        field: "noti2",
        label: "2차 연락여부",
        visible: false,
    },
    {
        field: "noti3",
        label: "3차 연락여부",
        visible: false,
    },
    {
        field: "purchase_at",
        label: "구매일",
        visible: false,
        render: formatDate
    },
    {
        field: "memo",
        label: "메모",
        visible: false,
    },
    {
        field: "note",
        label: "비고",
        visible: false,
    },
    {
        field: "tracking_number",
        label: "송장번호",
        visible: false,
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
    {
        field: "purchase_at",
        label: "구매일",
        render: formatDate,
        visible: false,
    },
    {
        field: "deliver_request_at",
        label: "배송접수일",
        render: formatDate,
        visible: false,
    },
    {
        field: "shipping_address",
        label: "배송지",
        visible: false,
    },
    {
        field: "shipping_company",
        label: "배송사",
        visible: false,
        render: formatShippingCompanyName
    },
    {
        field: "tracking_number",
        label: "송장번호",
        visible: false,
    },
    {
        field: "tracking_shipping_link",
        label: "배송조회",
        render: formatShippingLink,
        align: "center",
        visible: false,
    },
    {
        field: "deliver_at",
        label: "배송일",
        render: formatDate,
        visible: false,
    },
    {
        field: "delivered_at",
        label: "배송완료일",
        render: formatDate,
        visible: false,
    }
];
