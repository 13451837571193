import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";
import { formatShippingCompanyName, formatShippingLink } from "pages/Admin/Task/InputDeliveryInfo/helper";

export const columnInfos: Column[] = [
    {
        field: "_id",
        label: "id",
        visible: false
    },
    {
        field: "user_id",
        label: "사용자ID",
        visible: false,
        // multiselect: true
    },
    {
        field: "submission_id",
        label: "응답지번호",
        align: "center",
        visible: false,
    },
    {
        field: "intake_id",
        label: "진료지ID",
        visible: false,
    },
    {
        field: "intake_title",
        label: "진료지제목",
        visible: true,
        // multiselect: true
    },
    {
        field: "state",
        label: "상태",
        visible: true,
    },
    {
        field: "created_at",
        label: "응답지 제출일",
        visible: true,
        render: formatDate
    },
    {
        field: "intake_version",
        label: "진료지버전",
        visible: false,
    },
    {
        field: "purchase_at",
        label: "구매일",
        render: formatDate,
        visible: true,
    },
    {
        field: "shipping_address",
        label: "배송지",
        visible: true,
    },
    {
        field: "deliver_request_at",
        label: "배송접수일",
        render: formatDate,
        visible: true,
    },
    {
        field: "shipping_company",
        label: "배송사",
        visible: true,
        render: formatShippingCompanyName
    },
    {
        field: "tracking_number",
        label: "송장번호",
        visible: true,
    },
    {
        field: "tracking_shipping_link",
        label: "배송조회",
        render: formatShippingLink,
        align: "center",
    },
    {
        field: "deliver_at",
        label: "배송일",
        render: formatDate,
        visible: true,
    },
    {
        field: "delivered_at",
        label: "배송완료일",
        render: formatDate,
        visible: true,
    }
]
