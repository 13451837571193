import Button from "components/Form/Button";
import { formatDate, renderQuarterWrap, renderSmallWrap } from "components/Table/Simple/presets"
import React from "react"
/*
question_id
question_idx
question
question_translated
answer
answer_translated
*/
export const columnInfos = [
    {
        field: "question_id",
        label: "질문 ID",
        visible: false,
    },
    {
        field: "question_idx",
        label: "질문번호",
        visible: true,
    },
    {
        field: "question",
        label: "질문",
        visible: true,
        render: renderSmallWrap
    },
    {
        field: "question_type",
        label: "질문유형",
        visible: false,
    },
    {
        field: "question_translated",
        label: "번역된 질문",
        visible: true,
        render: renderSmallWrap
    },
    {
        field: "answer",
        label: "답변",
        visible: true,
        render: renderSmallWrap
    },
    {
        field: "answer_translated",
        label: "번역된 답변",
        visible: true,
        render: renderSmallWrap
    },
]

export const userTableColumnInfo = [
    {
        field: "user_id",
        label: "사용자ID",
        visible: true,
    },
    {
        field: "user_type",
        label: "사용자 타입",
        visible: false,
    },
    {
        field: "name",
        label: "고객명",
        visible: true,
    },
    {
        field: "phone_number",
        label: "전화번호",
        visible: true,
    },
    {
        field: "birthdate",
        label: "생년월일",
        visible: true,
        render: formatDate
    },
    {
        field: "nation",
        label: "국가",
        visible: true,
    },
    {
        field: "gender",
        label: "성별",
        visible: true,
    },
    {
        field: "address",
        label: "회원주소",
        visible: false,
    },
    {
        field: "source",
        label: "가입경로",
        visible: false,
    },
    {
        field: "point",
        label: "포인트",
        visible: false,
    },
    {
        field: "created_at",
        label: "가입일",
        visible: true,
        render: formatDate
    },
    {
        field: "line_user_name",
        label: "라인 사용자명",
        visible: false,
    },

    {
        field: "note",
        label: "비고",
        visible: false,
    },
    {
        field: "password_reset_required",
        label: "비밀번호 초기화 필요",
        visible: false,
    },
    {
        field: "line_access_code",
        label: "라인 접근 코드",
        visible: false,
    },
    {
        field: "line_email",
        label: "라인 이메일",
        visible: false,
    },
    {
        field: "line_state",
        label: "라인 상태",
        visible: false,
    },
    {
        field: "line_user_id",
        label: "라인 사용자 ID",
        visible: false,
    },
    {
        field: "refresh_token",
        label: "리프레시 토큰",
        visible: false,
    },
    {
        field: "updated_at",
        label: "수정일",
        visible: false,
    }

]