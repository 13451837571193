import React, { useEffect, useState } from "react";
import { adminClient } from "apis/admin";
import Card from "components/Card";
import SimpleTable from "components/Table/Simple";
import _ from "lodash";
import { columnInfos } from "./config";
import PageTitle from "components/Title/Page";
import { randomString } from "utils/math";
import PagingTable from "components/Table/Paging";

const CustomerFeedbackPage = () => {
    const [refreshKey, setRefreshKey] = useState(randomString());

    useEffect(() => {
        setRefreshKey(randomString());
    }, [])

    const fetchTableData = async (page: number, limit: number) => {
        console.log("[Feedback] fetchTableData", page, limit);
        try {
            const params = {
                page,
                limit,
            }
            const ret = await adminClient.get(`/feedbacks`, { params });
            console.log("[fetchTableData] ret", ret);
            if (!ret?.data?.data) {
                return { data: [], page: 1, total: 0 }
            }
            const tempTableData = ret.data.data.map((elm) => _.omit(elm, ["_id"]));
            return {
                data: tempTableData,
                page: ret.data.page,
                total: ret.data.total,
            }
        } catch (e) {
            console.error(e);
            return { data: [], page: 1, total: 0 }
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>피드백 확인</PageTitle>
                <PagingTable
                    fetchData={fetchTableData}
                    columns={columnInfos}
                    refreshKey={refreshKey}
                />
            </div>
        </Card>
    )
}

export default CustomerFeedbackPage;