import { SYSTEM_TRS } from "assets/trs/system";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

export type Translation = {
    intake_id?: string;
    origin: string;
    translated: string;
    translated_lang?: string;
};

type Target = string | { [key: string]: any } | Target[];  // Can be a string, object, or array of objects

export const useTranslation = () => {
    const [searchParams] = useSearchParams();
    const [lang, setLang] = useState(() => {
        const queryLang = searchParams.get("lang");
        if (_.isNil(queryLang)) {
            return "JP";
        }
        return queryLang;
    });
    const [trs, setTrs] = useState(SYSTEM_TRS as Translation[]);

    const addTrs = (newTrs: Translation[]) => {
        setTrs([...trs, ...newTrs]);
    }

    const changeLang = (lang: string) => {
        setLang(lang);
    }

    const filteredTrs = trs.filter(tr => (tr.translated_lang).toLowerCase() === lang.toLowerCase());

    return {
        tr: (target: Target) => tr(target, filteredTrs),
        getTrs: () => trs,
        addTrs,
        changeLang
    }
}

export const tr = (target: Target, trs: Translation[] = [], originIfNotFound = true, lang = "JP"): any => {
    const lastLang = localStorage.getItem("lastLang");
    if (!_.isNil(lastLang)) {
        lang = lastLang;
    }
    const trsWithSystem = [...trs, ...SYSTEM_TRS.filter(tr => String(tr.translated_lang).toLowerCase() === String(lang).toLowerCase())];
    const translate = (text: string): string => {
        const translation = trsWithSystem.find(tr => tr.origin === text);
        return translation ? translation.translated : (originIfNotFound ? text : "");
    };

    const recursiveTranslate = (target: Target): Target => {
        if (typeof target === 'string') {
            return translate(target);
        } else if (Array.isArray(target)) {
            return target.map(item => recursiveTranslate(item));
        } else if (target !== null && typeof target === 'object') {
            const translatedObj: { [key: string]: any } = {};
            for (const key in target) {
                if (Object.prototype.hasOwnProperty.call(target, key)) {
                    translatedObj[key] = recursiveTranslate(target[key]);
                }
            }
            return translatedObj;
        }
        return target;
    };

    return recursiveTranslate(target);
};