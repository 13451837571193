import React, { useEffect, useState } from "react";
import { adminClient } from "apis/admin";
import Card from "components/Card";
import CRUDTable from "components/Table/CRUD";
import PagingTable from "components/Table/Paging";
import _ from "lodash";
import { columnInfos } from "./config";
import { Column } from "components/Table/Simple";
import { toast } from "react-toastify";
import { AdminClient } from "apis/admin";
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from "../helper";
import PageTitle from "components/Title/Page";
import Select from "components/Form/Select";
import { confirmDelete } from "utils/browser";

const ProductListPage = () => {
    const [clinicInfos, setClinicInfos] = useState([]);
    const [clinicItems, setClinicItems] = useState(null);
    const [filterParams, setFilterParams] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [randomKey, setRandomKey] = useState(0);
    const [isSapn, setIsSapn] = useState(false);

    useEffect(() => {
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        });
    }, [])

    useEffect(() => {
        if (_.isEmpty(clinicInfos)) {
            return;
        }
        const items = clinicInfos.map((info) => {
            return {
                name: info.name,
                value: info.clinic_id
            }
        })
        const itesmWithAll = [{ name: "모든 의원", value: null }, null, ...items];
        setClinicItems(itesmWithAll);
        setSelectedClinicItem(itesmWithAll[0]);
    }, [clinicInfos])

    useEffect(() => {
        console.log("[useEffect] selectedClinicItem", selectedClinicItem)
        if (_.isNil(selectedClinicItem)) {
            return;
        }
        setFilterParams({
            clinic_id: selectedClinicItem?.value
        })
        setRandomKey(Math.random());
    }, [selectedClinicItem])

    const fetchProductData = async (page, limit) => {
        if (_.isNil(filterParams)) {
            return { data: [], page: 1, total: 0 }
        }
        try {
            const params = {
                page,
                limit,
                ...filterParams
            }
            const ret = await adminClient.get(`/products`, { params });
            console.log("[products ret]", ret);
            const tempTableData = ret.data.data;
            return {
                data: tempTableData,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error("[fetchProductData]", e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const columnInfosWithClinicIds = convertClinicIdColumnSelectable(columnInfos, clinicInfos);

    const onCreate = async (data) => {
        try {
            const productData = {
                ...data,
                price: Number(data.price),
            }
            const ret = await adminClient.post(`/products`, productData);
            console.log("[onCreate]", ret);
            setRandomKey(Math.random());
            toast.success("제품이 추가되었습니다.")
            return true;
        } catch (e) {
            console.error(e);
            toast.error("제품 추가에 실패했습니다.")
            return false;
        }
    }

    const onEdit = async (data) => {
        console.log("[onEdit] data", data);
        const ok = window.confirm("정말로 수정하시겠습니까?");
        if (!ok) {
            return false;
        }
        try {
            const product_id = parseInt(data.product_id)
            const productData = {
                ..._.omit(data, ["product_id", "created_at", "updated_at"]),
                price: Number(data.price),
            }
            console.log("[onEdit] productData", productData)
            const ret = await adminClient.put(`/products/${product_id}`, productData);
            console.log("[onEdit]", ret);
            setRandomKey(Math.random());
            toast.success("제품이 수정되었습니다.")
            return true;
        } catch (e) {
            console.error(e);
            toast.error("제품 수정에 실패했습니다.")
            return false;
        }
    }

    const onDelete = async (data) => {
        console.log("[onDelete] data", data)
        const ok = confirmDelete();
        if (!ok) {
            return false;
        }
        try {
            const product_id = parseInt(data.product_id)
            const ret = await adminClient.delete(`/products/${product_id}`);
            console.log("[onDelete]", ret);
            setRandomKey(Math.random());
            toast.success("제품이 삭제되었습니다.")
            return true;
        } catch (e) {
            console.error(e);
            toast.error("제품 삭제에 실패했습니다.")
            return false;
        }
    }

    const onReorder = async (tableData, row, isUp) => {
        console.log("[onReorder]", tableData, row, isUp);
        // const ok = window.confirm("순서를 변경하시겠습니까?");
        // if (!ok) {
        //     return false;
        // }
        try {
            const product_id = row.product_id;
            const directionPath = isUp ? "move-up" : "move-down";
            const ret = await adminClient.put(`/products/${product_id}/${directionPath}`);
            console.log("[onReorder] ret", ret);
            setRandomKey(Math.random());
            // toast.success("제품 순서가 변경되었습니다.")
            return true;
        } catch (e) {
            console.error(e);
            toast.error("제품 순서 변경에 실패했습니다.")
            return false;
        }
    }

    const rowInfo = createRowInfoWithClinic(clinicInfos);

    return (
        <Card>
            <div className="p-3" key={randomKey}>
                <PageTitle>제품정보 관리</PageTitle>
                <PagingTable
                    columns={columnInfosWithClinicIds}
                    rowInfo={rowInfo}
                    fetchData={fetchProductData}
                    windowSize={10000}
                    isCrudable={true}
                    onCreate={onCreate}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onReorder={onReorder}
                    isReordable={true}
                    filtersComponent={
                        <div className="flex space-x-2">
                            <Select
                                items={clinicItems}
                                selectedItem={selectedClinicItem}
                                onChange={setSelectedClinicItem}
                            />
                        </div>
                    }
                    disableSort={true}
                />
            </div>
        </Card>
    )
}

export default ProductListPage;