import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import ManagerSideNav from 'containers/SideNav/manager';
import ManagerSiteRoute from 'containers/SiteRoute/managerRoute';
import MemberSideNav from 'containers/SideNav/member';
import MemberSiteRoute from 'containers/SiteRoute/memberRoute';
import DoctorSideNav from 'containers/SideNav/doctor';
import DoctorSiteRoute from 'containers/SiteRoute/doctorRoute';
import { UserType } from 'pages/Admin/User/types';
import { getUserTypeFromCookie } from 'utils/cookie';
import SpinnerMedium from 'components/Spinner/Medium';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { logoutAndMoveToLoginPage } from 'apis/admin';

const RefreshTokenWrapper = ({ children }) => {
    const [isReady, setIsReady] = useState(null);
    const searchParams = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        console.log("[MainContentsWrapper] use effect searchParams", searchParams)
        // setIsReady(false);
        initialize();
    }, [searchParams])

    const initialize = async () => {
        const lastPath = localStorage.getItem("logoutFrom");
        console.log("[Home] lastPath", lastPath);
        if (!_.isNil(lastPath)) {
            navigate(lastPath);
            localStorage.removeItem("logoutFrom");
        }
        setIsReady(true);
    }

    return (
        !isReady ?
            <div>
                <SpinnerMedium></SpinnerMedium>
            </div> :
            <>
                {children}
            </>
    )
}
const LoggedInLayout = () => {
    const [userType, setUserType] = useState(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        console.log("[App] useEffect []")
        initialize();
    }, []);

    useEffect(() => {
        if ([UserType.STAFF, UserType.DOCTOR, UserType.MASTER].includes(userType)) {
            console.log("[LoggedInLayout] userType", userType)
            localStorage.setItem("lastLang", "ko");
        }
        if (userType === UserType.MEMBER) {
            console.log("[LoggedInLayout] userType", userType);
            const lang = searchParams.get("lang");
            if (_.isNil(lang)) {
                localStorage.setItem("lastLang", "jp");
            }
        }
    }, [userType])

    const initialize = async () => {
        const userType = getUserTypeFromCookie();
        console.log("[LoggedInLayout] userType", userType)
        if (_.isNil(userType)) {
            logoutAndMoveToLoginPage();
            // throw new InvalidTokenError();
            return false;
        }
        setUserType(UserType[userType]);
    }

    return (
        <RefreshTokenWrapper>
            {(userType === UserType.STAFF || userType === UserType.MASTER) ?
                <div className="main-container">
                    <ManagerSideNav></ManagerSideNav>
                    <div className="main-content">
                        <ManagerSiteRoute></ManagerSiteRoute>
                    </div>
                </div> :
                (userType === UserType.MEMBER) ?
                    <div className="main-container">
                        <MemberSideNav></MemberSideNav>
                        <div className="main-content">
                            <MemberSiteRoute></MemberSiteRoute>
                        </div>
                    </div> :
                    (userType === UserType.DOCTOR) ?
                        <div className="main-container">
                            <DoctorSideNav></DoctorSideNav>
                            <div className="main-content">
                                <DoctorSiteRoute></DoctorSiteRoute>
                            </div>
                        </div> : <div></div>
            }
        </RefreshTokenWrapper>
    );
}

export default LoggedInLayout;