import React from "react";

export const TopBar = ({ children }) => {
    return (
        <div className="fixed top-0 left-0 right-0 px-3 bg-white shadow-[0px_3px_3px_rgba(0,0,0,0.1)] z-50 sm:left-[250px]">
            {children}
        </div>
    )
}

export default TopBar;