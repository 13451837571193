import { Column } from "components/Table/Simple";
import { PrescriptionTemplateFieldType } from "./types";
import { renderQuarterWrap } from "components/Table/Simple/presets";

export const variableColumnInfos: Column[] = [
    {
        field: "key",
        label: "변수명",
        visible: true,
        creatable: true,
        editable: false,
    },
    {
        field: "type",
        label: "변수종류",
        visible: true,
        creatable: true,
        editable: true,
        selectable: {
            getOptions: () => [
                { name: "짧은변수", value: PrescriptionTemplateFieldType.SHORT },
                { name: "긴변수", value: PrescriptionTemplateFieldType.LONG }
            ]
        },
        render: (value) => {
            if (value === PrescriptionTemplateFieldType.SHORT) {
                return "짧은변수";
            } else if (value === PrescriptionTemplateFieldType.LONG) {
                return "긴변수";
            }
            return "알수없는변수";
        }
    },
    {
        field: "description",
        label: "변수설명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "data",
        label: "예시값",
        visible: true,
        creatable: true,
        editable: true,
        textarea: true,
        render: renderQuarterWrap
    },
    {
        field: "translated_data",
        label: "번역예시값",
        visible: true,
        creatable: true,
        editable: true,
        textarea: true,
        render: renderQuarterWrap
    }
]