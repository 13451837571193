import React, { useEffect, useState } from "react";
import _, { set } from "lodash";
import { columnInfos } from "./config";
import Card from "components/Card";
// import { memberClient } from "apis/member";
import { doctorClient } from "apis/doctor";
import PageTitle from "components/Title/Page";
import { toast } from "react-toastify";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import IconButton from "components/Form/Button/Icon";
import PagingTable from "components/Table/Paging";
import { randomString } from "utils/math";
import { useSearchParams } from "react-router-dom";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import { useModal } from "components/Modal/Simple";
import { Column } from "components/Table/Simple";
import PurchaseViewer from "pages/Admin/Customer/Status/PurchaseViewer";
import DoctorSalesViewer from "./DoctorSalesViewer";

const DoctorStatusPage = () => {
    const { Modal: PurchaseViewerModal, openModal: openPurchaseViewerModal, closeModal: closePurchaseViewerModal } = useModal();
    const [searchParams, setSearchParams] = useSearchParams();
    const [refreshKey, setRefreshKey] = useState(randomString());
    const [filterParams, setFilterParams] = useState(null);
    const [searchText, setSearchText] = useState(searchParams.get("user_search_text") ?? null);

    useEffect(() => {
        // setRefreshKey(randomString());
    }, [])

    useEffect(() => {
        if (_.isNil(searchText)) {
            setFilterParams({});
            return;
        }
        setFilterParams({
            ...(!_.isNil(searchText) && { search_text: searchText }),
        })
    }, [searchText])

    useEffect(() => {
        if (!_.isNil(searchText)) {
            setSearchParams({ user_search_text: searchText });
        } else {
            setSearchParams();
        }
    }, [searchText])

    useEffect(() => {
        setRefreshKey(randomString());
    }, [filterParams])

    const fetchTableData = async (page, limit) => {
        if (_.isNil(filterParams)) {
            return { data: [], page: 1, total: 0 }
        }
        const params = {
            page,
            limit,
            ...filterParams
        }
        try {
            const ret = await doctorClient.get(`/public/trackings`, { params });
            if (_.isNil(ret?.data?.data)) {
                return { data: [], page: 1, total: 0 }
            }
            const tempTableData = ret.data.data;
            return {
                data: tempTableData,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error("[DoctorSubmissionPage] fetchTableData", e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const columnInfosWithTask: Column[] = [
        {
            field: "task",
            label: "작업",
            visible: true,
            render: (value, field, row) => {
                const state = row.state;
                if (CustomerState[state] === CustomerState.AWAITING_PRESCRIPTION_TRANSLATION) {
                    return (
                        <IconButton
                            paddingClassName="px-2 py-1"
                            onButtonClicked={async () => {
                                const ok = window.confirm("처방전 작성 대기상태로 되돌리시겠습니까?");
                                if (!ok) {
                                    return;
                                }
                                try {
                                    const ret = await doctorClient.patch(`/trackings/${row.submission_id}/awaiting_prescription`);
                                    console.log("[DoctorSubmissionList] onComplete ret", ret);
                                    toast.success("처방전 작성 대기상태로 변경 되었습니다.");
                                    setRefreshKey(randomString());
                                } catch (e) {
                                    console.error("[DoctorSubmissionList] onComplete e", e);
                                    toast.error("처방전 작성 대기상태로 변경에 실패했습니다.");
                                }
                            }}>
                            <div className="text-xs">
                                처방전 재작성
                            </div>
                        </IconButton>
                    )
                }
            }
        },
        ...columnInfos?.map((info) => {
            if (info.field === "purchase_detail") {
                return {
                    ...info,
                    render: (value, field, row) => (
                        <div hidden={![CustomerState.DELIVERED, CustomerState.PAYMENT_COMPLETED, CustomerState.SHIPPING].includes(CustomerState[row.state])}>
                            <CalculatorIcon className="size-4 cursor-pointer" onClick={() => { openPurchaseViewerModal(row) }}></CalculatorIcon>
                        </div>
                    ),
                    visible: true,
                }
            }
            return info;
        })
    ]

    return (
        <Card>
            <div className="p-3">
                <PageTitle>진료 상태</PageTitle>
                <div className="flex pb-2">
                    <ToggleSearchInput
                        upperLabel="고객검색"
                        upButtonLabel="적용"
                        downButtonLabel="해제"
                        initialValue={searchText}
                        initialIsUp={_.isEmpty(searchText)}
                        onToggle={(isUp, value) => { setSearchText(!isUp ? value : null) }}
                        placeholder="고객번호, 고객명, 전화번호 검색"
                    />
                </div>
                <PagingTable
                    fetchData={fetchTableData}
                    columns={columnInfosWithTask}
                    key={refreshKey}
                    windowSize={20}
                />
            </div>
            <PurchaseViewerModal>
                <DoctorSalesViewer></DoctorSalesViewer>
            </PurchaseViewerModal>
        </Card>
    )
}

export default DoctorStatusPage;
