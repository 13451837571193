
import React from "react";
import { Routes, Route } from "react-router-dom";

import MemberSubmission from "pages/Member/Submission";
import MemberPrescription from "pages/Member/Prescription";
import MemberPointHistory from "pages/Member/PointHistory";
import MemberPurchase from "pages/Member/Purchase";
import MemberMyPage from "pages/Member/MyPage";

export default () => (
    <Routes>
        <Route path="/submission" element={<MemberSubmission />}></Route>
        <Route path="/prescription/" element={<MemberPrescription />}>
            <Route path=":submission_id" element={<MemberPrescription />}></Route>
        </Route>
        <Route path="/purchase" element={<MemberPurchase />}></Route>
        <Route path="/point-history" element={<MemberPointHistory />}></Route>
        <Route path="/my-page" element={<MemberMyPage />}></Route>
    </Routes>
)

